import React from "react";
import Spinner from "./Spinner";
import PropTypes from "prop-types";

function LinkButton(props) {
  const classes = `rounded-full text-center gap-4 flex items-center justify-center 
  transition duration-300 ease-in-out px-5 sm:px-6 py-4 sm:py-5 
  ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}
  ${
    props.primary ? "bg-lime hover:bg-lime/50" : "bg-lightBg hover:bg-purple/10"
  }
  ${props.propsclasses}`;
  return (
    <a className={classes} {...props}>
      {props.children || (
        <p className="text-black font-subheader font-medium text-sm sm:text-base leading-tight sm:leading-3 uppercase flex items-center gap-2">
          {props.loading ? <Spinner borderColor="border-black" /> : null}
          {props.label}
        </p>
      )}
    </a>
  );
}

export default LinkButton;

LinkButton.propTypes = {
  disabled: PropTypes.bool,
  propsclasses: PropTypes.string,
  children: PropTypes.element,
  loading: PropTypes.bool,
  label: PropTypes.string | PropTypes.element,
  primary: PropTypes.bool,
};
