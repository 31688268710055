import React, { useEffect, useState } from "react";
import { inputLengthValidation } from "../../utils/utils";
import PropTypes from "prop-types";

function Input(props) {
  const {
    label,
    limit,
    placeholder,
    value,
    handler,
    textarea,
    select,
    selectOptions,
    disabled,
    children,
    type,
    invalid,
    minLength,
  } = props;
  const [showInvalid, setShowInvalid] = useState(false);

  useEffect(() => {
    if (
      minLength &&
      value.toString().length < minLength &&
      value.toString().length > 0
    ) {
      setShowInvalid(true);
    } else {
      setShowInvalid(false);
    }
  }, [minLength, setShowInvalid, value]);

  return (
    <div className="flex flex-col w-full relative font-header">
      <label className="flex justify-between items-center mb-1">
        <p className="font-header">{label}</p>
        <span className="flex items-center justify-center gap-3">
          {showInvalid ? (
            <p className="font-header text-xs text-danger">
              {inputLengthValidation(minLength)}
            </p>
          ) : null}
          <p
            className={`font-header font-light text-sm ${
              showInvalid ? "text-danger" : "text-black"
            }`}
          >
            {limit}
          </p>
        </span>
      </label>
      {textarea ? (
        <textarea
          onBlur={() =>
            invalid ? setShowInvalid(true) : setShowInvalid(false)
          }
          disabled={disabled}
          value={value}
          onChange={handler}
          placeholder={placeholder || ""}
          className={`${
            showInvalid
              ? "border-danger/50 focus:outline-danger"
              : "border-blueGrey/20"
          } border-2 rounded-md p-3 text-black placeholder:text-black/60 scrollable`}
        />
      ) : select ? (
        <select
          onBlur={() =>
            invalid ? setShowInvalid(true) : setShowInvalid(false)
          }
          disabled={disabled}
          value={value}
          onChange={handler}
          placeholder={placeholder || ""}
          className={`${
            showInvalid
              ? "border-danger/50 focus:outline-danger"
              : "border-blueGrey/20"
          } bg-white border-2 rounded-md p-3 text-black placeholder:text-black/60 scrollable h-full cursor-pointer`}
        >
          {selectOptions.map((so, idx) => (
            <option key={idx} value={so.value}>
              {so.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          onBlur={() =>
            invalid ? setShowInvalid(true) : setShowInvalid(false)
          }
          type={type || "text"}
          disabled={disabled}
          value={value}
          onChange={handler}
          placeholder={placeholder || ""}
          className={`${
            showInvalid
              ? "border-danger/50 focus:outline-danger"
              : "border-blueGrey/20"
          } border-2 rounded-md p-3 text-black placeholder:text-black/60`}
        />
      )}
      {children || null}
    </div>
  );
}

export default Input;

Input.propTypes = {
  label: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  textarea: PropTypes.bool,
  select: PropTypes.bool,
  selectOptions: PropTypes.array,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  type: PropTypes.string,
  invalid: PropTypes.bool,
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
