import { getDefaultProfileRequest } from "../apollo/getDefaultProfile";
import { getProfiles, getUserSigNonces } from "../apollo/getProfiles";
import { getEnabledCurrencies } from "../apollo/enabledCurrencies";
import { dispatcher } from "../store";
import {
  getProfilesFailure,
  getProfilesRequest,
  getProfilesSuccess,
  setDefaultProfile,
  userInfoFailure,
  userInfoRequest,
  userInfoSuccess,
  setCurrencies,
  setUserSigNonces,
} from "../_actions/user";

export function getUserInfo(userAddress) {
  dispatcher(userInfoRequest());
  getProfilesForUser(userAddress);
  getUserDefaultProfile(userAddress);
  getEnabledCurrencies().then((result) => {
    if (result && result.data) {
      const enabledCurrencies = result.data.enabledModuleCurrencies;
      dispatcher(setCurrencies(enabledCurrencies));
    }
  });
}

export function getProfilesForUser(userAddress) {
  dispatcher(getProfilesRequest());
  getProfiles({ ownedBy: userAddress })
    .then((result) => {
      const profiles = result.data.profiles.items;
      getUserSigNonces()
        .then((result) => dispatcher(setUserSigNonces(result)))
        .catch((e) => console.error("Error getting user sig nonce: ", e));
      dispatcher(getProfilesSuccess(profiles));
      dispatcher(userInfoSuccess());
    })
    .catch((error) => {
      dispatcher(userInfoFailure(error));
      dispatcher(getProfilesFailure(error));
    });
}

function getUserDefaultProfile(address) {
  getDefaultProfileRequest(address)
    .then((result) => {
      const { defaultProfile } = result.data;
      dispatcher(setDefaultProfile(defaultProfile));
    })
    .catch((error) => console.error("Error getting default profile: ", error));
}
