import React, { useState } from "react";
import { ReactComponent as HelpCircleIcon } from "../../assets/svg/HelpCircleIcon.svg";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";

function Tooltip({ label }) {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <div className="relative px-1 flex flex-col items-center">
      <HelpCircleIcon
        className="w-4 h-4"
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      />
      <Transition
        show={isShowing}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute -top-12"
      >
        <div className="bg-black text-white text-xs rounded py-2 px-4 right-0 w-max">
          {label}
        </div>
        <svg
          className="absolute text-black h-2 w-full left-0 top-full"
          x="0px"
          y="0px"
          viewBox="0 0 255 255"
        >
          <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </Transition>
    </div>
  );
}

export default Tooltip;

Tooltip.propTypes = {
  label: PropTypes.string,
};
