import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  nftTypeIsValid,
  shortAddress,
  SUPPORTED_CHAINS,
} from "../../utils/utils";
import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { ReactComponent as XCircleIconBlack } from "../../assets/svg/XCircleIconBlack.svg";
import Modal from "../Modules/Modal";
import Spinner from "../Modules/Spinner";
import { useUser } from "../../_reducers/user";
import { useAppStatus } from "../../_reducers/appStatus";
import Button from "../Modules/Button";
import {
  setProfileImageUriNormal,
  setProfileImageUriWithNft,
} from "../../_functions/setUpdateProfileImage";
import { useWallet } from "use-wallet";
import LazyImage from "../Modules/LazyImage";
import { gql, useQuery } from "@apollo/client";
import { GET_USERS_NFTS } from "../../apollo/getUsersNfts";
import PropTypes from "prop-types";
import config from "../../config";

export default function NFTPickerModal({ open, setOpen, inlineEditor }) {
  const wallet = useWallet();
  const user = useUser();
  const profileId = inlineEditor || user.profiles[0].id;

  const { data, loading, fetchMore } = useQuery(gql(GET_USERS_NFTS), {
    variables: {
      request: {
        ownerAddress: wallet.account,
        chainIds: config.chain.CHAIN_ID,
        limit: 8,
      },
    },
    fetchPolicy: "network-only",
  });

  const [nfts, setNfts] = useState([]);
  const [nftsPageInfo, setNftsPageInfo] = useState();
  const [loadingMoreNfts, setLoadingMoreNfts] = useState(false);

  useLayoutEffect(() => {
    if (!loading) {
      setNfts(data ? data.nfts.items : []);
      setNftsPageInfo(data ? data.nfts.pageInfo : null);
    }
  }, [loading]);

  const displayLoadMoreButton =
    nftsPageInfo && nfts && nftsPageInfo.totalCount > nfts.length && !loading;

  const loadMoreNfts = async () => {
    setLoadingMoreNfts(true);
    const results = await fetchMore({
      variables: {
        request: {
          ownerAddress: wallet.account,
          chainIds: config.chain.CHAIN_ID,
          limit: 8,
          cursor: nftsPageInfo.next,
        },
      },
      fetchPolicy: "network-only",
    });
    if (results && results.data && results.data.nfts) {
      const newNfts = nfts.concat(results.data.nfts.items);
      setNfts(newNfts);
      setNftsPageInfo(results.data.nfts.pageInfo);
    }
    setLoadingMoreNfts(false);
  };

  const appStatus = useAppStatus();
  const loadingImageUpdate =
    appStatus["UPDATE_PFP"] && appStatus["UPDATE_PFP"].pending;

  useEffect(() => {
    if (loadingImageUpdate) {
      setOpen(false);
    }
  }, [loadingImageUpdate, setOpen]);

  const [search, setSearch] = useState("");

  const [selectedNft, setSelectedNft] = useState();

  const handleSelectNft = (uniqueId) => {
    const nft = {
      uniqueId,
      contractAddress: uniqueId.split(".")[0],
      tokenId: uniqueId.split(".")[1],
    };
    if (selectedNft && selectedNft.uniqueId === uniqueId) {
      setSelectedNft();
    } else {
      setSelectedNft(nft);
    }
  };

  const searchFilter = (item) =>
    item.name.toLowerCase().includes(search.toLowerCase()) ||
    item.contractName.toLowerCase().includes(search.toLowerCase()) ||
    item.contractAddress.toLowerCase().includes(search.toLowerCase());

  const updateProfileImageLocal = (e) => {
    const localFile = e.target.files ? e.target.files[0] : null;
    if (localFile) {
      setProfileImageUriNormal(
        localFile,
        profileId,
        wallet.account,
        wallet.ethereum
      );
    }
  };

  return (
    <Modal open={open} setOpen={loadingImageUpdate ? () => null : setOpen}>
      <div className="relative min-h-96 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
        <button className="absolute top-5 right-5">
          <XCircleIconBlack
            className="w-5 h-5"
            onClick={() => setOpen(false)}
          />
        </button>
        <div className="mt-2 text-left sm:mt-2">
          <div className="h-full">
            <div className="w-full px-4 flex flex-col md:flex-row items-start md:items-center md:justify-between sm:pr-8">
              <p className="text-basil text-2xl font-medium text-left font-header">
                {inlineEditor ? "Update Profile Pic" : "Choose NFT"}
              </p>
              <p className="sm:hidden text-sm text-black font-medium text-left font-header">
                Only image types are supported
              </p>
              <span className="flex items-center justify-center border border-black/10 p-2 rounded-md w-full md:w-1/4 mt-2 md:mt-0">
                <SearchIcon />
                <input
                  placeholder="Search"
                  className="w-5/6 ml-4 outline-none focus:outline-none"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </span>
            </div>
            <p className="hidden sm:block text-black font-medium text-left font-header px-4">
              Only image types are supported
            </p>
            <div className="m-auto h-96 overflow-y-scroll grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 auto-cols-min my-4 p-2 scrollable">
              {loading ? (
                <Spinner
                  borderColor="border-basil"
                  size="w-10 h-10 m-auto mt-8 col-span-full row-span-full"
                />
              ) : null}
              {!loading && nfts && nfts.length
                ? nfts.filter(searchFilter).map((item, idx) => {
                    return (
                      <NFTItem
                        key={idx}
                        item={item}
                        selectedNft={selectedNft}
                        handleSelectNft={handleSelectNft}
                        loadingImageUpdate={loadingImageUpdate}
                      />
                    );
                  })
                : null}
              {!loading && (!nfts || nfts.length === 0) ? (
                <p className="font-header text-lg my-4 mx-auto text-center">
                  No NFTs owned
                </p>
              ) : null}
              {displayLoadMoreButton ? (
                <button
                  className="font-header text-basil my-2 mx-auto text-lg font-medium col-span-full flex items-center gap-2"
                  onClick={loadMoreNfts}
                >
                  {loadingMoreNfts ? (
                    <Spinner borderColor="border-basil" />
                  ) : null}
                  <>Load more</>
                </button>
              ) : null}
            </div>
            <div className="w-full flex justify-end items-center gap-4 my-2">
              {inlineEditor ? (
                <label
                  hmtlfor="profilePicInput"
                  className={`${
                    loadingImageUpdate ? "cursor-not-allowed" : "cursor-pointer"
                  } rounded-full p-3 text-center bg-white gap-4 flex items-center justify-center`}
                >
                  <p className="text-basil font-subheader text-base font-medium">
                    UPLOAD A FILE
                  </p>
                  <input
                    disabled={loadingImageUpdate}
                    id="profilePicInput"
                    className="hidden"
                    type="file"
                    onChange={updateProfileImageLocal}
                  />
                </label>
              ) : null}
              <Button
                disabled={!selectedNft || loadingImageUpdate}
                primary
                label={loadingImageUpdate ? "updating..." : "continue"}
                onClick={() =>
                  setProfileImageUriWithNft(
                    selectedNft,
                    profileId,
                    wallet.account,
                    wallet.ethereum
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function NFTItem({ item, selectedNft, handleSelectNft, loadingImageUpdate }) {
  const uniqueId = item.contractAddress + "." + item.tokenId;
  const selected = selectedNft && selectedNft.uniqueId === uniqueId;
  const src =
    item.originalContent.uri.length &&
    item.originalContent.uri.split("://")[0] === "ipfs"
      ? "https://ipfs.io/ipfs/" + item.originalContent.uri.split("://")[1]
      : item.originalContent.uri;
  return (
    <button
      disabled={
        !(item.originalContent && nftTypeIsValid(item.originalContent.metaType))
      }
      onClick={() => (loadingImageUpdate ? null : handleSelectNft(uniqueId))}
      key={uniqueId}
      className={`sm:h-[300px] disabled:cursor-not-allowed rounded-lg shadow-md transition ease-in-out delay-150 hover:shadow-lg flex md:flex-col items-center md:justify-center justify-between gap-2 md:gap-0 p-4 
      ${selected ? "outline outline-basil" : ""} 
      ${loadingImageUpdate ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      <span className="relative rounded-lg w-1/6 md:w-full">
        <LazyImage
          className="aspect-square m-auto object-cover rounded-lg"
          src={src}
          onError={(e) =>
            (e.target.src =
              "https://theredwindows.net/wp-content/themes/koji/assets/images/default-fallback-image.png")
          }
          alt={item.tokenId}
        />
        <img
          className="w-6 h-6 absolute top-2 right-2 hidden md:block"
          src={SUPPORTED_CHAINS[item.chainId].pic}
          alt={item.chainId}
        />
      </span>
      <div>
        <p className="text-xs text-black/40 mt-2">
          {item.contractName.length
            ? item.contractName
            : shortAddress(item.contractAddress)}
        </p>
        <p className="text-sm text-black/80 break-all">
          {item.name} #{item.tokenId}
        </p>
      </div>
      <img
        className="w-6 h-6 md:hidden block border-2 border-blueGrey rounded-full object-cover"
        src={SUPPORTED_CHAINS[item.chainId].pic}
        alt={item.chainId}
      />
    </button>
  );
}

NFTPickerModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  inlineEditor: PropTypes.string,
};

NFTItem.propTypes = {
  item: PropTypes.object,
  selectedNft: PropTypes.object,
  handleSelectNft: PropTypes.func,
  loadingImageUpdate: PropTypes.bool,
};
