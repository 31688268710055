import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWallet } from "use-wallet";
import ExtendedCard from "../components/Explorer/ExtendedCard";
import Spinner from "../components/Modules/Spinner";
import { useRouter } from "../hooks/useRouter";
import { getTokens } from "../utils/localStorageUtils";
import { fetchProfile } from "../_functions/fetchProfile";
import { getUserInfo } from "../_functions/getUserInfo";
import { getUserNfts } from "../_functions/getUserNfts";
import { useAppStatus } from "../_reducers/appStatus";
import { useProfiles } from "../_reducers/profiles";
// import { ReactComponent as SadLensLogo } from "../assets/svg/SadLensLogo.svg";
import PropTypes from "prop-types";
import { gql, useQuery } from "@apollo/client";
import { GET_PROFILE } from "../apollo/getProfile";

function Profile({ isEdit }) {
  const router = useRouter();
  const { handle } = router.query;

  const appStatus = useAppStatus();

  const loadingImageSuccess =
    appStatus["UPDATE_PFP"] &&
    !appStatus["UPDATE_PFP"].pending &&
    !appStatus["UPDATE_PFP"].error;

  const metadataUpdateSuccess =
    appStatus["UPDATE_METADATA"] &&
    !appStatus["UPDATE_METADATA"].pending &&
    !appStatus["UPDATE_METADATA"].error;

  const profiles = useProfiles();
  const profile = profiles && profiles[handle];

  const { data, loading, error, refetch } = useQuery(gql(GET_PROFILE), {
    variables: {
      request: {
        handle,
      },
    },
    fetchPolicy: "network-first",
  });

  useEffect(() => {
    if (loadingImageSuccess) {
      refetch({
        variables: {
          request: {
            handle,
          },
        },
        fetchPolicy: "network-first",
      });
    }
  }, [loadingImageSuccess]);

  const wallet = useWallet();

  useEffect(() => {
    if (wallet.account) {
      const auth = getTokens();
      const { exp } =
        auth && auth.accessToken ? jwtDecode(auth.accessToken) : "";
      if (Date.now() < exp * 1000) {
        getUserInfo(wallet.account);
        getUserNfts(wallet.account);
      }
    }
  }, [wallet.account]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      if (
        (profile && metadataUpdateSuccess) ||
        (profile && loadingImageSuccess)
      ) {
        fetchProfile(profile.handle);
        dispatch({ type: "RESET_APPSTATUS" });
      }
    })();
  }, [metadataUpdateSuccess, loadingImageSuccess, profile]);

  return (
    <div className="mt-4 h-full w-full flex flex-col items-center pb-12">
      <div
        style={{
          backgroundImage:
            profile && profile.coverPicture && profile.coverPicture.original
              ? `url(${profile.coverPicture.original.url.replace(
                  "ipfs://",
                  "https://lens.infura-ipfs.io/ipfs/"
                )})`
              : "",
        }}
        className="fixed w-full h-full top-0 left-0 z-0 sm:hidden bg-no-repeat bg-cover"
      />
      {loading || !data || error ? (
        <div className="h-max mt-24  sm:mt-48 mx-auto">
          <Spinner size="w-10 h-10" borderColor="border-basil" />
        </div>
      ) : data.profile ? (
        <ExtendedCard profile={data.profile} isEdit={isEdit} />
      ) : (
        <div className="h-max mt-24 sm:mt-48 mx-auto flex flex-col sm:flex-row items-center gap-6">
          <p className="text-7xl">🌿</p>
          <div className="flex flex-col items-center sm:items-start justify-center">
            <p className="font-header text-black text-2xl mt-2 text-center leading-none">
              {"This profile doesn't seem to exist"}
            </p>
            <p className="font-header text-basil mt-2 text-center leading-none">
              {"Try using the searchbar to find users"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;

Profile.propTypes = {
  isEdit: PropTypes.bool,
};
