import React from "react";
import PropTypes from "prop-types";

function Spinner({ size, borderColor }) {
  return (
    <div
      className={`
      ${size || "w-3 h-3"} 
      ${borderColor || "border-white"}
       border-4 rounded-full spinner`}
    />
  );
}

export default Spinner;

Spinner.propTypes = {
  size: PropTypes.string,
  borderColor: PropTypes.string,
};
