import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo/apolloClient";
import { Provider } from "react-redux";
import { store } from "./store";
import { UseWalletProvider } from "use-wallet";
import { ParallaxProvider } from "react-scroll-parallax";
import config from "./config";

ReactDOM.render(
  <React.StrictMode>
    <UseWalletProvider
      autoConnect
      chainId={config.chain.CHAIN_ID}
      connectors={{
        injected: {
          chainId: [config.chain.CHAIN_ID],
        },
        walletconnect: {
          rpc: {
            [config.chain.CHAIN_ID]: config.chain.RPC_URL,
          },
        },
      }}
    >
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </Provider>
      </ApolloProvider>
    </UseWalletProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
