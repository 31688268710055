import React, { useEffect, useState } from "react";
import Modal from "../Modules/Modal";
import { ReactComponent as XCircleIconBlack } from "../../assets/svg/XCircleIconBlack.svg";
import { gql, useQuery } from "@apollo/client";
import { GET_FOLLOWERS } from "../../apollo/followers";
import { Link } from "react-router-dom";
import LazyImage from "../Modules/LazyImage";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import { shortAddress } from "../../utils/utils";
import Spinner from "../Modules/Spinner";
import { GET_FOLLOWING } from "../../apollo/following";
import PropTypes from "prop-types";
import { useUser } from "../../_reducers/user";
import { GET_PROFILES } from "../../apollo/getProfiles";

function ProfilesListModal({ open, setOpen, profile }) {
  const { name, handle, id, ownedBy, stats } = profile;

  const { totalFollowers, totalFollowing } = stats;

  const [tab, setTab] = useState("followers");

  /**
   * FOLLOWERS
   */

  const {
    data: followersData,
    loading: followersLoading,
    fetchMore: followersRefetch,
  } = useQuery(gql(GET_FOLLOWERS), {
    variables: {
      request: {
        profileId: id,
        limit: 10,
      },
    },
  });

  const [followers, setFollowers] = useState([]);
  const [followersPageInfo, setFollowersPageInfo] = useState();
  const [loadingMoreFollowers, setLoadingMoreFollowers] = useState(false);

  useEffect(() => {
    if (followersData) {
      const followers =
        followersData.followers.items && followersData.followers.items.length
          ? followersData.followers.items.map(({ wallet }) =>
              wallet.defaultProfile
                ? { ...wallet.defaultProfile }
                : { handle: shortAddress(wallet.address) }
            )
          : [];
      setFollowers(followers);
      setFollowersPageInfo(followersData.followers.pageInfo);
    }
  }, [followersData]);

  const displayLoadMoreFollowers =
    followersPageInfo &&
    followers &&
    followersPageInfo.totalCount > followers.length;

  const loadMoreFollowers = async () => {
    setLoadingMoreFollowers(true);
    const results = await followersRefetch({
      variables: {
        request: {
          profileId: id,
          limit: 10,
          cursor: followersPageInfo.next,
        },
      },
    });
    if (results && results.data && results.data.followers) {
      const cleanedFollowers =
        results.data.followers.items && results.data.followers.items.length
          ? results.data.followers.items.map(({ wallet }) =>
              wallet.defaultProfile
                ? { ...wallet.defaultProfile }
                : { handle: shortAddress(wallet.address) }
            )
          : [];
      const newFollowers = followers.concat(cleanedFollowers);
      setFollowers(newFollowers);
      setFollowersPageInfo(results.data.followers.pageInfo);
    }
    setLoadingMoreFollowers(false);
  };

  /**
   * FOLLOWING
   */

  const {
    data: followingData,
    loading: followingLoading,
    fetchMore: followingRefetch,
  } = useQuery(gql(GET_FOLLOWING), {
    variables: {
      request: {
        address: ownedBy,
        limit: 10,
      },
    },
  });

  const [following, setFollowing] = useState([]);
  const [followingPageInfo, setFollowingPageInfo] = useState();
  const [loadingMoreFollowing, setLoadingMoreFollowing] = useState(false);

  useEffect(() => {
    if (followingData) {
      const cleanedFollowing = followingData.following.items.map(
        (item) => item.profile
      );
      setFollowing(cleanedFollowing);
      setFollowingPageInfo(followingData.following.pageInfo);
    }
  }, [followingData]);

  const displayLoadMoreFollowing =
    followingPageInfo &&
    following &&
    followingPageInfo.totalCount > following.length;

  const loadMoreFollowing = async () => {
    setLoadingMoreFollowing(true);
    const results = await followingRefetch({
      variables: {
        request: {
          address: ownedBy,
          limit: 10,
          cursor: followingPageInfo.next,
        },
      },
    });
    if (results && results.data && results.data.following) {
      const cleanedFollowing = results.data.following.items.map(
        (item) => item.profile
      );
      const newfollowing = following.concat(cleanedFollowing);
      setFollowing(newfollowing);
      setFollowingPageInfo(results.data.following.pageInfo);
    }
    setLoadingMoreFollowing(false);
  };

  useEffect(() => {
    if (totalFollowers || totalFollowing) {
      followersRefetch({
        profileId: id,
        limit: 10,
      });
    }
  }, [totalFollowers, totalFollowing]);

  const user = useUser();

  const addToFollowersCondition =
    user &&
    user.defaultProfile &&
    user.pendingFollows &&
    user.pendingFollows.length > 0 &&
    user.pendingFollows.includes(id);

  const addToFollowingCondition =
    user && user.pendingFollows && user.pendingFollows.length !== 0;

  const { data: profilesData } = useQuery(gql(GET_PROFILES), {
    variables: {
      request: {
        profileIds: user.pendingFollows,
      },
    },
    skip: !addToFollowingCondition,
  });

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="relative min-h-96 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-[450px] sm:w-full sm:p-6 px-4 pt-5 pb-24 sm:pb-24 h-[600px]">
        <button className="absolute top-7 right-7">
          <XCircleIconBlack
            className="w-5 h-5"
            onClick={() => setOpen(false)}
          />
        </button>
        <div className="flex items-center gap-4">
          <div className="flex flex-col items-start">
            <p className="font-header text-xl leading-tight">
              {name || handle}
            </p>
            <p className="font-header text-sm font-light leading-tight">
              @{handle}
            </p>
          </div>
        </div>
        <Tabs tab={tab} setTab={setTab} />
        <div className="overflow-y-auto scrollable h-full">
          {followersLoading || followingLoading ? (
            <div className="h-full flx items-center justify-center">
              <Spinner borderColor="border-basil" size="h-6 w-6" />
            </div>
          ) : (
            <ProfilesList
              tab={tab}
              profiles={
                tab === "followers"
                  ? addToFollowersCondition
                    ? [...followers, user.defaultProfile]
                    : followers
                  : addToFollowingCondition && profilesData
                  ? [...profilesData.profiles.items, ...following]
                  : following
              }
              displayLoadMoreFollowers={displayLoadMoreFollowers}
              loadingMoreFollowers={loadingMoreFollowers}
              loadMoreFollowers={loadMoreFollowers}
              displayLoadMoreFollowing={displayLoadMoreFollowing}
              loadingMoreFollowing={loadingMoreFollowing}
              loadMoreFollowing={loadMoreFollowing}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ProfilesListModal;

function Tabs({ tab, setTab }) {
  return (
    <div className="flex justify-around mt-4">
      <button
        className={`flex flex-col items-center w-max pb-1 focus:outline-none ${
          tab === "followers"
            ? "text-basil border-b-2 border-basil font-semibold"
            : "font-light"
        }`}
        onClick={() => (tab !== "followers" ? setTab("followers") : null)}
      >
        <p className="font-header leading-tight uppercase">followers</p>
      </button>
      <button
        className={`flex flex-col items-center w-max focus:outline-none ${
          tab === "following"
            ? "text-basil border-b-2 border-basil font-semibold"
            : "font-light"
        }`}
        onClick={() => (tab !== "following" ? setTab("following") : null)}
      >
        <p className="font-header leading-tight uppercase">following</p>
      </button>
    </div>
  );
}

function ProfilesList({
  tab,
  profiles,
  displayLoadMoreFollowers,
  loadingMoreFollowers,
  loadMoreFollowers,
  displayLoadMoreFollowing,
  loadingMoreFollowing,
  loadMoreFollowing,
}) {
  return (
    <div className="mt-4 flex flex-col items-center w-full pr-2">
      {!profiles.length ? (
        <p className="text-center font-header mt-8">List of {tab} is empty</p>
      ) : null}
      {profiles.map((profile) => {
        return (
          <Link
            to={"/" + profile.handle}
            key={profile.id}
            className="w-full p-4 flex items-center gap-4 hover:bg-peas focus:bg-peas rounded-lg"
          >
            <LazyImage
              className="w-10 h-10 rounded-full"
              src={
                profile.picture &&
                profile.picture.original &&
                profile.picture.original.url
                  ? profile.picture.original.url.replace(
                      "ipfs://",
                      "https://lens.infura-ipfs.io/ipfs/"
                    )
                  : FallbackAvatar
              }
              alt="profile thumbnail"
            />
            <div className="flex flex-col items-start">
              <p className="font-header font-semibold text-lg leading-tight">
                {profile.name || profile.handle}
              </p>
              <p className="font-header leading-tight font-light text-sm">
                @{profile.handle}
              </p>
            </div>
          </Link>
        );
      })}
      {tab === "followers" && displayLoadMoreFollowers ? (
        <button
          className="font-header text-basil my-2 mx-auto font-medium col-span-full flex items-center gap-2"
          onClick={loadMoreFollowers}
        >
          {loadingMoreFollowers ? <Spinner borderColor="border-basil" /> : null}
          <>Load more</>
        </button>
      ) : null}
      {tab === "following" && displayLoadMoreFollowing ? (
        <button
          className="font-header text-basil my-2 mx-auto font-medium col-span-full flex items-center gap-2"
          onClick={loadMoreFollowing}
        >
          {loadingMoreFollowing ? <Spinner borderColor="border-basil" /> : null}
          <>Load more</>
        </button>
      ) : null}
    </div>
  );
}

ProfilesListModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  profile: PropTypes.object,
};

Tabs.propTypes = { tab: PropTypes.string, setTab: PropTypes.func };

ProfilesList.propTypes = {
  tab: PropTypes.string,
  profiles: PropTypes.array,
  displayLoadMoreFollowers: PropTypes.bool,
  loadingMoreFollowers: PropTypes.bool,
  loadMoreFollowers: PropTypes.func,
  displayLoadMoreFollowing: PropTypes.bool,
  loadingMoreFollowing: PropTypes.bool,
  loadMoreFollowing: PropTypes.func,
};
