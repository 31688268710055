import omitDeep from "omit-deep";
import toast from "react-hot-toast";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { dispatcher, store } from "../store";
import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import {
  toggleDispatcherFailure,
  toggleDispatcherRequest,
  toggleDispatcherSuccess,
} from "../_actions/user";
import { ethers } from "ethers";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import config from "../config";
import { LENS_HUB_ABI } from "../utils/lensHubAbi";
import { createSetDispatcherTypedData } from "../apollo/createSetDispatcherTypedData";
import { refreshProfile } from "./refreshProfile";
import { refreshDefaultProfile } from "./refreshDefaultProfile";

export const setDispatcherFromTypedData = async (
  result,
  profileId,
  userAddress,
  provider
) => {
  const { expiresAt, id } = result.data.createSetDispatcherTypedData;

  const typedData = omitDeep(
    result.data.createSetDispatcherTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(toggleDispatcherFailure(e));
    console.error("Error with set dispatcher signature", e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(
      toggleDispatcherFailure("Error setting dispatcher with broadcast")
    );
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason) {
    toast.error(boradcastErrorFormatting(reason));
    return await setDispatcherWithSig(
      signature,
      profileId,
      typedData,
      provider
    );
  }
  if (txHash) {
    const indexation = await pollUntilIndexed(txHash);
    if (indexation) {
      setTimeout(async () => {
        await refreshProfile(userAddress, profileId);
      }, 500);
      setTimeout(async () => {
        await refreshDefaultProfile(userAddress);
      }, 500);
      dispatcher(toggleDispatcherSuccess());
      toast.success("Dispatcher updated!");
    }
  } else {
    dispatcher(toggleDispatcherFailure("Error setting up dispatcher"));
    console.error("Error setting up dispatcher");
    toast.error("Error on updating the dispatcher!");
    return;
  }
};

export async function setDispatcher(profileId, enable, userAddress, provider) {
  dispatcher(toggleDispatcherRequest());

  const address = ethers.utils.getAddress(userAddress);

  try {
    const userSigNonces = store.getState().user.userSigNonces;
    const result = await createSetDispatcherTypedData({
      options: { overrideSigNonce: userSigNonces },
      request: {
        profileId,
        enable: enable,
      },
    });
    await setDispatcherFromTypedData(result, profileId, address, provider);
  } catch (e) {
    dispatcher(toggleDispatcherFailure("Error setting dispatcher"));
    console.error("Error setting dispatcher", e);
    return;
  }
}

const setDispatcherWithSig = async (
  signature,
  profileId,
  userAddress,
  typedData,
  provider
) => {
  const { v, r, s } = splitSignature(signature);
  const lensHub = new ethers.Contract(
    config.contracts.LENS_HUB_CONTRACT_ADDRESS,
    LENS_HUB_ABI,
    getProvider(provider).getSigner()
  );
  const tx = await lensHub.setDispatcherWithSig({
    profileId,
    dispatcher: typedData.value.dispatcher,
    sig: {
      v,
      r,
      s,
      deadline: typedData.value.deadline,
    },
  });
  if (tx.hash) {
    const indexation = await pollUntilIndexed(tx.hash);
    if (indexation) {
      setTimeout(async () => {
        await refreshProfile(userAddress, profileId);
      }, 500);
      setTimeout(async () => {
        await refreshDefaultProfile(userAddress);
      }, 500);
      dispatcher(toggleDispatcherSuccess());
      toast.success("Dispatcher updated!");
    }
  } else {
    dispatcher(toggleDispatcherFailure("Error setting up dispatcher"));
    console.error("Error setting up dispatcher");
    toast.error("Error on updating the dispatcher!");
    return;
  }
};
