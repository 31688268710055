import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getActionName } from "../utils/utils";

const appStatus = (state = {}, action) => {
  const { type, payload } = action;
  const actionName = getActionName(type);

  if (!actionName) {
    return {
      ...state,
    };
  }

  if (type.endsWith("_REQUEST")) {
    return {
      ...state,
      [actionName]: {
        pending: true,
        error: false,
      },
    };
  }

  if (type.endsWith("_FAILURE")) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        error: payload,
      },
    };
  }

  if (type.endsWith("_SUCCESS")) {
    return {
      ...state,
      [actionName]: {
        pending: false,
        error: false,
      },
    };
  }

  if (type === "RESET_APP") {
    return {
      appCrashed: true,
    };
  }

  if (type === "RESET_APPSTATUS") {
    return {};
  }

  return {
    ...state,
  };
};

export default appStatus;

function selectReducerState(state) {
  return state.appStatus;
}

const selectAppStatus = createSelector(
  selectReducerState,
  (appStatus) => appStatus
);

export const useAppStatus = () => useSelector(selectAppStatus);
