import { dispatcher, store } from "../store";
import {
  fetchProfileFailure,
  fetchProfileRequest,
  fetchProfileSuccess,
} from "../_actions/profiles";
import { getProfiles } from "../apollo/getProfiles";

import toast from "react-hot-toast";

export function fetchProfile(handle) {
  dispatcher(fetchProfileRequest());
  const cached =
    store.getState().profiles &&
    store.getState().profiles.length > 0 &&
    store
      .getState()
      .profiles.map((p) => p.handle)
      .inclues(handle);
  if (cached) {
    return;
  } else {
    getProfiles({ handles: [handle] })
      .then(async (result) => {
        let profile = result.data.profiles.items[0];
        dispatcher(fetchProfileSuccess(profile));
      })
      .catch((error) => {
        console.error(error.message);
        toast.error("Error fetching profile");
        dispatcher(fetchProfileFailure(error));
      });
  }
}
