import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_SET_FOLLOW_MODULE_TYPED_DATA = `
  mutation CreateSetFollowModule($request: CreateSetFollowModuleRequest!) { 
    createSetFollowModuleTypedData(request: $request) {
      id
      expiresAt
      typedData {
        types {
          SetFollowModuleWithSig {
            name
            type
          }
        }
      domain {
        name
        chainId
        version
        verifyingContract
      }
      value {
        nonce
        deadline
        profileId
        followModule
        followModuleInitData
      }
     }
   }
 }
`;

export const createSetFollowModuleTypedData = (setFollowModuleRequest) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_SET_FOLLOW_MODULE_TYPED_DATA),
    variables: {
      request: setFollowModuleRequest,
    },
  });
};
