import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const GET_CHALLENGE = `
  query Challenge($request: ChallengeRequest!) {
    challenge(request: $request) { text }
  }
`;

export const generateChallenge = async (address) => {
  return await apolloClient.query({
    query: gql(GET_CHALLENGE),
    variables: {
      request: {
        address,
      },
    },
    fetchPolicy: "network-only",
  });
};
