import React, { useEffect, useState } from "react";
import Modal from "../Modules/Modal";
import Button from "../Modules/Button";
import { ReactComponent as XCircleIconBlack } from "../../assets/svg/XCircleIconBlack.svg";
import PropTypes from "prop-types";
import { useAppStatus } from "../../_reducers/appStatus";
import { setDispatcher } from "../../_functions/setDispatcher";
import { useWallet } from "use-wallet";

const INFO_TEXT = `Activate the dispatcher option on your default profile. \n\nAfter signing this transaction, you won't need to sign other transactions for posts, comments, mirrors and profile updates.\n\nYou can still update it later, and you can activate it for other profiles you might own.`;

function DispatcherModal({ open, setOpen, profileId }) {
  const wallet = useWallet();

  const appStatus = useAppStatus();

  const setDispatcherLoading =
    appStatus["TOGGLE_DISPATCHER"] && appStatus["TOGGLE_DISPATCHER"].pending;

  const setDispatcherSuccess =
    appStatus["TOGGLE_DISPATCHER"] &&
    !appStatus["TOGGLE_DISPATCHER"].pending &&
    !appStatus["TOGGLE_DISPATCHER"].error;

  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    if (open && setDispatcherSuccess) {
      setOpen(false);
    }
  }, [open, setDispatcherSuccess]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="relative min-h-96 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
        <button className="absolute sm:top-8 sm:right-8 top-4 right-4">
          <XCircleIconBlack
            className="w-5 h-5"
            onClick={() => {
              setOpen(false);
            }}
          />
        </button>
        <div className="mt-2 text-left sm:mt-2">
          <p className="font-header font-semibold text-xl text-basil">
            {`🌿✨ Activate the sign-less experience`}
          </p>
          <div className="w-full mt-4">
            <p className="font-subheader whitespace-pre-wrap">{INFO_TEXT}</p>
          </div>
          <span className="flex items-center justify-start gap-3 mt-4">
            <div
              className={`relative rounded-full w-12 h-6 transition duration-200 ease-linear ${
                dontShowAgain ? "bg-basil" : "bg-blueGrey"
              }`}
            >
              <label
                htmlFor="hideToggle"
                className={`absolute left-0 bg-white border-2 mb-2 w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer ${
                  dontShowAgain
                    ? "translate-x-full border-basil"
                    : "translate-x-0 border-blueGrey"
                }`}
              />
              <input
                disabled={setDispatcherLoading}
                type="checkbox"
                id="hideToggle"
                name="hideToggle"
                className="appearance-none w-full h-full active:outline-none focus:outline-none"
                checked={dontShowAgain}
                onChange={() => setDontShowAgain(!dontShowAgain)}
              />
            </div>
            <p className="text-sm">{`Don't show this message again`}</p>
          </span>
          <div className="flex items-center justify-center gap-4 mt-6">
            <Button
              label="Close"
              onClick={() => {
                if (dontShowAgain) {
                  window.localStorage.setItem("hideSignlessModal", true);
                }
                setOpen(false);
              }}
            />
            <Button
              label="Confirm"
              primary
              onClick={async () => {
                await setDispatcher(
                  profileId,
                  true,
                  wallet.account,
                  wallet.ethereum
                );
              }}
              loading={setDispatcherLoading}
              disabled={setDispatcherLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DispatcherModal;

DispatcherModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  profileId: PropTypes.string,
};
