import { getDefaultProfileRequest } from "../apollo/getDefaultProfile";
import { dispatcher } from "../store";
import { setDefaultProfile } from "../_actions/user";

export async function refreshDefaultProfile(userAddress) {
  try {
    const { data } = await getDefaultProfileRequest(userAddress);
    if (data) {
      const defaultProfile = data.defaultProfile;
      dispatcher(setDefaultProfile(defaultProfile));
    }
  } catch (e) {
    console.error(e.message);
  }
}
