import toast from "react-hot-toast";
import { apolloClient, ARRAY_OF_FOLLOWS } from "../apollo/apolloClient";
import { proxyAction } from "../apollo/proxyAction";
import { pollProxyUntilComplete } from "../apollo/proxyStatus";
import { dispatcher, store } from "../store";
import { setFollowSuccess } from "../_actions/profiles";
import { updateFollowed, updatePendingFollows } from "../_actions/user";
import { refreshProfile } from "./refreshProfile";

export async function followWithProxy(profileId, userProfiles) {
  try {
    apolloClient.cache.updateQuery(
      {
        query: ARRAY_OF_FOLLOWS,
      },
      (data) => ({ arrayOfFollows: [...data.arrayOfFollows, profileId] })
    );

    userProfiles.map((profile) => {
      apolloClient.cache.modify({
        id: `Profile:${profile.id}`,
        fields: {
          stats: (stats) => ({
            ...stats,
            totalFollowing: stats.totalFollowing + 1,
          }),
        },
        optimistic: true,
      });
    });

    dispatcher(setFollowSuccess());
    toast.success("Followed!");

    const result = await proxyAction({
      follow: {
        freeFollow: {
          profileId,
        },
      },
    });

    if (result.data.proxyAction) {
      const completed = await pollProxyUntilComplete(result.data.proxyAction);

      if (completed) {
        await refreshProfile(null, profileId);

        const pendingFollows = store.getState().user.pendingFollows || [];
        dispatcher(
          updatePendingFollows(pendingFollows.filter((p) => p !== profileId))
        );

        const followed = store.getState().user.followed || [];
        dispatcher(updateFollowed([...followed, profileId]));
        return true;
      } else {
        return false;
      }
    }
  } catch (e) {
    toast.error(e.message || e || "Something went wrong");
    return false;
  }
}
