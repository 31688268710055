export const GET_PENDING_APPROVALS = `
query PendingApprovals($request: PendingApprovalFollowsRequest!) {
  pendingApprovalFollows(request: $request) {
    items {
      id
      handle
      name
      picture {
        ... on NftImage {
          contractAddress
          tokenId
          uri
          verified
        }
        ... on MediaSet {
          original {
            url
            width
            height
            mimeType
          }
          small {
            url
            width
            height
            mimeType
          }
          medium {
            url
            width
            height
            mimeType
          }
        }
        __typename
      }
    }
    pageInfo {
      prev
      next
      totalCount
    }
  }
}
`;
