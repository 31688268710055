import React, { useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import ConnectWalletModal from "../components/Modals/ConnectWalletModal";
import Explorer from "../components/Explorer/Explorer";
import { gql, useQuery } from "@apollo/client";
import { CLAIMABLE_HANDLES } from "../apollo/claimableHandles";
import toast from "react-hot-toast";
import config from "../config";
import { useUser } from "../_reducers/user";
import DispatcherModal from "../components/Modals/DispatcherModal";

export default function Home() {
  const [open, setOpen] = useState(false);
  const [openDispatcher, setOpenDispatcher] = useState(false);

  const wallet = useWallet();
  const user = useUser();

  const { data } = useQuery(gql(CLAIMABLE_HANDLES), {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (wallet.account && data && data.claimableHandles) {
      if (
        data.claimableHandles.canClaimFreeTextHandle ||
        (data.claimableHandles.reservedHandles &&
          data.claimableHandles.reservedHandles.length > 0)
      ) {
        toast.success(
          <span>
            <>You are eligible to claim your Lens profile </>
            <a
              href={config.urls.claim}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              here
            </a>
          </span>,
          { duration: 6000, icon: "🌿" }
        );
      }
    }
  }, [wallet.account, data]);

  useEffect(() => {
    if (
      user &&
      user.defaultProfile &&
      user.defaultProfile.dispatcher === null &&
      window &&
      !window.localStorage.getItem("hideSignlessModal")
    ) {
      setOpenDispatcher(true);
    }
  }, [user]);

  const switchNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${config.chain.CHAIN_ID.toString(16)}`,
              chainName: config.chain.CHAIN_NAME,
              rpcUrls: [config.chain.RPC_URL],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: [config.chain.EXPLORER_URL],
            },
          ],
        });
      } catch (error) {
        console.error("error adding network: ", error);
      }
    }
  };

  return (
    <div className="mt-8 h-full w-full flex flex-col">
      {user && user.defaultProfile ? (
        <DispatcherModal
          open={openDispatcher}
          setOpen={setOpenDispatcher}
          profileId={user.defaultProfile.id}
        />
      ) : null}
      <div className="">
        <ConnectWalletModal open={open} setOpen={setOpen} />
      </div>
      <div className="flex-grow w-full">
        <Explorer />
      </div>
      {window.ethereum && wallet && wallet.chainId !== config.chain.CHAIN_ID ? (
        <button
          className="fixed bottom-4 left-6 bg-peas/70 hover:bg-peas/80 text-basil text-xs py-2 px-3 rounded"
          onClick={switchNetwork}
        >
          Add {config.chain.CHAIN_NAME}
        </button>
      ) : null}
    </div>
  );
}
