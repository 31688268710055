import React, { useState } from "react";
import { useWallet } from "use-wallet";
import ExtendedCard from "../components/Explorer/ExtendedCard";
import Spinner from "../components/Modules/Spinner";
import { useRouter } from "../hooks/useRouter";
// import { fetchProfile } from "../_functions/fetchProfile";
import { useAppStatus } from "../_reducers/appStatus";
import { useProfiles } from "../_reducers/profiles";
import { ReactComponent as SadLensLogo } from "../assets/svg/SadLensLogo.svg";
import PropTypes from "prop-types";
import FollowRedirectModal from "../components/Modals/FollowRedirectModal";
import ConnectWalletModal from "../components/Modals/ConnectWalletModal";
import { gql, useQuery } from "@apollo/client";
import { GET_PROFILE } from "../apollo/getProfile";

function FollowProfile({ isFollow }) {
  const router = useRouter();
  const { handle } = router.query;

  const appStatus = useAppStatus();

  const loadingProfile =
    appStatus["FETCH_PROFILE"] && appStatus["FETCH_PROFILE"].pending;

  const profiles = useProfiles();
  const profile = profiles && profiles[handle];

  const { data, loading, error } = useQuery(gql(GET_PROFILE), {
    variables: {
      request: {
        handle,
      },
    },
  });
  const profileToFollow = data?.profile

  const wallet = useWallet();

  const [open, setOpen] = useState(isFollow);

  const [openWalletModal, setOpenWalletModal] = useState(!wallet.isConnected());

  if (loadingProfile) {
    return (
      <div className="w-full min-h-screen bg-lightBg flex flex-col items-center md:pb-12 xl:pb-0">
        <p className="text-[50px] animate-ping m-auto">🌿</p>
      </div>
    );
  }

  return (
    <>
      <FollowRedirectModal
        open={open && wallet.isConnected()}
        setOpen={setOpen}
        profile={profileToFollow || { id: "", followModule: null, ownedBy: "--" }}
      />
      <ConnectWalletModal open={openWalletModal} setOpen={setOpenWalletModal} />
      <div className="mt-4 h-full w-full flex flex-col items-center pb-12">
        <div
          style={{
            backgroundImage:
              profile && profile.coverPicture && profile.coverPicture.original
                ? `url(${profile.coverPicture.original.url.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )})`
                : "",
          }}
          className="fixed w-full h-full top-0 left-0 z-0 sm:hidden bg-no-repeat bg-cover"
        />
        {loading || !data || error ? (
          <div className="h-max mt-24  sm:mt-48 mx-auto">
            <Spinner size="w-10 h-10" borderColor="border-basil" />
          </div>
        ) : data.profile ? (
          <ExtendedCard profile={data.profile} />
        ) : (
          <div className="h-max mt-24 sm:mt-48 mx-auto flex flex-col items-center gap-4">
            <SadLensLogo className="h-1/2" />
            <p className="font-header text-basil text-xl mt-2 text-center">
              {"This profile doesn't seem to exist"}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default FollowProfile;

FollowProfile.propTypes = {
  isFollow: PropTypes.bool,
};
