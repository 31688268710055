import { apolloClient } from './apolloClient';
import { gql } from '@apollo/client'

const CREATE_SET_PROFILE_IMAGE_URI_TYPED_DATA = `
  mutation UpdateProfileImage($request: UpdateProfileImageRequest!) { 
    createSetProfileImageURITypedData(request: $request) {
      id
      expiresAt
      typedData {
        domain {
          name
          chainId
          version
          verifyingContract
        }
        types {
          SetProfileImageURIWithSig {
            name
            type
          }
        }
        value {
          nonce
            deadline
            imageURI
            profileId
        }
      }
    }
 }
`

export const createSetProfileImageUriTypedData = (request) => {
   return apolloClient.mutate({
    mutation: gql(CREATE_SET_PROFILE_IMAGE_URI_TYPED_DATA),
    variables: {
      request
    },
  })
}