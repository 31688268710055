import React, { useState } from "react";
import { useWallet } from "use-wallet";
import {
  getPictureToDisplay,
  shortAddress,
  shortName,
} from "../../utils/utils";
import { useUser } from "../../_reducers/user";
import ConnectWalletModal from "../Modals/ConnectWalletModal";
import Button from "../Modules/Button";
import HeaderSearchBar from "./HeaderSearchBar";
import { useAppStatus } from "../../_reducers/appStatus";
import MobileHeaderSearch from "./MobileHeaderSearch";
import { deleteTokens } from "../../utils/localStorageUtils";
import { dispatcher } from "../../store";
import { getProfilesSuccess } from "../../_actions/user";
import toast from "react-hot-toast";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import LazyImage from "../Modules/LazyImage";
import PropTypes from "prop-types";
import { ReactComponent as PolygonMainnet } from "../../assets/svg/PolygonMainnet.svg";
import { ReactComponent as PolygonTestnet } from "../../assets/svg/PolygonTestnet.svg";
import config from "../../config";

function HeaderMenu() {
  const wallet = useWallet();
  if (wallet.status === "connected") {
    return <DefaultProfile />;
  } else {
    return <ConnectWallet />;
  }
}

export default HeaderMenu;

function ConnectWallet() {
  const [open, setOpen] = useState(false);
  const appStatus = useAppStatus();
  const userAuthLoading =
    appStatus && appStatus["USER_AUTH"] && appStatus["USER_AUTH"].pending;
  return (
    <div className="flex items-center gap-4">
      <ConnectWalletModal open={open} setOpen={setOpen} />
      <div className="block md:hidden">
        <MobileHeaderSearch />
      </div>
      <div className="hidden md:block">
        <HeaderSearchBar />
      </div>
      <Button
        disabled={userAuthLoading}
        loading={userAuthLoading}
        primary
        label="CONNECT WALLET"
        onClick={() => setOpen(true)}
      />
    </div>
  );
}

function DefaultProfile() {
  const [open, setOpen] = useState(false);
  const user = useUser();
  const wallet = useWallet();
  const profileToShow =
    user.defaultProfile || (user.profiles && user.profiles[0]) || null;
  return (
    <div className="flex items-center gap-4">
      <div className="block md:hidden">
        <MobileHeaderSearch />
      </div>
      <div className="hidden md:block">
        <HeaderSearchBar />
      </div>
      <div
        className="relative flex flex-col items-center"
        onBlur={() => setOpen(false)}
      >
        <Popover>
          <Popover.Button className="focus:outline-none">
            {user.defaultProfile ? (
              <Button pill primary active={open} onClick={() => setOpen(!open)}>
                <div className="flex items-center justify-start gap-3">
                  <LazyImage
                    src={
                      getPictureToDisplay(user.defaultProfile.picture) ||
                      FallbackAvatar
                    }
                    className="rounded-full object-cover h-9 w-9"
                    alt="profile_picture"
                  />
                  <div className="flex flex-col items-start justify-center">
                    <p className="block sm:hidden text-basil font-subheader text-xs sm:text-sm font-semibold leading-tight uppercase">
                      @{shortName(user.defaultProfile.handle, 15)}
                    </p>
                    <p className="hidden sm:block text-basil font-subheader text-xs sm:text-sm font-semibold leading-tight uppercase">
                      @{shortName(user.defaultProfile.handle)}
                    </p>
                    <p className="text-basil font-subheader text-xs sm:text-sm leading-tight uppercase">
                      {profileToShow && profileToShow.ownedBy
                        ? shortAddress(profileToShow.ownedBy)
                        : ""}
                    </p>
                  </div>
                </div>
              </Button>
            ) : (
              <Button
                primary
                label={wallet.account ? shortAddress(wallet.account) : ""}
                active={open}
                onClick={() => setOpen(!open)}
              />
            )}
          </Popover.Button>
          <Transition
            enter="transition ease-out duration-800"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="bg-white absolute z-10 rounded-lg shadow-xl min-w-min top-16 right-4">
              <DefaultProfileMenu setOpen={setOpen} />
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
}

function DefaultProfileMenu({ setOpen }) {
  const wallet = useWallet();
  const user = useUser();
  const profiles = user.profiles;

  const disconnect = () => {
    localStorage.clear();
    deleteTokens();
    wallet.reset();
    dispatcher(getProfilesSuccess());
    toast.success("User logged out");
  };

  const switchNetwork = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${config.chain.CHAIN_ID.toString(16)}`,
              chainName: config.chain.CHAIN_NAME,
              rpcUrls: [config.chain.RPC_URL],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
              blockExplorerUrls: [config.chain.EXPLORER_URL],
            },
          ],
        });
      } catch (error) {
        console.error("error adding network: ", error);
      }
    }
  };

  return (
    <div className="w-max min-w-[200px]">
      <div className="p-2">
        <p className="uppercase text-sm">
          {wallet.account ? shortAddress(wallet.account) : ""}
        </p>
      </div>
      {profiles && profiles.length ? (
        <div
          className="border-y border-black flex flex-col gap-1 items-start"
          role="list"
        >
          <p className="uppercase font-bold text-xs px-2 pt-2">Your profiles</p>
          <div className="flex flex-col gap-2 w-full px-2 my-2">
            {profiles.map((profile) => {
              return (
                <Link
                  key={profile.id}
                  to={"/" + profile.handle}
                  role="listitem"
                  className="hover:bg-purple/10 hover:text-black hover:font-semibold text-black bg-lightPurple focus:text-black px-2 py-1 cursor-pointer flex items-center justify-between gap-2 w-full rounded"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <p key={profile.id} className="uppercase text-sm font-header">
                    @{profile.handle}
                  </p>
                </Link>
              );
            })}
          </div>
        </div>
      ) : null}
      <button
        className="cursor-default p-2 hover:bg-peas hover:text-basil focus:bg-peas focus:text-basil w-full text-left flex items-center justify-start border-b border-basil gap-2"
        onMouseDown={async (e) => {
          e.stopPropagation();
          await switchNetwork();
        }}
      >
        {config.chain.CHAIN_ID === 137 ? (
          <PolygonMainnet className="w-4 h-4" />
        ) : (
          <PolygonTestnet className="w-4 h-4" />
        )}
        {wallet.chainId === config.chain.CHAIN_ID ? (
          <p className="text-sm">{config.chain.CHAIN_NAME}</p>
        ) : (
          <p className="text-sm cursor-pointer">
            Add/Switch {config.chain.CHAIN_NAME}
          </p>
        )}
      </button>
      <button
        className="p-2 hover:bg-lightPurple hover:text-black hover:font-semibold focus:bg-lightPurple focus:text-black focus:font-semibold hover:rounded-b-lg w-full text-left"
        onMouseDown={(e) => {
          e.stopPropagation();
          disconnect();
        }}
      >
        <p className="uppercase text-sm font-bold cursor-pointer">DISCONNECT</p>
      </button>
    </div>
  );
}

DefaultProfileMenu.propTypes = {
  setOpen: PropTypes.func,
};
