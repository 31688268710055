import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const NFT_CHALLENGE = `
  query NftOwnershipChallenge($request: NftOwnershipChallengeRequest!) {
    nftOwnershipChallenge(request: $request) { id, text }
  }
`;

export const generateNftChallenge = (ethereumAddress, nfts) => {
  return apolloClient.query({
    query: gql(NFT_CHALLENGE),
    variables: {
      request: {
        ethereumAddress,
        nfts,
      },
    },
  });
};
