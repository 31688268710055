import { getProfiles } from "../apollo/getProfiles";
import { dispatcher } from "../store";
import { fetchProfileSuccess } from "../_actions/profiles";

export async function refreshProfile(userAddress, id) {
  try {
    const { data } = await getProfiles(
      userAddress
        ? {
            ownedBy: [userAddress],
          }
        : {
            profileIds: [id],
          }
    );
    if (data) {
      const profile = data.profiles.items[0];
      dispatcher(fetchProfileSuccess(profile));
    }
  } catch (e) {
    console.error(e.message);
  }
}
