import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const BROADCAST = `
  mutation Broadcast($request: BroadcastRequest!) { 
    broadcast(request: $request) {
      ... on RelayerResult {
        txHash
      }
      ... on RelayError {
        reason
      },
      __typename
    }
 }
`;

export const broadcast = (BroadcastRequest) => {
  return apolloClient.mutate({
    mutation: gql(BROADCAST),
    variables: {
      request: BroadcastRequest,
    },
  });
};
