import React, { useState } from "react";
import Button from "../Modules/Button";
import { ReactComponent as CheckIcon } from "../../assets/svg/CheckIcon.svg";
import { useWallet } from "use-wallet";
import PropTypes from "prop-types";
import ConnectWalletModal from "../Modals/ConnectWalletModal";

function FollowButton({
  isFollowedByMe,
  handleFollowButtonLabel,
  handleFollowButtonClick,
  isRevertFollowModule,
  loadingFollow,
  handleUnfollowButtonClick,
  loadingUnfollow,
}) {
  const wallet = useWallet();
  const [showBurn, setShowBurn] = useState(false);
  const [open, setOpen] = useState(false);

  if (isFollowedByMe) {
    return (
      <>
        <Button
          primary
          propsclasses="w-full mt-2"
          disabled={loadingUnfollow}
          loading={loadingUnfollow}
          onClick={handleUnfollowButtonClick}
          onMouseOver={() => setShowBurn(true)}
          onMouseOut={() => setShowBurn(false)}
          label={showBurn || loadingUnfollow ? "🔥 Unfollow" : null}
        >
          {!showBurn && !loadingUnfollow ? (
            <span className="flex items-center gap-2">
              <CheckIcon className="h-3" />
              <p className="text-basil font-subheader font-medium leading-3 uppercase flex items-center">
                Following
              </p>
            </span>
          ) : null}
        </Button>
      </>
    );
  } else {
    return (
      <>
        <ConnectWalletModal open={open} setOpen={setOpen} />
        <Button
          propsclasses="w-full mt-2"
          label={handleFollowButtonLabel()}
          primary
          onClick={
            wallet && wallet.isConnected()
              ? handleFollowButtonClick
              : () => setOpen(true)
          }
          disabled={loadingFollow || isRevertFollowModule}
          // loading={loadingFollow}
        />
      </>
    );
  }
}

export default FollowButton;

FollowButton.propTypes = {
  isFollowedByMe: PropTypes.bool,
  handleFollowButtonLabel: PropTypes.func,
  handleFollowButtonClick: PropTypes.func,
  isRevertFollowModule: PropTypes.bool,
  loadingFollow: PropTypes.bool,
  handleUnfollowButtonClick: PropTypes.func,
  loadingUnfollow: PropTypes.bool,
};
