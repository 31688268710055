export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";

export const FETCH_FOLLOWS_REQUEST = "FETCH_FOLLOWS_REQUEST";
export const FETCH_FOLLOWS_FAILURE = "FETCH_FOLLOWS_FAILURE";
export const FETCH_FOLLOWS_SUCCESS = "FETCH_FOLLOWS_SUCCESS";

export const FETCH_FOLLOWERS_REQUEST = "FETCH_FOLLOWERS_REQUEST";
export const FETCH_FOLLOWERS_FAILURE = "FETCH_FOLLOWERS_FAILURE";
export const FETCH_FOLLOWERS_SUCCESS = "FETCH_FOLLOWERS_SUCCESS";

export const SET_FOLLOW_REQUEST = "SET_FOLLOW_REQUEST";
export const SET_FOLLOW_FAILURE = "SET_FOLLOW_FAILURE";
export const SET_FOLLOW_SUCCESS = "SET_FOLLOW_SUCCESS";

export const SET_UNFOLLOW_REQUEST = "SET_UNFOLLOW_REQUEST";
export const SET_UNFOLLOW_FAILURE = "SET_UNFOLLOW_FAILURE";
export const SET_UNFOLLOW_SUCCESS = "SET_UNFOLLOW_SUCCESS";

export function fetchProfileRequest() {
  return {
    type: FETCH_PROFILE_REQUEST,
  };
}
export function fetchProfileFailure(errorMessage) {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: { errorMessage },
  };
}
export function fetchProfileSuccess(profile) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: { profile },
  };
}

export function setFollowRequest() {
  return {
    type: SET_FOLLOW_REQUEST,
  };
}
export function setFollowFailure(errorMessage) {
  return {
    type: SET_FOLLOW_FAILURE,
    payload: { errorMessage },
  };
}
export function setFollowSuccess() {
  return {
    type: SET_FOLLOW_SUCCESS,
  };
}

export function setUnfollowRequest() {
  return {
    type: SET_UNFOLLOW_REQUEST,
  };
}
export function setUnfollowFailure(errorMessage) {
  return {
    type: SET_UNFOLLOW_FAILURE,
    payload: { errorMessage },
  };
}
export function setUnfollowSuccess() {
  return {
    type: SET_UNFOLLOW_SUCCESS,
  };
}
