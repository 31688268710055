import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_SET_DISPATCHER_TYPED_DATA = `
  mutation CreateSetDispatcherTypedData($options: TypedDataOptions, $request: SetDispatcherRequest!) {
    createSetDispatcherTypedData(options: $options, request: $request) {
      id
      expiresAt
      typedData {
        types {
          SetDispatcherWithSig {
            name
            type
          }
        }
        domain {
          name
          chainId
          version
          verifyingContract
        }
        value {
          nonce
          deadline
          profileId
          dispatcher
        }
      }
    }
  }
`;

export const createSetDispatcherTypedData = (variables) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_SET_DISPATCHER_TYPED_DATA),
    variables
  });
};
