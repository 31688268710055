import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_FOLLOW_TYPED_DATA = `
  mutation CreateFollowTypedData(
    $options: TypedDataOptions
    $request: FollowRequest!
    ) { 
    createFollowTypedData(options: $options, request: $request) {
      id
      expiresAt
      typedData {
        domain {
          name
          chainId
          version
          verifyingContract
        }
        types {
          FollowWithSig {
            name
            type
          }
        }
        value {
          nonce
          deadline
          profileIds
          datas
        }
      }
    }
 }
`;

export const createFollowTypedData = (variables) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_FOLLOW_TYPED_DATA),
    variables,
  });
};
