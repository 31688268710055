import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getFollowModule,
  getFollowModuleInfo,
  getPictureToDisplay,
  profileMatchFormatter,
} from "../../utils/utils";
import LazyImage from "../Modules/LazyImage";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import EmptyRectangle from "../../assets/png/EmptyRectangle.png";
import { ParallaxBanner } from "react-scroll-parallax";
import { useWallet } from "use-wallet";
import FollowButton from "./FollowButton";
import { follow } from "../../_functions/follow";
import { useAppStatus } from "../../_reducers/appStatus";
import PropTypes from "prop-types";
import { unfollow } from "../../_functions/unfollow";
import { useUser } from "../../_reducers/user";
import { ReactComponent as PoapLogo } from "../../assets/svg/PoapLogo.svg";
import { ReactComponent as NftsIcon } from "../../assets/svg/NftsIcon.svg";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { ARRAY_OF_FOLLOWS } from "../../apollo/apolloClient";

function Card({ profile, reward }) {
  const {
    picture,
    handle,
    coverPicture,
    name,
    followModule,
    id,
    bio,
    match,
    isFollowedByMe,
    stats,
  } = profile;

  const { data: arrayOfFollowsData } = useQuery(ARRAY_OF_FOLLOWS);

  const followedByMe =
    isFollowedByMe ||
    (arrayOfFollowsData && arrayOfFollowsData.arrayOfFollows
      ? arrayOfFollowsData.arrayOfFollows.includes(id)
      : false);

  const { totalFollowers, totalFollowing } = stats;

  const isRevertFollowModule =
    followModule && followModule.type === "RevertFollowModule";

  const isProfileFollowModule =
    followModule && followModule.type === "ProfileFollowModule";

  const user = useUser();

  const handleFollowButtonClick = async () => {
    if (
      isProfileFollowModule &&
      wallet &&
      wallet.account &&
      user &&
      user.profiles.length === 0
    ) {
      toast.error("This profile accepts follows only from Lens profiles");
      return;
    }
    if (!wallet.account) {
      toast.error("You need to connect your wallet");
      return;
    }
    if (followModule === null) {
      reward();
      toast.success(`Follow ${handle} submitted!`);
    }
    await follow(
      [
        {
          profile: id,
          followModule: followModule
            ? getFollowModule(
                followModule,
                user && user.defaultProfile
                  ? user.defaultProfile.id
                  : user && user.profiles
                  ? user.profiles[0].id
                  : null
              )
            : null,
        },
      ],
      wallet.account,
      followModule,
      wallet.ethereum,
      user.profiles
    );
  };

  const handleUnfollowButtonClick = async () => {
    await unfollow(profile, wallet.ethereum);
  };

  const handleFollowButtonLabel = () => {
    return getFollowModuleInfo(followModule);
  };

  const wallet = useWallet();

  const appStatus = useAppStatus();
  const loadingUnfollow =
    appStatus && appStatus["SET_UNFOLLOW"] && appStatus["SET_UNFOLLOW"].pending;
  const successFollow =
    appStatus &&
    appStatus["SET_FOLLOW"] &&
    !appStatus["SET_FOLLOW"].pending &&
    !appStatus["SET_FOLLOW"].error;

  const dispatch = useDispatch();
  const resetAppStatus = useCallback(
    () => dispatch(() => ({ type: "RESET_APPSTATUS" })),
    [dispatch]
  );

  useEffect(() => {
    if (successFollow) {
      setTimeout(() => resetAppStatus(), 2000);
    }
  }, [successFollow]);

  if (followedByMe) {
    return <div className="hidden" />;
  }

  return (
    <div className="bg-white rounded-xl shadow-md w-[80vw] md:w-[500px] relative flex flex-col items-center">
      <ParallaxBanner
        layers={[
          {
            image:
              coverPicture && coverPicture.original
                ? coverPicture.original.url.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )
                : EmptyRectangle,
            speed: -10,
          },
        ]}
        style={{ aspectRatio: "2 / 1" }}
        className="rounded-t-xl h-28 sm:h-48 flex justify-center bg-no-repeat bg-cover bg-center w-full"
      />
      <LazyImage
        src={getPictureToDisplay(picture) || FallbackAvatar}
        className="object-cover border-4 border-cream rounded-full w-36 h-36 md:w-52 md:h-52 absolute top-10 sm:top-20 z-10"
        alt="profile_picture"
      />
      <div className="bg-lightBg border-4 border-cream rounded-full w-36 h-36 md:w-52 md:h-52 absolute top-10 sm:top-20" />
      <div className="bg-white rounded-t-xl w-full h-4 absolute top-24 sm:top-44" />
      <div className="mt-20 sm:mt-28 flex flex-col items-center text-center">
        <Link
          to={"/" + handle}
          className={`font-header font-bold ${
            name && name.length > 24
              ? "text-xl md:text-3xl"
              : handle && handle.length > 24
              ? "text-xl md:text-3xl"
              : "text-2xl md:text-4xl"
          }`}
        >
          {name || handle}
        </Link>
        <Link to={"/" + handle} className="font-header sm:text-2xl">
          @{handle}
        </Link>
        <div className="flex items-center gap-4 mx-auto mt-2 px-3 py-1 rounded-lg">
          <span className="font-header text-black flex gap-1">
            <p className="font-header text-black font-semibold">
              {totalFollowers}
            </p>{" "}
            followers
          </span>
          <span className="font-header text-black flex gap-1">
            <p className="font-header text-black font-semibold">
              {totalFollowing}
            </p>{" "}
            following
          </span>
        </div>
        {bio && bio.length ? (
          <p className="font-header sm:text-lg mt-3 px-5 sm:px-12 text-center text-black/80 break-all">
            {bio.length > 125 ? bio.substring(0, 125) + "..." : bio}
          </p>
        ) : null}
        {match && (match.shared_poaps > 0 || match.shared_nfts > 0) ? (
          <div className="mt-6 flex flex-col items-center gap-2">
            {match.shared_poaps > 0 ? (
              <span className="flex items-center gap-3">
                <PoapLogo className="h-7 w-7" />
                <p className="font-header text-black/80">
                  {profileMatchFormatter(match.shared_poaps, "POAP")}
                </p>
              </span>
            ) : null}
            {match.shared_nfts > 0 ? (
              <span className="flex items-center gap-3">
                <NftsIcon className="h-7 w-7" />
                <p className="font-header text-black/80">
                  {profileMatchFormatter(match.shared_nfts, "NFT")}
                </p>
              </span>
            ) : null}
          </div>
        ) : null}
        <div className="mb-8 mt-4">
          <FollowButton
            isFollowedByMe={followedByMe}
            handleFollowButtonLabel={handleFollowButtonLabel}
            handleFollowButtonClick={handleFollowButtonClick}
            isRevertFollowModule={isRevertFollowModule}
            loadingFollow={
              user && user.pendingFollows && user.pendingFollows.includes(id)
            }
            handleUnfollowButtonClick={handleUnfollowButtonClick}
            loadingUnfollow={loadingUnfollow}
          />
        </div>
      </div>
    </div>
  );
}

export default Card;

Card.propTypes = {
  profile: PropTypes.object,
  reward: PropTypes.func,
};
