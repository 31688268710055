import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  FETCH_FOLLOWERS_FAILURE,
  FETCH_PROFILE_SUCCESS,
} from "../_actions/profiles";

const initialState = {};

const profiles = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        [action.payload.profile.handle]: action.payload.profile,
      };
    case FETCH_FOLLOWERS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default profiles;

function selectReducerState(state) {
  return state.profiles;
}

const selectProfiles = createSelector(
  selectReducerState,
  (profiles) => profiles
);

export const useProfiles = () => useSelector(selectProfiles);
