import omitDeep from "omit-deep";
import toast from "react-hot-toast";
import { createSetDefaultProfileTypedData } from "../apollo/createSetDefaultProfileTypedData";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { dispatcher } from "../store";
import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import {
  setDefaultProfile,
  updateDefaultProfileFailure,
  updateDefaultProfileRequest,
  updateDefaultProfileSuccess,
} from "../_actions/user";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import { ethers } from "ethers";
import { LENS_HUB_ABI } from "../utils/lensHubAbi";
import config from "../config";

export const setUpdateDefaultProfileFromTypedData = async (
  result,
  profile,
  provider
) => {
  const { expiresAt, id } = result.data.createSetDefaultProfileTypedData;

  const typedData = omitDeep(
    result.data.createSetDefaultProfileTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(updateDefaultProfileFailure(e));
    console.error(e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(updateDefaultProfileFailure("Broadcast signature expired"));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason || !relayerResult) {
    toast.error(boradcastErrorFormatting(reason));
    return await setDefaultProfileWithSig(
      signature,
      typedData,
      profile,
      provider
    );
  }
  if (txHash) {
    const indexation = await pollUntilIndexed(txHash);
    if (indexation) {
      dispatcher(setDefaultProfile(profile));
      dispatcher(updateDefaultProfileSuccess());
      toast.success("Default profile updated!");
    }
  } else {
    dispatcher(updateDefaultProfileFailure("Error updating default profile"));
    console.error("Error default profile");
    return;
  }
};

export async function setUpdateDefaultProfile(profileId, profile, provider) {
  dispatcher(updateDefaultProfileRequest());
  try {
    const result = await createSetDefaultProfileTypedData(profileId);
    await setUpdateDefaultProfileFromTypedData(result, profile, provider);
  } catch (e) {
    dispatcher(updateDefaultProfileFailure("Error updating default profile"));
    console.error(e);
    return;
  }
}

const setDefaultProfileWithSig = async (
  signature,
  typedData,
  profile,
  provider
) => {
  try {
    const { v, r, s } = splitSignature(signature);
    const lensHub = new ethers.Contract(
      config.contracts.LENS_HUB_CONTRACT_ADDRESS,
      LENS_HUB_ABI,
      getProvider(provider).getSigner()
    );
    const tx = await lensHub.setDefaultProfileWithSig({
      profileId: typedData.value.profileId,
      wallet: typedData.value.wallet,
      sig: {
        v,
        r,
        s,
        deadline: typedData.value.deadline,
      },
    });
    if (tx.hash) {
      const indexation = await pollUntilIndexed(tx.hash);
      if (indexation) {
        dispatcher(setDefaultProfile(profile));
        dispatcher(updateDefaultProfileSuccess());
        toast.success("Default profile updated!");
      }
    } else {
      dispatcher(updateDefaultProfileFailure("Error updating default profile"));
      console.error("Error default profile");
      return;
    }
  } catch (e) {
    dispatcher(updateDefaultProfileFailure(e));
    console.error("Error updating default profile: ", e);
    toast.error(e.message);
    return;
  }
};
