import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { gql, useLazyQuery } from "@apollo/client";
import { SEARCH_USERS_QUERY } from "../../apollo/searchProfiles";
import Spinner from "../Modules/Spinner";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import { shortAddress } from "../../utils/utils";
import Modal from "../Modules/Modal";
import LazyImage from "../Modules/LazyImage";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function MobileSearchModal({ open, setOpen }) {
  const [searchText, setSearchText] = useState("");

  const [searchUsers, { data: searchUsersData, loading: searchUsersLoading }] =
    useLazyQuery(gql(SEARCH_USERS_QUERY));

  const handleSearch = async (evt) => {
    let keyword = evt.target.value;
    setSearchText(keyword);
    searchUsers({
      variables: { request: { type: "PROFILE", query: keyword } },
    });
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="min-h-96 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
        <div className="mt-2 text-left sm:mt-2">
          <span className="flex items-center justify-center border border-black/10 p-2 rounded-md w-full mt-2">
            <SearchIcon />
            <input
              placeholder="Search"
              className="w-5/6 ml-4 outline-none focus:outline-none"
              value={searchText}
              onChange={handleSearch}
            />
          </span>
          <div className="overflow-hidden py-2 mt-4">
            <div className="overflow-y-auto scrollable py-2 max-h-[30vh]">
              {searchUsersLoading ? (
                <div className="flex flex-col items-center justify-center">
                  <Spinner size="h-5 w-5" borderColor="border-basil" />
                </div>
              ) : (
                <div>
                  {searchUsersData &&
                    searchUsersData.search.items.map((profile) => (
                      <Link
                        to={"/" + profile.handle}
                        key={profile.id}
                        className="p-2 hover:bg-basil focus:bg-basil focus:outline-none flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setSearchText("");
                          setOpen(false);
                        }}
                      >
                        <LazyImage
                          alt="search user"
                          src={
                            profile.picture && profile.picture.original
                              ? profile.picture.original.url.replace(
                                  "ipfs://",
                                  "https://lens.infura-ipfs.io/ipfs/"
                                )
                              : FallbackAvatar
                          }
                          className="rounded-full h-8 w-8 object-cover"
                        />
                        <div className="flex flex-col items-start">
                          <p className="font-header font-semibold">
                            {profile.handle}
                          </p>
                          <p className="text-blueGrey text-sm">
                            {shortAddress(profile.ownedBy)}
                          </p>
                        </div>
                      </Link>
                    ))}
                  {searchUsersData &&
                    searchUsersData.search.items &&
                    searchUsersData.search.items.length === 0 && (
                      <div className="px-4 py-2 font-subheader">
                        No matching result
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MobileSearchModal;

MobileSearchModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
