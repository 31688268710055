import React from "react";
import Modal from "../Modules/Modal";
import { ReactComponent as XCircleIconBlack } from "../../assets/svg/XCircleIconBlack.svg";
import PropTypes from "prop-types";
import { useWallet } from "use-wallet";
import { useUser } from "../../_reducers/user";
import {
  getFollowModule,
  getFollowModuleInfo,
  shortAddress,
  shortName,
} from "../../utils/utils";
import LazyImage from "../Modules/LazyImage";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import FollowButton from "../Explorer/FollowButton";
import { follow } from "../../_functions/follow";
import { useAppStatus } from "../../_reducers/appStatus";
import { unfollow } from "../../_functions/unfollow";
import toast from "react-hot-toast";
import { useRouter } from "../../hooks/useRouter";

function FollowRedirectModal({ open, setOpen, profile }) {
  const { id, handle, followModule, isFollowedByMe } = profile;

  const wallet = useWallet();

  const user = useUser();
  const profileToDisplay = user.defaultProfile
    ? user.defaultProfile.name && user.defaultProfile.name.length > 0
      ? user.defaultProfile.name
      : user.defaultProfile.handle
    : wallet.account
    ? shortAddress(wallet.account)
    : "";

  const isRevertFollowModule =
    followModule && followModule.type === "RevertFollowModule";

  const isProfileFollowModule =
    followModule && followModule.type === "ProfileFollowModule";

  const handleFollowButtonClick = async () => {
    if (
      isProfileFollowModule &&
      wallet &&
      wallet.account &&
      user &&
      user.profiles.length === 0
    ) {
      toast.error("This profile accepts follows only from Lens profiles");
    }
    await follow(
      followModule
        ? [
            {
              profile: id,
              followModule: getFollowModule(
                followModule,
                user && user.defaultProfile
                  ? user.defaultProfile.id
                  : user && user.profiles
                  ? user.profiles[0].id
                  : null
              ),
            },
          ]
        : [
            {
              profile: id,
            },
          ],
      wallet.account,
      followModule,
      wallet.ethereum
    );
  };

  const handleUnfollowButtonClick = async () => {
    await unfollow(profile, wallet.ethereum);
  };

  const appStatus = useAppStatus();
  const loadingFollow =
    appStatus && appStatus["SET_FOLLOW"] && appStatus["SET_FOLLOW"].pending;
  const loadingUnfollow =
    appStatus && appStatus["SET_UNFOLLOW"] && appStatus["SET_UNFOLLOW"].pending;

  const router = useRouter();

  return (
    <Modal open={open} setOpen={() => router.push("/" + handle)}>
      <div className="relative min-h-32 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-[550px] sm:w-full sm:p-6 px-4 pt-5 pb-24 sm:pb-24 h-[260px]">
        <button className="absolute top-7 right-7 focus:outline-none">
          <XCircleIconBlack
            className="w-5 h-5"
            onClick={() => setOpen(false)}
          />
        </button>
        <div className="flex flex-col items-start">
          <p className="font-semibold text-xl">Welcome {profileToDisplay}</p>
          <p className="text-black/80">
            Do you confirm you want to follow @{handle}?
          </p>
        </div>
        <div className="my-4 px-4 py-2 bg-peas flex items-center gap-4 rounded-xl">
          <LazyImage
            alt="search user"
            src={
              profile.picture && profile.picture.original
                ? profile.picture.original.url.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )
                : FallbackAvatar
            }
            className="rounded-full h-12 w-12 object-cover"
          />
          <div className="flex flex-col items-start">
            {profile.name ? (
              <p className="font-header font-medium text-black/80">
                <p className="font-header font-semibold">
                  {shortName(profile.name)}
                </p>
              </p>
            ) : null}
            <p className="text-blueGrey text-sm">{profile.handle}</p>
            <p className="text-blueGrey text-sm">
              {shortAddress(profile.ownedBy)}
            </p>
          </div>
        </div>
        <FollowButton
          isFollowedByMe={isFollowedByMe}
          handleFollowButtonLabel={() => getFollowModuleInfo(followModule)}
          handleFollowButtonClick={handleFollowButtonClick}
          isRevertFollowModule={isRevertFollowModule}
          loadingFollow={loadingFollow && user.pendingFollows.includes(id)}
          loadingUnfollow={loadingUnfollow}
          handleUnfollowButtonClick={handleUnfollowButtonClick}
        />
      </div>
    </Modal>
  );
}

export default FollowRedirectModal;

FollowRedirectModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  profile: PropTypes.object,
};
