import toast from "react-hot-toast";
import { allowance } from "../apollo/allowance";
import { getModuleApprovalData } from "../apollo/getModuleApprovalData";
import { dispatcher } from "../store";
import { sendTx } from "../utils/ethersService";
import { setFollowFailure } from "../_actions/profiles";

export async function approveFollowModule(
  currency,
  value,
  followModule,
  provider
) {
  const allowancesResult = await allowance({
    currencies: [currency],
    followModules: ["FeeFollowModule"],
    collectModules: [],
    referenceModules: [],
  });

  // const gasPrice = await ethersProvider.getGasPrice()

  const feeFollowModuleAllowance =
    allowancesResult.data.approvedModuleAllowanceAmount.find(
      (a) => a.module === "FeeFollowModule"
    );

  if (
    feeFollowModuleAllowance &&
    feeFollowModuleAllowance.allowance !== "0x00"
  ) {
    return feeFollowModuleAllowance;
  }

  const generateApprovalModuleData = {
    currency,
    value,
    followModule,
  };

  const result = await getModuleApprovalData(generateApprovalModuleData);

  const generateModuleCurrencyApprovalData =
    result.data.generateModuleCurrencyApprovalData;

  const txData = {
    to: generateModuleCurrencyApprovalData.to,
    from: generateModuleCurrencyApprovalData.from,
    data: generateModuleCurrencyApprovalData.data,
  };

  try {
    const tx = await sendTx(txData, provider);
    await tx.wait();
    toast.success("ERC20 allowed!");
    return tx;
  } catch (e) {
    dispatcher(setFollowFailure("Error on allowance"));
    console.error("Error on ERC20 allowance", e.code);
    toast.error(
      e.code === "INSUFFICIENT_FUNDS"
        ? "Insufficient balance"
        : "Error on ERC20 allowance"
    );
    return null;
  }
}
