import { gql, useLazyQuery } from "@apollo/client";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { SEARCH_USERS_QUERY } from "../../apollo/searchProfiles";
import { ReactComponent as SearchIconBlack } from "../../assets/svg/SearchIconBlack.svg";
import Spinner from "../Modules/Spinner";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import { shortAddress, shortName } from "../../utils/utils";
import LazyImage from "../Modules/LazyImage";
import { useRouter } from "../../hooks/useRouter";

function HeaderSearchBar() {
  const router = useRouter();
  const [searchText, setSearchText] = useState("");
  const [searchUsers, { data: searchUsersData, loading: searchUsersLoading }] =
    useLazyQuery(gql(SEARCH_USERS_QUERY));
  const handleSearch = async (evt) => {
    let keyword = evt.target.value;
    setSearchText(keyword);
    searchUsers({
      variables: { request: { type: "PROFILE", query: keyword } },
    });
  };
  return (
    <Popover className="relative" aria-modal>
      <label className="flex items-center gap-2 focus:outline-none bg-white px-4 py-2 h-12 my-4 rounded-full">
        <SearchIconBlack />
        <input
          value={searchText}
          onChange={handleSearch}
          placeholder="Search by profile..."
          className="bg-transparent focus:outline-none text-black placeholder:text-black/60 font-light"
          onBlur={() => setSearchText("")}
        />
      </label>
      {searchText.length > 0 && (
        <Transition
          show={searchText.length > 0}
          as={Fragment}
          enter="transition ease-out duration-800"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            static
            className="bg-white absolute rounded-lg shadow-xl w-[400px]"
          >
            <div className="py-2">
              <div className="overflow-y-auto scrollable p-2 max-h-[30vh] min-h-max">
                {searchUsersLoading ? (
                  <div className="flex flex-col items-center justify-center h-full">
                    <Spinner size="h-5 w-5" borderColor="border-basil" />
                  </div>
                ) : (
                  <div>
                    {searchUsersData &&
                      searchUsersData.search.items.map((profile) => (
                        <p
                          role="link"
                          key={profile.id}
                          className="p-2 hover:bg-peas focus:bg-peas focus:outline-none flex items-center gap-3 cursor-pointer rounded-xl"
                          onMouseDown={() => {
                            router.push("/" + profile.handle);
                            setSearchText("");
                          }}
                        >
                          <LazyImage
                            alt="search user"
                            src={
                              profile.picture && profile.picture.original
                                ? profile.picture.original.url.replace(
                                    "ipfs://",
                                    "https://lens.infura-ipfs.io/ipfs/"
                                  )
                                : FallbackAvatar
                            }
                            className="rounded-full h-8 w-8 object-cover"
                          />
                          <div className="flex flex-col items-start">
                            {profile.name ? (
                              <p className="font-header font-medium text-black/80">
                                <p className="font-header font-semibold">
                                  {shortName(profile.name)}
                                </p>
                              </p>
                            ) : null}
                            <p className="text-blueGrey text-sm">
                              {profile.handle}
                            </p>
                            <p className="text-blueGrey text-sm">
                              {shortAddress(profile.ownedBy)}
                            </p>
                          </div>
                        </p>
                      ))}
                    {searchUsersData &&
                      searchUsersData.search.items &&
                      searchUsersData.search.items.length === 0 && (
                        <div className="px-4 py-2 font-subheader">
                          No matching result
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      )}
    </Popover>
  );
}

export default HeaderSearchBar;
