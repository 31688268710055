import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../Modules/Button";
import Tooltip from "../Modules/Tooltip";
import Input from "../Modules/Input";
import { useRouter } from "../../hooks/useRouter";
import { useUser } from "../../_reducers/user";
import { useWallet } from "use-wallet";
import { setFollowModule } from "../../_functions/setFollowModule";
import config from "../../config";
import { useAppStatus } from "../../_reducers/appStatus";
import { setDispatcher } from "../../_functions/setDispatcher";

const DISPATCHER_INFO_TEXT = `Sign-less experience allows you to do some operations without having to sign all transactions. You can update your profile info here, you can also post, comment and mirror on other platforms without having to sign transactions.`;

function EditSettings({ profile, loadingFollowModuleUpdate }) {
  const { handle, id, followModule, dispatcher } = profile;

  const router = useRouter();

  const wallet = useWallet();

  const initialFeeFollowModuleData =
    followModule && followModule.type === "FeeFollowModule"
      ? {
          asset: followModule.amount.asset.address,
          value: followModule.amount.value,
          type: followModule.type,
        }
      : followModule;

  const [newFollowModule, setNewFollowModule] = useState(
    initialFeeFollowModuleData
  );

  const submitFollowModule = async () => {
    let followModuleToUpdate;
    if (newFollowModule === null) {
      followModuleToUpdate = { freeFollowModule: true };
    } else if (newFollowModule.type === "FeeFollowModule") {
      followModuleToUpdate = {
        feeFollowModule: {
          amount: {
            currency: newFollowModule.asset,
            value: newFollowModule.value,
          },
          recipient: wallet.account,
        },
      };
    } else if (newFollowModule.type === "ProfileFollowModule") {
      followModuleToUpdate = { profileFollowModule: true };
    } else {
      followModuleToUpdate = { revertFollowModule: true };
    }
    await setFollowModule(id, followModuleToUpdate, wallet.ethereum);
  };

  return (
    <>
      <DispatcherEdit dispatcher={dispatcher} profileId={id} />
      <FollowModuleEdit
        followModule={followModule}
        newFollowModule={newFollowModule}
        disabled={loadingFollowModuleUpdate || false}
        setNewFollowModule={setNewFollowModule}
      />
      <div className="mt-8 flex flex-col md:flex-row items-center gap-4">
        <Button
          label="Cancel"
          propsclasses="w-full md:w-1/2"
          onClick={() => router.push("/" + handle)}
          disabled={loadingFollowModuleUpdate}
        />
        <Button
          loading={loadingFollowModuleUpdate}
          label="Save"
          primary
          propsclasses="w-full md:w-1/2"
          onClick={submitFollowModule}
          disabled={loadingFollowModuleUpdate}
        />
      </div>
    </>
  );
}

export default EditSettings;

function FollowModuleEdit({ newFollowModule, disabled, setNewFollowModule }) {
  const user = useUser();
  const { enabledCurrencies } = user;

  const defaultFeeFollowModule = {
    type: "FeeFollowModule",
    asset: config.contracts.WMATIC_ADDRESS,
    value: 0.0,
  };

  return (
    <>
      <p className="self-start font-medium text-lg font-header mt-4">
        Follow Settings
      </p>
      <div className="grid grid-cols-2 gap-4 w-full">
        <button
          onClick={() => setNewFollowModule(null)}
          disabled={disabled}
          className={`font-header bg-lightPurple p-3 w-full rounded-lg flex items-center gap-2 justify-center text-sm ${
            !newFollowModule
              ? "font-semibold border-2 border-blueGrey/70  cursor-default"
              : "text-black/70"
          }`}
        >
          <>Anyone can follow you</>
          <Tooltip label={"Any wallet address can follow you."} />
        </button>
        <button
          onClick={() => setNewFollowModule(defaultFeeFollowModule)}
          disabled={disabled}
          className={`font-header bg-lightPurple p-3 w-full rounded-lg flex items-center gap-2 justify-center text-sm ${
            newFollowModule && newFollowModule.type === "FeeFollowModule"
              ? "font-semibold border-2 border-blueGrey/70  cursor-default"
              : "text-black/70"
          }`}
        >
          <>Charge to follow</>
        </button>
        <button
          onClick={() => setNewFollowModule({ type: "ProfileFollowModule" })}
          disabled={disabled}
          className={`font-header bg-lightPurple p-3 w-full rounded-lg flex items-center gap-2 justify-center disabled:cursor-not-allowed text-sm ${
            newFollowModule && newFollowModule.type === "ProfileFollowModule"
              ? "font-semibold border-2 border-blueGrey/70  "
              : "text-black/70"
          }`}
        >
          <>Lens to Lens profiles</>
          <Tooltip label={"Only Lens profiles can follow you."} />
        </button>
        <button
          onClick={() => setNewFollowModule({ type: "RevertFollowModule" })}
          disabled={disabled}
          className={`font-header bg-lightPurple p-3 w-full rounded-lg flex items-center gap-2 justify-center disabled:cursor-not-allowed text-sm ${
            newFollowModule && newFollowModule.type === "RevertFollowModule"
              ? "font-semibold border-2 border-blueGrey/70  "
              : "text-black/70"
          }`}
        >
          <>No one can follow</>
        </button>
      </div>
      {newFollowModule && newFollowModule.type === "FeeFollowModule" ? (
        <div className="w-full flex gap-2">
          <Input
            label="Asset"
            value={newFollowModule.asset}
            select
            selectOptions={enabledCurrencies.map((currency) => ({
              value: currency.address,
              label: currency.symbol,
            }))}
            handler={(e) =>
              setNewFollowModule({ ...newFollowModule, asset: e.target.value })
            }
            disabled={disabled}
          />
          <Input
            label="Value"
            type="number"
            value={newFollowModule.value}
            handler={(e) =>
              e.target.value >= 0
                ? setNewFollowModule({
                    ...newFollowModule,
                    value: e.target.value,
                  })
                : null
            }
            disabled={disabled}
          />
        </div>
      ) : null}
    </>
  );
}

function DispatcherEdit({ dispatcher, profileId }) {
  const canUseRelay = dispatcher ? dispatcher.canUseRelay : false;

  const wallet = useWallet();

  const appStatus = useAppStatus();

  const setDispatcherLoading =
    appStatus["TOGGLE_DISPATCHER"] && appStatus["TOGGLE_DISPATCHER"].pending;

  return (
    <>
      <p className="self-start font-medium text-lg font-header mt-4">
        {`🌿✨ Sign-less experience`}
      </p>
      <p className="text-sm mt-1">{DISPATCHER_INFO_TEXT}</p>
      <div className="w-full">
        <span className="flex items-center justify-start gap-3 mt-4">
          <div
            className={`relative rounded-full w-12 h-6 transition duration-200 ease-linear ${
              canUseRelay ? "bg-basil" : "bg-blueGrey"
            }`}
          >
            <label
              htmlFor="hideToggle"
              className={`absolute left-0 bg-white border-2 mb-2 w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer ${
                canUseRelay
                  ? "translate-x-full border-basil"
                  : "translate-x-0 border-blueGrey"
              }`}
            />
            <input
              disabled={setDispatcherLoading}
              type="checkbox"
              id="hideToggle"
              name="hideToggle"
              className="appearance-none w-full h-full active:outline-none focus:outline-none disabled:cursor-not-allowed"
              checked={canUseRelay}
              onClick={async () => {
                await setDispatcher(
                  profileId,
                  !canUseRelay,
                  wallet.account,
                  wallet.ethereum
                );
              }}
            />
          </div>
          <p className="text-sm">
            {setDispatcherLoading
              ? "Setting up the Sign-less experience..."
              : `${
                  canUseRelay ? "Disable" : "Enable"
                } sign-less experience with the dispatcher.`}
          </p>
        </span>
      </div>
    </>
  );
}

EditSettings.propTypes = {
  profile: PropTypes.object,
  loadingFollowModuleUpdate: PropTypes.bool,
};

FollowModuleEdit.propTypes = {
  newFollowModule: PropTypes.object,
  disabled: PropTypes.bool,
  setNewFollowModule: PropTypes.func,
};

DispatcherEdit.propTypes = {
  dispatcher: PropTypes.object,
  profileId: PropTypes.string,
};
