import { combineReducers } from "redux";
import appStatus from "./appStatus";
import user from "./user";
import profiles from "./profiles";

export const rootReducer = combineReducers({
  appStatus,
  user,
  profiles,
});
