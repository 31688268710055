import React from "react";
import ExplorerList from "./ExplorerList";

function Explorer() {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-2 mb-6 text-left mx-6 sm:mx-0">
        <p className="font-header text-black uppercase font-bold text-3xl mb-3">
          Profiles based on your web3 footprint
        </p>
      </div>
      <div className="mb-12 flex flex-col items-center gap-8">
        <ExplorerList />
      </div>
    </div>
  );
}

export default Explorer;
