import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const EXPLORE_PROFILES = `
query ExploreProfiles($request: ExploreProfilesRequest!) {
  exploreProfiles(request: $request) {
    items {
      id
      name
      bio
      metadata
      picture {
        ... on NftImage {
          contractAddress
          tokenId
          uri
          verified
        }
        ... on MediaSet {
          original {
            url
            width
            height
            mimeType
          }
        }
        __typename
      }
      handle
      coverPicture {
        ... on NftImage {
          contractAddress
          tokenId
          uri
          verified
        }
        ... on MediaSet {
          original {
            url
            mimeType
          }
        }
        __typename
      }
      ownedBy
      dispatcher {
        address
        canUseRelay
      }
      stats {
        totalFollowers
        totalFollowing
        totalPosts
        totalComments
        totalMirrors
        totalPublications
        totalCollects
      }
      isFollowedByMe
      followModule {
        ... on FeeFollowModuleSettings {
          type
          amount {
            asset {
              symbol
              name
              decimals
              address
            }
            value
          }
          recipient
        }
        ... on ProfileFollowModuleSettings {
          type
        }
        ... on RevertFollowModuleSettings {
          type
        }
        __typename
      }
    }
    pageInfo {
      prev
      next
      totalCount
    }
  }
}
`;

export const explorePublicationProfiles = (request) => {
  return apolloClient.query({
    query: gql(EXPLORE_PROFILES),
    variables: {
      request: request,
    },
  });
};
