import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./screens/Home";
import Profile from "./screens/Profile";
import "./styles.css";
import ErrorBoundaryWrapper from "./components/Modules/ErrorBoundaryWrapper";
import toast, { resolveValue, Toaster } from "react-hot-toast";
import { useWallet } from "use-wallet";
import jwtDecode from "jwt-decode";
import { getUserInfo } from "./_functions/getUserInfo";
import { getTokens } from "./utils/localStorageUtils";
import { getUserNfts } from "./_functions/getUserNfts";
import { authenticateConnected } from "./_functions/authenticateConnected";
import NotFound from "./components/Modules/NotFound";
import config from "./config";
import FollowProfile from "./screens/FollowProfile";

const toastOptions = {
  error: {
    style: {
      background: "white",
      color: "black",
      borderLeft: "4px solid #F45C5C",
    },
    className: "font-subheader",
  },
  success: {
    style: {
      background: "white",
      text: "black",
      borderLeft: "4px solid #ABFE2C",
    },
    className: "font-subheader",
  },
};

function App() {
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.error) {
      toast.error(
        wallet.error.name === "ChainUnsupportedError"
          ? `Your are on the wrong network. Switch to ${config.chain.CHAIN_NAME}.`
          : wallet.error.name === "NoEthereumProviderError"
          ? "No wallet provider found on this browser."
          : wallet.error.message
      );
    }
  }, [wallet.error]);

  useEffect(() => {
    if (wallet.account) {
      const auth = getTokens();
      const { exp } =
        auth && auth.accessToken ? jwtDecode(auth.accessToken) : "";
      if (Date.now() < exp * 1000) {
        getUserInfo(wallet.account);
        getUserNfts(wallet.account);
      }
      if (!auth || Date.now() >= exp * 1000) {
        authenticateConnected(wallet.account, wallet.ethereum);
      }
    }
  }, [wallet.account]);

  useEffect(() => {
    if (
      window.localStorage.getItem("walletconnect") &&
      JSON.parse(window.localStorage.getItem("walletconnect")).connected
    ) {
      wallet.connect("walletconnect");
    }
  }, []);

  const [appLoad, setAppLoad] = useState(true);

  useEffect(() => {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 3000);
    }

    function handleEthereum() {
      const { ethereum } = window;
      if (ethereum && ethereum) {
        setAppLoad(false);
      } else {
        toast.error("No wallet provider found");
        setAppLoad(false);
      }
    }
  }, [setAppLoad]);

  useEffect(() => {
    if (window.ethereum && wallet.chainId !== config.chain.CHAIN_ID) {
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${config.chain.CHAIN_ID.toString(16)}`,
          },
        ],
      });
    }
  }, [wallet.chainId]);

  if (appLoad) {
    return (
      <div className="w-full min-h-screen bg-lightBg flex flex-col items-center md:pb-12 xl:pb-0">
        <p className="text-[50px] animate-ping m-auto">🌿</p>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-lightBg">
      <Toaster position="bottom-right" toastOptions={toastOptions}>
        {(t) => (
          <div
            style={{
              ...toastOptions[t.type].style,
              padding: "10px 15px",
              borderRadius: "5px",
            }}
          >
            <p className="leading-none">{resolveValue(t.message, t)}</p>
            {t.type === "error" ? (
              <button
                className="underline text-xs text-blueGrey"
                onClick={() => navigator.clipboard.writeText(t.message)}
              >
                Copy message
              </button>
            ) : null}
          </div>
        )}
      </Toaster>
      <Router>
        <ErrorBoundaryWrapper>
          <Header />
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route exact path="/:handle" render={() => <Profile />} />
            <Route
              exact
              path="/:handle/edit"
              render={() => <Profile isEdit={true} />}
            />
            <Route
              exact
              path="/:handle/follow"
              render={() => <FollowProfile isFollow={true} />}
            />
            <Route path="" component={NotFound} />
          </Switch>
        </ErrorBoundaryWrapper>
      </Router>
    </div>
  );
}

export default App;
