import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function LazyImage({ src, alt, className, ...rest }) {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState();
  useEffect(() => {
    let mounted = true;
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      if (mounted) {
        setLoading(false);
        updateSrc(src);
      }
    };
    return function cleanup() {
      mounted = false;
    };
  }, [src, updateSrc, setLoading]);
  if (loading) {
    return (
      <div className={className}>
        <div className="rounded-full relative w-full h-full bg-white">
          <div className="rounded-full absolute w-full h-full bg-lime/50 animate-pulse" />
        </div>
      </div>
    );
  } else {
    return <img src={currentSrc} className={className} alt={alt} {...rest} />;
  }
}

export default LazyImage;

LazyImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};
