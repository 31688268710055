import React, { useState } from "react";
import { ReactComponent as SearchIconBlack } from "../../assets/svg/SearchIconBlack.svg";
import MobileSearchModal from "../Modals/MobileSearchModal";

function MobileHeaderSearch() {
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <button
      className="bg-lime h-12 w-12 flex items-center justify-center cursor-pointer rounded-full"
      onClick={() => setSearchOpen(true)}
    >
      <MobileSearchModal open={searchOpen} setOpen={setSearchOpen} />
      <SearchIconBlack />
    </button>
  );
}

export default MobileHeaderSearch;
