import omitDeep from "omit-deep";
import toast from "react-hot-toast";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { dispatcher } from "../store";
import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import {
  toggleFollowFailure,
  toggleFollowRequest,
  toggleFollowSuccess,
} from "../_actions/user";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import { ethers } from "ethers";
import { LENS_PERIPHERY_ABI } from "../utils/lensPeripheryAbi";
import config from "../config";
import { createToggleFollowTypedData } from "../apollo/createToggleFollowTypedData";

export const toggleFollowFromTypedData = async (result, provider, follower) => {
  const { expiresAt, id } = result.data.createToggleFollowTypedData;

  const typedData = omitDeep(
    result.data.createToggleFollowTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(toggleFollowFailure(e));
    console.error(e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(toggleFollowFailure("Broadcast signature expired"));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason || !relayerResult) {
    toast.error(boradcastErrorFormatting(reason));
    return await followToggleWithSig(signature, typedData, follower, provider);
  }
  if (txHash) {
    const indexation = await pollUntilIndexed(txHash);
    if (indexation) {
      dispatcher(toggleFollowSuccess());
      toast.success("Approvals submitted!");
    }
  } else {
    dispatcher(toggleFollowFailure("Error approving followings"));
    console.error("Error approving followings");
    return;
  }
};

export async function toggleFollow(profileIds, enables, provider, userAddress) {
  const follower = ethers.utils.getAddress(userAddress);
  dispatcher(toggleFollowRequest());
  try {
    const result = await createToggleFollowTypedData(profileIds, enables);
    await toggleFollowFromTypedData(result, provider, follower);
  } catch (e) {
    dispatcher(toggleFollowFailure("Error toggling follows"));
    console.error(e);
    return;
  }
}

const followToggleWithSig = async (
  signature,
  typedData,
  follower,
  provider
) => {
  try {
    const { v, r, s } = splitSignature(signature);
    const lensPeriphery = new ethers.Contract(
      config.contracts.LENS_PERIPHERY_CONTRACT_ADDRESS,
      LENS_PERIPHERY_ABI,
      getProvider(provider).getSigner()
    );
    const tx = await lensPeriphery.toggleFollowWithSig({
      follower,
      profileIds: typedData.value.profileIds,
      enables: typedData.value.enables,
      sig: {
        v,
        r,
        s,
        deadline: typedData.value.deadline,
      },
    });
    if (tx.hash) {
      const indexation = await pollUntilIndexed(tx.hash);
      if (indexation) {
        dispatcher(toggleFollowSuccess());
        toast.success("Approvals submitted!");
      }
    } else {
      dispatcher(toggleFollowFailure("Error submitting approvals"));
      console.error("Error submitting approvals");
      return;
    }
  } catch (e) {
    dispatcher(toggleFollowFailure(e));
    console.error("Error submitting approvals: ", e);
    toast.error(e.message);
    return;
  }
};
