import { dispatcher } from "../store";
import { getProvider } from "../utils/ethersService";
import { setWallet } from "../_actions/user";
import { authenticate } from "./authenticate";

export const authenticateConnected = (userAddress, ethereum) => {
  dispatcher(setWallet(userAddress));
  const provider = getProvider(ethereum);
  authenticate(userAddress, provider);
};
