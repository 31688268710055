export const USER_AUTH_REQUEST = "USER_AUTH_REQUEST";
export const USER_AUTH_FAILURE = "USER_AUTH_FAILURE";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_FAILURE = "USER_INFO_FAILURE";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";

export const GET_PROFILES_REQUEST = "GET_PROFILES_REQUEST";
export const GET_PROFILES_FAILURE = "GET_PROFILES_FAILURE";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";

export const GET_NFTS_REQUEST = "GET_NFTS_REQUEST";
export const GET_NFTS_FAILURE = "GET_NFTS_FAILURE";
export const GET_NFTS_SUCCESS = "GET_NFTS_SUCCESS";

export const SET_WALLET = "SET_WALLET";
export const SET_DEFAULT_PROFILE = "SET_DEFAULT_PROFILE";
export const SET_DPFP_HAS_PIC = "SET_DPFP_HAS_PIC";
export const SET_CURRENCIES = "SET_CURRENCIES";

export const UPDATE_PFP_REQUEST = "UPDATE_PFP_REQUEST";
export const UPDATE_PFP_FAILURE = "UPDATE_PFP_FAILURE";
export const UPDATE_PFP_SUCCESS = "UPDATE_PFP_SUCCESS";

export const UPDATE_DPROFILE_REQUEST = "UPDATE_DPROFILE_REQUEST";
export const UPDATE_DPROFILE_FAILURE = "UPDATE_DPROFILE_FAILURE";
export const UPDATE_DPROFILE_SUCCESS = "UPDATE_DPROFILE_SUCCESS";

export const UPDATE_METADATA_REQUEST = "UPDATE_METADATA_REQUEST";
export const UPDATE_METADATA_FAILURE = "UPDATE_METADATA_FAILURE";
export const UPDATE_METADATA_SUCCESS = "UPDATE_METADATA_SUCCESS";

export const GET_SIMILAR_REQUEST = "GET_SIMILAR_REQUEST";
export const GET_SIMILAR_FAILURE = "GET_SIMILAR_FAILURE";
export const GET_SIMILAR_SUCCESS = "GET_SIMILAR_SUCCESS";

export const UPDATE_FMODULE_REQUEST = "UPDATE_FMODULE_REQUEST";
export const UPDATE_FMODULE_FAILURE = "UPDATE_FMODULE_FAILURE";
export const UPDATE_FMODULE_SUCCESS = "UPDATE_FMODULE_SUCCESS";

export const TOGGLE_FOLLOW_REQUEST = "TOGGLE_FOLLOW_REQUEST";
export const TOGGLE_FOLLOW_FAILURE = "TOGGLE_FOLLOW_FAILURE";
export const TOGGLE_FOLLOW_SUCCESS = "TOGGLE_FOLLOW_SUCCESS";

export const TOGGLE_DISPATCHER_REQUEST = "TOGGLE_DISPATCHER_REQUEST";
export const TOGGLE_DISPATCHER_FAILURE = "TOGGLE_DISPATCHER_FAILURE";
export const TOGGLE_DISPATCHER_SUCCESS = "TOGGLE_DISPATCHER_SUCCESS";

export const UPDATE_PENDING_FOLLOWS = "UPDATE_PENDING_FOLLOWS";
export const UPDATE_FOLLOWED = "UPDATE_FOLLOWED";

export const SET_USER_SIG_NONCES = "SET_USER_SIG_NONCES";

export function setWallet(wallet) {
  return {
    type: SET_WALLET,
    payload: { wallet },
  };
}

export function setDefaultProfile(defaultProfile) {
  return {
    type: SET_DEFAULT_PROFILE,
    payload: { defaultProfile },
  };
}

export function setCurrencies(enabledCurrencies) {
  return {
    type: SET_CURRENCIES,
    payload: { enabledCurrencies },
  };
}

export function userAuthRequest() {
  return {
    type: USER_AUTH_REQUEST,
  };
}
export function userAuthFailure(errorMessage) {
  return {
    type: USER_AUTH_FAILURE,
    payload: { errorMessage },
  };
}
export function userAuthSuccess(authToken) {
  return {
    type: USER_AUTH_SUCCESS,
    payload: { authToken },
  };
}

export function userInfoRequest() {
  return {
    type: USER_INFO_REQUEST,
  };
}
export function userInfoFailure(errorMessage) {
  return {
    type: USER_INFO_FAILURE,
    payload: { errorMessage },
  };
}
export function userInfoSuccess() {
  return {
    type: USER_INFO_SUCCESS,
  };
}

export function getProfilesRequest() {
  return {
    type: GET_PROFILES_REQUEST,
  };
}
export function getProfilesFailure(errorMessage) {
  return {
    type: GET_PROFILES_FAILURE,
    payload: { errorMessage },
  };
}
export function getProfilesSuccess(profiles) {
  return {
    type: GET_PROFILES_SUCCESS,
    payload: { profiles },
  };
}

export function getNftsRequest() {
  return {
    type: GET_NFTS_REQUEST,
  };
}
export function getNftsFailure(errorMessage) {
  return {
    type: GET_NFTS_FAILURE,
    payload: { errorMessage },
  };
}
export function getNftsSuccess(nfts) {
  return {
    type: GET_NFTS_SUCCESS,
    payload: { nfts },
  };
}

export function updatePfpRequest() {
  return {
    type: UPDATE_PFP_REQUEST,
  };
}
export function updatePfpFailure(errorMessage) {
  return {
    type: UPDATE_PFP_FAILURE,
    payload: { errorMessage },
  };
}
export function updatePfpSuccess() {
  return {
    type: UPDATE_PFP_SUCCESS,
  };
}

export function updateDefaultProfileRequest() {
  return {
    type: UPDATE_DPROFILE_REQUEST,
  };
}
export function updateDefaultProfileFailure(errorMessage) {
  return {
    type: UPDATE_DPROFILE_FAILURE,
    payload: { errorMessage },
  };
}
export function updateDefaultProfileSuccess() {
  return {
    type: UPDATE_DPROFILE_SUCCESS,
  };
}

export function updateMetadataRequest() {
  return {
    type: UPDATE_METADATA_REQUEST,
  };
}
export function updateMetadataFailure(errorMessage) {
  return {
    type: UPDATE_METADATA_FAILURE,
    payload: { errorMessage },
  };
}
export function updateMetadataSuccess() {
  return {
    type: UPDATE_METADATA_SUCCESS,
  };
}

export function getSimilarRequest() {
  return {
    type: GET_SIMILAR_REQUEST,
  };
}
export function getSimilarFailure(errorMessage) {
  return {
    type: GET_SIMILAR_FAILURE,
    payload: { errorMessage },
  };
}
export function getSimilarSuccess(similarProfiles) {
  return {
    type: GET_SIMILAR_SUCCESS,
    payload: { similarProfiles },
  };
}

export function updateFollowModuleRequest() {
  return {
    type: UPDATE_FMODULE_REQUEST,
  };
}
export function updateFollowModuleFailure(errorMessage) {
  return {
    type: UPDATE_FMODULE_FAILURE,
    payload: { errorMessage },
  };
}
export function updateFollowModuleSuccess() {
  return {
    type: UPDATE_FMODULE_SUCCESS,
  };
}

export function updatePendingFollows(profileIds) {
  return {
    type: UPDATE_PENDING_FOLLOWS,
    payload: { profileIds },
  };
}

export function updateFollowed(profile) {
  return {
    type: UPDATE_FOLLOWED,
    payload: { profile },
  };
}

export function setUserSigNonces(nonce) {
  return {
    type: SET_USER_SIG_NONCES,
    payload: { nonce },
  };
}

export function toggleFollowRequest() {
  return {
    type: TOGGLE_FOLLOW_REQUEST,
  };
}
export function toggleFollowFailure(errorMessage) {
  return {
    type: TOGGLE_FOLLOW_FAILURE,
    payload: { errorMessage },
  };
}
export function toggleFollowSuccess() {
  return {
    type: TOGGLE_FOLLOW_SUCCESS,
  };
}

export function toggleDispatcherRequest() {
  return {
    type: TOGGLE_DISPATCHER_REQUEST,
  };
}
export function toggleDispatcherFailure(errorMessage) {
  return {
    type: TOGGLE_DISPATCHER_FAILURE,
    payload: { errorMessage },
  };
}
export function toggleDispatcherSuccess() {
  return {
    type: TOGGLE_DISPATCHER_SUCCESS,
  };
}
