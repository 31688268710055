import React from "react";
import { Popover, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { openSeaUrl, polygonScanUrl } from "../../utils/utils";
import { gql, useQuery } from "@apollo/client";
import { ReactComponent as DotsIcon } from "../../assets/svg/DotsIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/svg/CopyIcon.svg";
import { ReactComponent as OpenSeaLogo } from "../../assets/svg/OpenSeaLogo.svg";
import { ReactComponent as PolygonLogo } from "../../assets/svg/PolygonLogo.svg";
import { useWallet } from "use-wallet";
import PropTypes from "prop-types";
import config from "../../config";

function DetailsTooltip(props) {
  const { ownedBy, id } = props;

  const wallet = useWallet();

  const GET_FOLLOWER_NFT_TOKEN_IDS = `
  query($request: FollowerNftOwnedTokenIdsRequest!) {
    followerNftOwnedTokenIds(request: $request) { 
            followerNftAddress
            tokensIds
        }
  }
`;

  const { data } = useQuery(gql(GET_FOLLOWER_NFT_TOKEN_IDS), {
    variables: {
      request: {
        address: wallet.account,
        profileId: id,
      },
    },
  });

  return (
    <div {...props}>
      <div className="hidden sm:flex items-center gap-1">
        {data &&
        data.followerNftOwnedTokenIds &&
        data.followerNftOwnedTokenIds.tokensIds.length ? (
          <a
            title="View Follower NFT on OpenSea"
            href={openSeaUrl(
              data.followerNftOwnedTokenIds.followerNftAddress,
              data.followerNftOwnedTokenIds.tokensIds[0],
              config.chain.CHAIN_ID
            )}
            target="_blank"
            rel="noreferrer"
            className="h-7 w-7 p-1 rounded-full flex items-center justify-center bg-lightPurple hover:bg-purple/10"
          >
            <OpenSeaLogo className="h-5 w-5" />
          </a>
        ) : null}
        <a
          title="View address on PolygonScan"
          href={polygonScanUrl(ownedBy, config.chain.CHAIN_ID)}
          target="_blank"
          rel="noreferrer"
          className="h-7 w-7 p-1 rounded-full flex items-center justify-center bg-lightPurple hover:bg-purple/10"
        >
          <PolygonLogo className="h-5 w-5" />
        </a>
        <button
          title="Copy profile URL"
          className="h-7 w-7 p-1 rounded-full flex items-center justify-center bg-lightPurple hover:bg-purple/10"
          onClick={() => {
            navigator.clipboard.writeText(window.location.href);
            toast.success("Profile URL copied to clipboard");
          }}
        >
          <CopyIcon className="h-4 w-4" />
        </button>
      </div>
      <Popover className="block sm:hidden">
        <div className="relative">
          <Popover.Button>
            <DotsIcon />
          </Popover.Button>
          <Transition
            enter="transition ease-out duration-800"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="bg-white absolute z-10 rounded-lg shadow-xl w-48 top-8 -right-2 ">
              <div className="flex flex-col items-start py-2">
                <button
                  className="flex items-center gap-2 text-left text-subheader text-sm cursor-pointer w-full px-4 py-3 hover:bg-lightPurple hover:text-purple focus:bg-lightPurple focus:text-purple focus:outline-none"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    toast.success("Profile URL copied to clipboard");
                  }}
                >
                  <CopyIcon className="h-4 w-4" />
                  <>Copy link</>
                </button>
                {data &&
                data.followerNftOwnedTokenIds &&
                data.followerNftOwnedTokenIds.tokensIds.length ? (
                  <a
                    href={openSeaUrl(
                      data.followerNftOwnedTokenIds.followerNftAddress,
                      data.followerNftOwnedTokenIds.tokensIds[0],
                      config.chain.CHAIN_ID
                    )}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-2 text-subheader text-sm cursor-pointer w-full px-4 py-3 hover:bg-lightPurple hover:text-purple focus:bg-lightPurple focus:text-purple focus:outline-none"
                  >
                    <OpenSeaLogo className="h-5 w-5" />
                    <p>View on OpenSea</p>
                  </a>
                ) : null}
                <a
                  href={polygonScanUrl(ownedBy, config.chain.CHAIN_ID)}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2 text-subheader text-sm cursor-pointer w-full px-4 py-3 hover:bg-lightPurple hover:text-purple focus:bg-lightPurple focus:text-purple focus:outline-none"
                >
                  <PolygonLogo className="h-5 w-5" />
                  <p>View on PolygonScan</p>
                </a>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      </Popover>
    </div>
  );
}

export default DetailsTooltip;

DetailsTooltip.propTypes = {
  ownedBy: PropTypes.string,
  id: PropTypes.string,
};
