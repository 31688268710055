import {
  getProvider,
  getSigner,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import { ERC20_ABI } from "../utils/erc20Abi";
import omitDeep from "omit-deep";
import { dispatcher, store } from "../store";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { createFollowTypedData } from "../apollo/createFollowTypedData";
import {
  setFollowFailure,
  setFollowRequest,
  setFollowSuccess,
} from "../_actions/profiles";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { approveFollowModule } from "./approveFollowModule";
import { refreshProfile } from "./refreshProfile";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import { setUserSigNonces, updatePendingFollows } from "../_actions/user";
import { LENS_HUB_ABI } from "../utils/lensHubAbi";
import config from "../config";
import { followWithProxy } from "./followWithProxy";

export const follow = async (
  profilesToFollow,
  userAddress,
  followModule,
  provider,
  userProfiles
) => {
  dispatcher(setFollowRequest());

  if (followModule === null) {
    const pendingFollows = store.getState().user.pendingFollows || [];
    const toFlagFollowing = pendingFollows.concat(
      profilesToFollow.map((p) => p.profile)
    );

    dispatcher(updatePendingFollows(toFlagFollowing));
  } else {
    dispatcher(updatePendingFollows([]));
  }

  const address = ethers.utils.getAddress(userAddress);

  if (followModule && followModule.type === "FeeFollowModule") {
    const currency =
      profilesToFollow[0].followModule.feeFollowModule.amount.currency;

    const erc20 = new ethers.Contract(currency, ERC20_ABI, getSigner(provider));

    const decimals = followModule.amount.asset.decimals;

    const formattedAmount = ethers.utils.parseUnits(
      followModule.amount.value,
      decimals
    );

    const balance = await erc20.balanceOf(address);

    if (!ethers.BigNumber.from(balance).gte(formattedAmount)) {
      dispatcher(setFollowFailure("Not enough balance"));
      dispatcher(updatePendingFollows([]));
      toast.error("Not enough balance to follow this profile");
      return;
    }

    const allowance = await approveFollowModule(
      profilesToFollow[0].followModule.feeFollowModule.amount.currency,
      // profilesToFollow[0].followModule.feeFollowModule.amount.value,
      "10000",
      "FeeFollowModule",
      provider
    );
    if (!allowance) {
      return null;
    }
  }

  /* START OF PROXY SNIPPET */
  if (followModule === null) {
    const proxyFollow = await followWithProxy(
      profilesToFollow[0].profile,
      userProfiles
    );
    if (proxyFollow) return;
  }
  /* END OF PROXY SNIPPET */

  const userSigNonces = store.getState().user.userSigNonces;

  let result;
  try {
    result = await createFollowTypedData({
      options: { overrideSigNonce: userSigNonces },
      request: {
        follow: profilesToFollow,
      },
    });
  } catch (e) {
    console.error(e);
    if (followModule && followModule.type === "RevertFollowModule") {
      toast.error(
        `@${profilesToFollow[0].handle} doesn't allow anyone to follow them.`
      );
    } else if (followModule && followModule.type === "ProfileFollowModule") {
      toast.error("You need to own a Lens profile to follow this profile.");
    } else {
      toast.error(e.message);
    }
    dispatcher(setFollowFailure(e));
    dispatcher(updatePendingFollows([]));
    return;
  }

  const { expiresAt, id } = result.data.createFollowTypedData;

  const typedData = omitDeep(
    result.data.createFollowTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
    dispatcher(setUserSigNonces(userSigNonces + 1));
  } catch (e) {
    dispatcher(setFollowFailure(e));
    dispatcher(updatePendingFollows([]));
    console.error(e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(setFollowFailure("Broadcast signature expired"));
    dispatcher(updatePendingFollows([]));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  let relayerResult;
  try {
    relayerResult = await broadcast({ id, signature });
  } catch (e) {
    dispatcher(setFollowFailure(e));
    dispatcher(updatePendingFollows([]));
    toast.error("Broadcast error");
    return;
  }

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason) {
    toast.error(boradcastErrorFormatting(reason));
    return await followWithSigCall(
      signature,
      address,
      typedData,
      profilesToFollow,
      provider
    );
  }

  try {
    if (txHash) {
      const indexation = await pollUntilIndexed(txHash);
      if (indexation) {
        await profilesToFollow.map(
          async (p) => await refreshProfile(null, p.profile)
        );
        dispatcher(setFollowSuccess());
        dispatcher(updatePendingFollows([]));
        toast.success("Followed!");
      }
    } else {
      dispatcher(setFollowFailure("Error following user"));
      dispatcher(updatePendingFollows([]));
      console.error("Error following user");
      return;
    }
  } catch (e) {
    dispatcher(setFollowFailure(e));
    dispatcher(updatePendingFollows([]));
    console.error("Error following with signature: ", e);
    toast.error(e.message);
    return;
  }
};

const followWithSigCall = async (
  signature,
  address,
  typedData,
  profilesToFollow,
  provider
) => {
  const { v, r, s } = splitSignature(signature);
  try {
    const lensHub = new ethers.Contract(
      config.contracts.LENS_HUB_CONTRACT_ADDRESS,
      LENS_HUB_ABI,
      getProvider(provider).getSigner()
    );
    const tx = await lensHub.followWithSig({
      follower: address,
      profileIds: typedData.value.profileIds,
      datas: typedData.value.datas,
      sig: {
        v,
        r,
        s,
        deadline: typedData.value.deadline,
      },
    });
    if (tx.hash) {
      const indexation = await pollUntilIndexed(tx.hash);
      if (indexation) {
        await profilesToFollow.map(
          async (p) => await refreshProfile(null, p.profile)
        );
        dispatcher(setFollowSuccess());
        dispatcher(updatePendingFollows([]));
        toast.success("Followed!");
      }
    } else {
      dispatcher(setFollowFailure("Error following user"));
      dispatcher(updatePendingFollows([]));
      console.error("Error following user");
      return;
    }
  } catch (e) {
    dispatcher(setFollowFailure(e));
    dispatcher(updatePendingFollows([]));
    console.error("Error following with signature: ", e);
    toast.error(e.code === -32603 ? "Insufficient balance" : e.message);
    return;
  }
};
