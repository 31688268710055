import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getTokens } from "../utils/localStorageUtils";
import {
  GET_NFTS_FAILURE,
  GET_NFTS_SUCCESS,
  GET_PROFILES_FAILURE,
  GET_PROFILES_SUCCESS,
  GET_SIMILAR_FAILURE,
  GET_SIMILAR_SUCCESS,
  SET_CURRENCIES,
  SET_DEFAULT_PROFILE,
  SET_USER_SIG_NONCES,
  SET_WALLET,
  UPDATE_FOLLOWED,
  UPDATE_PENDING_FOLLOWS,
  USER_AUTH_FAILURE,
  USER_AUTH_SUCCESS,
} from "../_actions/user";

const initialState = {
  auth: getTokens() || "",
  wallet: null,
  profiles: null,
  nfts: null,
  defaultProfile: null,
  hasHandles: null,
  defaultProfileHasPic: null,
  similarProfiles: null,
  enabledCurrencies: null,
  pendingFollows: null,
  userSigNonces: null,
  followed: [],
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALLET:
      return {
        ...state,
        wallet: action.payload.wallet,
      };

    case SET_DEFAULT_PROFILE:
      return {
        ...state,
        defaultProfile: action.payload.defaultProfile,
      };

    case SET_CURRENCIES:
      return {
        ...state,
        enabledCurrencies: action.payload.enabledCurrencies,
      };

    case USER_AUTH_SUCCESS:
      return {
        ...state,
        auth: action.payload.authToken,
      };
    case USER_AUTH_FAILURE:
      return {
        ...state,
      };

    case GET_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload.profiles,
      };
    case GET_PROFILES_FAILURE:
      return {
        ...state,
      };

    case GET_NFTS_SUCCESS:
      return {
        ...state,
        nfts: action.payload.nfts,
      };
    case GET_NFTS_FAILURE:
      return {
        ...state,
      };

    case GET_SIMILAR_SUCCESS:
      return {
        ...state,
        similarProfiles: action.payload.similarProfiles,
      };
    case GET_SIMILAR_FAILURE:
      return {
        ...state,
      };

    case UPDATE_PENDING_FOLLOWS:
      return {
        ...state,
        pendingFollows: action.payload.profileIds,
      };

    case UPDATE_FOLLOWED:
      return {
        ...state,
        followed: [...state.followed, action.payload.profile],
      };

    case SET_USER_SIG_NONCES:
      return {
        ...state,
        userSigNonces: action.payload.nonce,
      };

    default:
      return state;
  }
};

export default user;

function selectReducerState(state) {
  return state.user;
}

const selectUser = createSelector(selectReducerState, (user) => user);

export const useUser = () => useSelector(selectUser);
