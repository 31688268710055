export const MUTUAL_FOLLOWERS = `
query MutualFollowers($request: MutualFollowersProfilesQueryRequest!) {
  mutualFollowersProfiles(request: $request) {
    items {
      id
          name
          bio
          handle
          picture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
          }
          ownedBy
    }
    pageInfo {
      next
      totalCount
    }
  }
}
`;
