import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import omitDeep from "omit-deep";
import { dispatcher } from "../store";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import {
  setUnfollowFailure,
  setUnfollowRequest,
  setUnfollowSuccess,
} from "../_actions/profiles";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { LENS_FOLLOW_NFT_ABI } from "../utils/lensFollowNftAbi";
import { createUnfollowTypedData } from "../apollo/createUnollowTypedData";
import { refreshProfile } from "./refreshProfile";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";

export const unfollow = async (profile, provider) => {
  dispatcher(setUnfollowRequest());

  const profileId = profile.id;

  let result;
  try {
    result = await createUnfollowTypedData({
      profile: profileId,
    });
  } catch (e) {
    dispatcher(setUnfollowFailure(e));
    console.error(e);
    toast.error(e.message);
    return;
  }

  const { expiresAt, id } = result.data.createUnfollowTypedData;

  const typedData = omitDeep(
    result.data.createUnfollowTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(setUnfollowFailure(e));
    console.error(e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(setUnfollowFailure("Broadcast signature expired"));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  try {
    if (reason || !relayerResult) {
      toast.error(boradcastErrorFormatting(reason));
      return await unfollowWithSig(signature, typedData, profile, provider);
    }
    if (txHash) {
      const indexation = await pollUntilIndexed(txHash);
      if (indexation) {
        await refreshProfile(null, profile.id);
        dispatcher(setUnfollowSuccess());
        toast.success("Profile unfollowed. Follow NFT burnt.");
      }
    } else {
      dispatcher(setUnfollowFailure("Error unfollowing user"));
      console.error("Error unfollowing user");
      return;
    }
  } catch (e) {
    dispatcher(setUnfollowFailure(e));
    console.error("Error unfollowing: ", e);
    toast.error(e.message);
    return;
  }
};

const unfollowWithSig = async (signature, typedData, profile, provider) => {
  const followNftContract = new ethers.Contract(
    typedData.domain.verifyingContract,
    LENS_FOLLOW_NFT_ABI,
    getProvider(provider).getSigner()
  );
  const { v, r, s } = splitSignature(signature);
  const sig = {
    v,
    r,
    s,
    deadline: typedData.value.deadline,
  };
  try {
    const tx = await followNftContract.burnWithSig(
      typedData.value.tokenId,
      sig
    );
    if (tx.hash) {
      const indexation = await pollUntilIndexed(tx.hash);
      if (indexation) {
        await refreshProfile(null, profile.id);
        dispatcher(setUnfollowSuccess());
        toast.success("Follow NFT burnt!");
      }
    } else {
      dispatcher(setUnfollowFailure("Error unfollowing user"));
      console.error("Error unfollowing user");
      return;
    }
  } catch (e) {
    dispatcher(setUnfollowFailure(e));
    console.error("Error unfollowing: ", e);
    toast.error(e.message);
    return;
  }
};
