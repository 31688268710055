import jwt_decode from "jwt-decode";

export function shortAddress(address) {
  return address.slice(0, 5) + "..." + address.slice(address.length - 4);
}

export function shortName(name, len) {
  return name.length > (len || 25)
    ? name.substring(0, len || 25) + "..."
    : name;
}

export const pattern = /^[a-z0-9.]*$/;

export function tokenExpired(token) {
  const { exp } = jwt_decode(token);
  return new Date().getTime() > exp * 1000;
}

export const SUPPORTED_CHAINS = {
  1: {
    pic: "https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png",
  },
  137: {
    pic: "https://avatars.githubusercontent.com/u/88427645?s=200&v=4",
  },
  80001: {
    pic: "https://research.binance.com/static/images/projects/matic-network/logo.png",
  },
};

export function getActionName(actionType) {
  if (typeof actionType !== "string") {
    return null;
  }
  return actionType.split("_").slice(0, -1).join("_");
}

export const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export function polygonScanUrl(address, chainId) {
  return `https://${
    chainId === 80001 ? "mumbai." : ""
  }polygonscan.com/address/${address}`;
}

export function openSeaUrl(address, tokenId, chainId) {
  return `https://${chainId === 80001 ? "testnets." : ""}opensea.io/assets/${
    chainId === 137 ? "matic" : "mumbai"
  }/${address}/${parseInt(tokenId)}`;
}

export function fetcher(...args) {
  return fetch(...args).then((res) => res.json());
}

export const getFollowModuleInfo = (followModule) => {
  if (followModule && followModule.type === "FeeFollowModule") {
    const amount = parseFloat(followModule.amount.value);
    const asset = followModule.amount.asset.symbol;
    return `Follow for ${amount} ${asset}`;
  } else if (followModule && followModule.type === "ProfileFollowModule") {
    return "Follow";
  } else if (followModule && followModule.type === "RevertFollowModule") {
    return "Profile blocks follows";
  } else {
    return "Follow";
  }
};

export function inputLengthValidation(minLength) {
  return `Should be ${minLength}+ characters long`;
}

export const SUPPORTED_COLLECTIONS = [
  {
    name: "Uniswap V3",
    address: "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
    icon: "https://etherscan.io/token/images/uniswapv3_32.png",
  },
  {
    name: "Crypto Coven",
    address: "0x5180db8F5c931aaE63c74266b211F580155ecac8",
    icon: "https://storage.googleapis.com/nftimagebucket/tokensinfo/6586d76e26bd53e50fa9f64cd600c8a2.png",
  },
  {
    name: "ENS",
    address: "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85",
    icon: "https://etherscan.io/token/images/ens2_32.png",
  },
  {
    name: "MANA",
    address: "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942",
    icon: "https://etherscan.io/token/images/decentraland_32.png?v=1",
  },
  {
    name: "CryptoKitties",
    address: "0x06012c8cf97BEaD5deAe237070F9587f8E7A266d",
    icon: "https://etherscan.io/token/images/cryptokitties_28.png?v=1",
  },
  {
    name: "Azuki",
    address: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
    icon: "https://storage.googleapis.com/nftimagebucket/tokensinfo/8757d575f54e98f4592c2a2bd357599c.png",
  },
  {
    name: "BAYC",
    address: "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D",
    icon: "https://etherscan.io/token/images/boredapeyc_32.png",
  },
  {
    name: "Moonbirds",
    address: "0x23581767a106ae21c074b2276D25e5C3e136a68b",
    icon: "https://storage.googleapis.com/nftimagebucket/tokensinfo/69509.png",
  },
];

export const SUPPORTED_MIMETYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "image/x-ms-bmp",
  "image/svg+xml",
  "image/webp",
];

export function getPictureToDisplay(picture) {
  if (picture) {
    if (picture && picture.__typename === "NftImage") {
      return picture.uri || null;
    } else {
      return picture.original
        ? picture.original.url.replace(
            "ipfs://",
            "https://lens.infura-ipfs.io/ipfs/"
          )
        : null;
    }
  } else {
    return null;
  }
}

export function nftTypeIsValid(mimeType) {
  return SUPPORTED_MIMETYPES.includes(mimeType);
}

export function tweetIntentUrl(handle) {
  return `https://twitter.com/intent/tweet?text=I minted my Profile NFT on @lensprotocol 🌿%0aFollow me on Lens &url=https://lensfrens.xyz/${handle}%0a%0a&hashtags=OwnYourDigitalRoots`;
}

export function boradcastErrorFormatting(reason) {
  switch (reason) {
    case "EXPIRED":
      return "The transaction broadcast expired. Please retry.";
    case "WRONG_WALLET_SIGNED":
      return "You are signing the message with the wrong wallet. Please connect the right wallet.";
    case "NOT_ALLOWED":
      return "Transaction broadcast not allowed.";
    default:
      return "The transaction broadcast failed. Please retry.";
  }
}

export const getFollowModule = (followModule, userProfileId) => {
  switch (followModule.type) {
    case "FeeFollowModule":
      return {
        feeFollowModule: {
          amount: {
            currency: followModule.amount.asset.address,
            value: followModule.amount.value,
          },
        },
      };
    case "FreeFollowModule":
      return {
        freeFollowModule: true,
      };
    case "ProfileFollowModule":
      return {
        profileFollowModule: {
          profileId: userProfileId,
        },
      };
    default:
      return {
        revertFollowModule: true,
      };
  }
};

export function profileMatchFormatter(number, label) {
  return `You have ${number} ${label}${number > 1 ? "s" : ""} in common`;
}

export function validURL(str) {
  if (str === null) return false;
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function URLformat(str) {
  if (str.indexOf("http://") == 0 || str.indexOf("https://") == 0) {
    return str;
  } else {
    return "https://" + str;
  }
}
