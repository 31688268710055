import React from "react";
import Spinner from "./Spinner";
import PropTypes from "prop-types";

function Button(props) {
  const classes = `rounded-full text-center gap-4 flex items-center justify-center 
  transition duration-300 ease-in-out
  ${
    props.disabled && props.primary
      ? "cursor-not-allowed bg-lime/30 hover:bg-lime/30"
      : props.disabled && !props.primary
      ? "cursor-not-allowed"
      : "cursor-pointer"
  }
  ${props.primary ? "bg-lime hover:bg-lime/50" : "bg-white hover:bg-basil/10"}
  ${props.pill ? "pl-3 pr-6 py-2" : "px-5 sm:px-6 py-4 sm:py-5"} 
  ${props.pill && props.active ? "outline outline-white" : ""} 
  ${props.propsclasses}`;
  return (
    <button className={classes} {...props}>
      {props.children || (
        <span className="text-basil font-subheader font-medium text-sm sm:text-base leading-tight sm:leading-3 uppercase flex items-center gap-2">
          {props.loading ? (
            <Spinner borderColor={props.primary ? "border-basil" : null} />
          ) : null}
          {props.label}
        </span>
      )}
    </button>
  );
}

export default Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  pill: PropTypes.bool,
  active: PropTypes.bool,
  propsclasses: PropTypes.string,
  children: PropTypes.element,
  loading: PropTypes.bool,
  label: PropTypes.string,
};
