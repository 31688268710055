import { ethers, utils } from "ethers";
import WalletConnectProvider from "@walletconnect/ethereum-provider";

export function ethersProvider(connector) {
  if (connector === "walletconnect") {
    return new ethers.providers.Web3Provider(new WalletConnectProvider());
  }

  if (
    connector === "coinbase" &&
    window.ethereum.providers &&
    window.ethereum.providers.length
  ) {
    window.ethereum.providers.forEach(async (p) => {
      if (p.isCoinbaseWallet) {
        return new ethers.providers.Web3Provider(p);
      }
    });
  }

  return new ethers.providers.Web3Provider(window.ethereum);
}

export function getProvider(ethereum) {
  if (
    window.localStorage.getItem("walletconnect") &&
    JSON.parse(window.localStorage.getItem("walletconnect")).connected
  ) {
    return new ethers.providers.Web3Provider(
      new WalletConnectProvider(ethereum)
    );
  } else {
    return new ethers.providers.Web3Provider(window.ethereum);
  }
}

export const getSigner = (provider) => {
  console.log("WIN", provider);
  return new ethers.providers.Web3Provider(window.ethereum);
};

export const signedTypeData = async (domain, types, value, ethereum) => {
  const currentProvider = getProvider(ethereum);
  const signer = currentProvider.getSigner();
  return await signer._signTypedData(domain, types, value);
};

export const splitSignature = (signature) => {
  return utils.splitSignature(signature);
};

export const sendTx = (transaction, ethereum) => {
  const currentProvider = getProvider(ethereum);
  const signer = currentProvider.getSigner();
  return signer.sendTransaction(transaction);
};
