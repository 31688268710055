import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const PROXY_ACTION = `
  mutation ProxyAction($request: ProxyActionRequest!) {
    proxyAction(request: $request)
  }
`;

export const proxyAction = (request) => {
  return apolloClient.mutate({
    mutation: gql(PROXY_ACTION),
    variables: {
      request,
    },
  });
};
