import { gql } from "@apollo/client";
import { apolloClient } from "./apolloClient";

export const CREATE_TOGGLE_FOLLOW_TYPED_DATA = `
mutation CreateToggleFollow($request: CreateToggleFollowRequest!) {
  createToggleFollowTypedData(request: $request) {
    id
    expiresAt
    typedData {
      types {
        ToggleFollowWithSig {
          name
          type
        }
      }
      domain {
        name
        chainId
        version
        verifyingContract
      }
      value {
        nonce
        deadline
        profileIds
        enables
      }
    }
  }
}
`;

export const createToggleFollowTypedData = (profileIds, enables) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_TOGGLE_FOLLOW_TYPED_DATA),
    variables: {
      request: {
        profileIds,
        enables,
      },
    },
  });
};
