import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const PROXY_STATUS = `
  query ProxyStatus($proxyActionId: ProxyActionId!) {
    proxyActionStatus(proxyActionId: $proxyActionId) {
      ... on ProxyActionError {
        reason
        lastKnownTxId
      }
      ... on ProxyActionStatusResult {
        txHash
        txId
        status
      }
      ... on ProxyActionQueued {
        queuedAt
      }
      __typename
    }
  }
`;

export const proxyStatus = async (proxyActionId) => {
  return await apolloClient.query({
    query: gql(PROXY_STATUS),
    variables: {
      proxyActionId,
    },
    fetchPolicy: "network-only",
  });
};

export const pollProxyUntilComplete = async (proxyActionId) => {
  const result = await proxyStatus(proxyActionId);
  const response = result.data.proxyActionStatus;
  if (response.__typename !== "ProxyActionError") {
    if (response.txId) {
      return true;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return await pollProxyUntilComplete(proxyActionId);
  }
  // Tx reverted
  throw new Error(response.reason);
};
