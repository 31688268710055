import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useWallet } from "use-wallet";
import Modal from "../Modules/Modal";
import PropTypes from "prop-types";

function ConnectWalletModal({ open, setOpen }) {
  const wallet = useWallet();

  const { connecting, error } = wallet.status;

  const activate = (connector) => {
    wallet.connect(connector);
  };

  useEffect(() => {
    if (wallet.account) {
      setOpen(false);
    }
  }, [wallet, setOpen]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="border border-basil inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div>
          <div className="mt-2 text-center sm:mt-2">
            <Dialog.Title
              as="h3"
              className="text-lg leading-6 font-medium text-center"
              style={{
                color: "#00501E",
                fontFamily: "Space Grotesk",
                fontWeight: 500,
                fontSize: "28px",
                lineHeight: "28px",
              }}
            >
              CONNECT WALLET
            </Dialog.Title>
            <div className="mt-2">
              <p
                className="text-sm "
                style={{
                  fontFamily: "Public Sans",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#00501E",
                }}
              >
                Click below:
              </p>
              <>
                {/* METAMASK */}
                <div className="flex flex-col md:flex-row gap-4 justify-around mt-6 mb-4 md:mb-0">
                  <div
                    onClick={() => activate("injected")}
                    className="cursor-pointer pb-8 w-48 h-48 rounded-lg flex justify-center items-center relative m-auto"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <svg
                      width="83"
                      height="83"
                      viewBox="0 0 83 83"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M60.9782 41.9302L41.8026 67.5428L22.6044 41.9302L41.8026 16.3401L60.9782 41.9302Z"
                        stroke="#00501E"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M60.9782 41.9304L41.8703 50.1518L22.6044 41.9304"
                        stroke="#00501E"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M41.78 81.9299C63.8714 81.9299 81.78 64.0213 81.78 41.9299C81.78 19.8385 63.8714 1.92993 41.78 1.92993C19.6887 1.92993 1.78003 19.8385 1.78003 41.9299C1.78003 64.0213 19.6887 81.9299 41.78 81.9299Z"
                        stroke="#00501E"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                      />
                    </svg>
                    <div
                      className="absolute bottom-4"
                      style={{
                        fontFamily: "Public Sans",
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#00501E",
                      }}
                    >
                      {connecting ? "Connecting..." : "Browser"}
                    </div>
                  </div>
                  {/* WALLETCONNECT */}
                  <div
                    onClick={() => activate("walletconnect")}
                    className="cursor-pointer pb-8 w-48 h-48 rounded-lg flex justify-center items-center relative m-auto"
                    style={{ backgroundColor: "#EFEFEF" }}
                  >
                    <svg
                      width={115}
                      height={115}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M76.538 88.708a2.755 2.755 0 0 1-1.94-.784l-16.87-16.52-16.873 16.513a2.8 2.8 0 0 1-3.875 0L12.812 64.253A2.66 2.66 0 0 1 12 62.332a2.66 2.66 0 0 1 .812-1.922l5.359-5.25a2.8 2.8 0 0 1 3.874 0L38.92 71.68l16.87-16.52a2.8 2.8 0 0 1 3.875 0l16.874 16.52 16.87-16.517a2.8 2.8 0 0 1 3.875 0l5.358 5.25a2.666 2.666 0 0 1 .812 1.922 2.653 2.653 0 0 1-.812 1.922l-24.168 23.66a2.755 2.755 0 0 1-1.935.791Zm-18.81-19.212c.352 0 .69.137.942.382l17.15 16.8a1.022 1.022 0 0 0 1.425 0l24.171-23.664a.943.943 0 0 0 .284-.672.95.95 0 0 0-.284-.672l-5.362-5.25a1.022 1.022 0 0 0-1.424 0l-17.15 16.8a1.35 1.35 0 0 1-1.887 0l-17.15-16.8a1.022 1.022 0 0 0-1.425 0l-17.15 16.8a1.35 1.35 0 0 1-1.887 0l-17.15-16.8a1.022 1.022 0 0 0-1.425 0l-5.372 5.247a.943.943 0 0 0 0 1.344l24.171 23.664a1.022 1.022 0 0 0 1.425 0l17.15-16.8c.255-.246.595-.381.948-.379ZM35.993 55.122a1.83 1.83 0 0 1-1.278-.515l-6.02-5.89a2.66 2.66 0 0 1-.812-1.922 2.66 2.66 0 0 1 .812-1.922l1.932-1.893c14.942-14.63 39.257-14.63 54.199 0l1.75 1.725a2.66 2.66 0 0 1 .812 1.922 2.66 2.66 0 0 1-.812 1.921l-6.02 5.898a1.837 1.837 0 0 1-2.552 0l-2.422-2.373c-9.852-9.646-25.88-9.646-35.732 0l-2.593 2.54c-.34.327-.793.508-1.264.508Zm21.735-21.376a36.874 36.874 0 0 0-25.876 10.48l-1.932 1.89a.934.934 0 0 0-.212 1.037c.05.116.122.22.212.307l6.02 5.897a.073.073 0 0 0 .102 0l2.594-2.537c10.528-10.308 27.65-10.308 38.182 0l2.422 2.37a.073.073 0 0 0 .101 0l6.024-5.895a.943.943 0 0 0 0-1.344l-1.75-1.722a36.887 36.887 0 0 0-25.886-10.483Z"
                        fill="#00501E"
                      />
                    </svg>
                    <div
                      className="absolute bottom-4"
                      style={{
                        fontFamily: "Public Sans",
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: "#00501E",
                      }}
                    >
                      {connecting ? "Connecting..." : "WalletConnect"}
                    </div>
                  </div>
                </div>
                {error ? (
                  <p className="test-basil text-center my-2">
                    Authentication error, invalid signature
                  </p>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConnectWalletModal;

ConnectWalletModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
