import { useEffect, useState } from "react";
import { ethers } from "ethers";

const useENS = (address) => {
  if (address && address.length) {
    address = ethers.utils.getAddress(address);
  }
  const [ensName, setENSName] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const resolveENS = async () => {
      setLoading(true);
      if (ethers.utils.isAddress(address)) {
        try {
          const provider = new ethers.providers.AlchemyProvider(
            "homestead",
            process.env.REACT_APP_ALCHEMY_KEY
          );
          const ensName = await provider.lookupAddress(address);
          setENSName(ensName);
        } catch (e) {
          console.error("Error with ENS lookup: ", e);
        } finally {
          setLoading(false);
        }
      }
    };
    resolveENS();
  }, [address]);

  return { ensName, loading };
};

export default useENS;
