import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const HAS_TX_BEEN_INDEXED = `
  query HasTxHashBeenIndexed($request: HasTxHashBeenIndexedRequest!) {
    hasTxHashBeenIndexed(request: $request) { 
        ... on TransactionIndexedResult {
          indexed
        }
        ... on TransactionError {
          reason
        }
        __typename
        }
  }
`;

export const hasTxBeenIndexed = async (txHash) => {
  return await apolloClient.query({
    query: gql(HAS_TX_BEEN_INDEXED),
    variables: {
      request: {
        txHash,
      },
    },
    fetchPolicy: "network-only",
  });
};

export const pollUntilIndexed = async (txHash) => {
  const result = await hasTxBeenIndexed(txHash);
  const response = result.data.hasTxHashBeenIndexed;
  if (response.__typename === "TransactionIndexedResult") {
    if (response.indexed) {
      return true;
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return await pollUntilIndexed(txHash);
  }
  // Tx reverted
  throw new Error(response.reason);
};
