import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const MODULE_APPROVAL_DATA = `
  query GenerateModuleCurrencyApprovalData($request: GenerateModuleCurrencyApprovalDataRequest!) {
    generateModuleCurrencyApprovalData(request: $request) {
      to
      from
      data
    }
  }
`

export const getModuleApprovalData = (moduleApprovalRequest) => {
   return apolloClient.query({
    query: gql(MODULE_APPROVAL_DATA),
    variables: {
      request: moduleApprovalRequest
    },
  })
}