import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../Modules/Button";
import Input from "../Modules/Input";
import { useWallet } from "use-wallet";
import { useRouter } from "../../hooks/useRouter";
import { setUpdateProfileMetadata } from "../../_functions/setUpdateProfileMetadata";
import toast from "react-hot-toast";
import EmptyRectangle from "../../assets/png/EmptyRectangle.png";
import { setUpdateDefaultProfile } from "../../_functions/setUpdateDefaultProfile";
import { useAppStatus } from "../../_reducers/appStatus";
import { useUser } from "../../_reducers/user";
import { validURL } from "../../utils/utils";
import axios from "axios";

const fieldLimits = {
  name: 1,
  location: 1,
  bio: 4,
  twitter: 1,
  website: 5,
};

function EditProfile({
  profile,
  loadingMetadataUpdate,
  loadingFollowModuleUpdate,
}) {
  const { handle, id, name, bio, coverPicture, attributes } = profile;

  const wallet = useWallet();

  const router = useRouter();

  const defaultFields = {
    name,
    bio,
    coverPicture: coverPicture ? coverPicture.original.url : null,
    location: attributes.find((a) => a.key === "location")
      ? attributes.find((a) => a.key === "location").value
      : null,
    twitter: attributes.find((a) => a.key === "twitter")
      ? attributes.find((a) => a.key === "twitter").value
      : null,
    website: attributes.find((a) => a.key === "website")
      ? attributes.find((a) => a.key === "website").value
      : null,
  };

  const [fields, setFields] = useState(defaultFields);

  console.log(fields);

  const fieldsChangeHandler = (field, value, limit) => {
    if (value.length <= limit) {
      setFields({ ...fields, [field]: value });
    }
  };

  const invalidLinks =
    fields.website && fields.website.lenght === 0 && !validURL(fields.website);

  const invalidFormCondition = Object.keys(fields).some(
    (key) =>
      fields[key] &&
      fields[key].length > 0 &&
      fields[key].length < fieldLimits[key]
  );

  const [coverPictureToUpdate, setCoverPictureToUpdate] = useState(
    coverPicture && coverPicture.original ? coverPicture.original.url : null
  );
  const [loadingUploadCover, setLoadingUploadCover] = useState(false);

  const updateCoverPicture = async (e) => {
    e.preventDefault();
    setLoadingUploadCover(true);
    const localFile = e.target.files ? e.target.files[0] : null;
    if (localFile) {
      try {
        const url = "https://lens-utils-api.vercel.app/api/uploadFilesToIPFS";
        // const url = "http://localhost:8080/api/uploadFilesToIPFS";

        const formData = new FormData();
        formData.append("file", localFile);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const toUpload = await axios.post(url, formData, config);

        if (!toUpload || !toUpload.data || !toUpload.data.Hash) {
          setLoadingUploadCover(false);
          toast.error("Error pinning cover picture");
          return;
        }

        const { Hash } = toUpload.data;

        setCoverPictureToUpdate(`ipfs://${Hash}`);
        setLoadingUploadCover(false);
      } catch (e) {
        setLoadingUploadCover(false);
        toast.error("Error uploading local file as cover picture");
      }
    }
  };

  useEffect(() => {
    if (coverPictureToUpdate) {
      setFields({ ...fields, coverPicture: coverPictureToUpdate });
    }
  }, [coverPictureToUpdate]);

  return (
    <>
      <InfosToEdit
        fields={fields}
        fieldsChangeHandler={fieldsChangeHandler}
        disabled={loadingMetadataUpdate || loadingFollowModuleUpdate}
        profile={profile}
      />
      <CoverPictureEditor
        disabled={loadingUploadCover || loadingMetadataUpdate}
        loadingUploadCover={loadingUploadCover}
        updateCoverPicture={updateCoverPicture}
        coverPictureToUpdate={coverPictureToUpdate}
        coverPicture={coverPicture}
      />
      <LinksToEdit
        fields={fields}
        fieldsChangeHandler={fieldsChangeHandler}
        disabled={loadingMetadataUpdate || loadingFollowModuleUpdate}
      />
      <div className="mt-8 flex flex-col md:flex-row items-center gap-4">
        <Button
          label="Cancel"
          propsclasses="w-full md:w-1/2"
          onClick={() => router.push("/" + handle)}
          disabled={loadingMetadataUpdate || loadingFollowModuleUpdate}
        />
        <Button
          loading={loadingMetadataUpdate}
          label="Save"
          primary
          propsclasses="w-full md:w-1/2"
          onClick={() =>
            setUpdateProfileMetadata(
              id,
              fields,
              wallet.account,
              wallet.ethereum
            )
          }
          disabled={
            loadingMetadataUpdate ||
            loadingFollowModuleUpdate ||
            invalidFormCondition ||
            invalidLinks
          }
        />
      </div>
    </>
  );
}

export default EditProfile;

function InfosToEdit({ fields, fieldsChangeHandler, disabled, profile }) {
  return (
    <>
      <span className="flex items-center justify-start w-full gap-1 mt-4">
        <p className="font-medium text-lg font-header">Info</p>
        <p className="font-light text-sm font-header">(optional)</p>
      </span>
      <EditDefaultProfile profile={profile} />
      <Input
        label="Name"
        limit={fields.name ? fields.name.length + "/30" : "0/30"}
        value={fields.name || ""}
        handler={(e) => fieldsChangeHandler("name", e.target.value, 30)}
        disabled={disabled}
        invalid={fields.name && fields.name.length <= fieldLimits.name}
        minLength={fieldLimits.name}
      />
      <Input
        label="Location"
        limit={fields.location ? fields.location.length + "/30" : "0/30"}
        value={fields.location || ""}
        handler={(e) => fieldsChangeHandler("location", e.target.value, 30)}
        disabled={disabled}
        invalid={
          fields.location && fields.location.length <= fieldLimits.location
        }
        minLength={fieldLimits.location}
      />
      <Input
        label="Bio"
        limit={fields.bio ? fields.bio.length + "/200" : "0/200"}
        value={fields.bio || ""}
        handler={(e) => fieldsChangeHandler("bio", e.target.value, 200)}
        textarea
        disabled={disabled}
        invalid={fields.bio && fields.bio.length <= fieldLimits.bio}
        minLength={fieldLimits.bio}
      />
    </>
  );
}

function LinksToEdit({ fields, fieldsChangeHandler, disabled }) {
  return (
    <>
      <span className="flex items-center justify-start w-full gap-1">
        <p className="font-medium text-lg font-header">Links</p>
        <p className="font-light text-sm font-header">(optional)</p>
      </span>{" "}
      <Input
        label="Twitter"
        limit={fields.twitter ? fields.twitter.length + "/30" : "0/30"}
        value={fields.twitter || ""}
        handler={(e) =>
          e.target.value !== "@"
            ? fieldsChangeHandler("twitter", e.target.value, 30)
            : null
        }
        placeholder="handle without the @"
        disabled={disabled}
        invalid={fields.twitter && fields.twitter.length < fieldLimits.twitter}
        minLength={fieldLimits.twitter}
      >
        <span className="absolute top-9 right-4 text-xl">🐦</span>
      </Input>
      <Input
        label="Website"
        limit={fields.website ? fields.website.length + "/200" : "0/200"}
        value={fields.website || ""}
        handler={(e) => fieldsChangeHandler("website", e.target.value, 200)}
        disabled={disabled}
        invalid={fields.website && fields.website.length <= fieldLimits.website}
        minLength={fieldLimits.website}
      >
        <span className="absolute top-9 right-4 text-xl">🌐</span>
      </Input>
    </>
  );
}

function CoverPictureEditor({
  disabled,
  loadingUploadCover,
  updateCoverPicture,
  coverPictureToUpdate,
  coverPicture,
}) {
  return (
    <>
      <span className="flex items-center justify-start w-full gap-1">
        <p className="font-medium text-lg font-header">Cover Picture</p>
        <p className="font-light text-sm font-header">(optional)</p>
      </span>
      <label
        hmtlfor="profilePicInput"
        className={`${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } border-2 border-blueGrey/20 rounded-md p-3 text-black placeholder:text-black/60 w-full h-28`}
      >
        <div
          className={`flex items-center justify-center h-full rounded-md bg-center bg-cover ${
            loadingUploadCover ? "animate-pulse" : ""
          }`}
          style={{
            backgroundImage: `url("${
              coverPictureToUpdate && coverPictureToUpdate.length
                ? coverPictureToUpdate.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )
                : coverPicture && coverPicture.original
                ? coverPicture.original.url.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )
                : EmptyRectangle
            }")`,
          }}
        >
          <p className="font-header text-black py-1 px-2 rounded-lg text-xs bg-white/80">
            Update your cover picture
          </p>
        </div>
        <input
          disabled={disabled}
          id="profilePicInput"
          className="hidden"
          type="file"
          onChange={updateCoverPicture}
        />
      </label>
    </>
  );
}

function EditDefaultProfile({ profile }) {
  const user = useUser();
  const wallet = useWallet();
  const appStatus = useAppStatus();

  const isDefaultProfile =
    user.defaultProfile && user.defaultProfile.handle === profile.handle;

  const loadingUpdateDefaultProfile =
    appStatus &&
    appStatus["UPDATE_DPROFILE"] &&
    appStatus["UPDATE_DPROFILE"].pending;
  return (
    <div className="flex flex-col w-full relative font-header">
      <label className="flex justify-between items-center mb-1">
        <p className="font-header">Default profile</p>
      </label>
      <span className="flex items-center justify-start gap-3">
        <div
          className={`relative rounded-full w-12 h-6 transition duration-200 ease-linear ${
            isDefaultProfile ? "bg-basil" : "bg-blueGrey"
          }`}
        >
          <label
            htmlFor="toggle"
            className={`absolute left-0 bg-white border-2 mb-2 w-6 h-6 rounded-full transition transform duration-100 ease-linear cursor-pointer ${
              isDefaultProfile
                ? "translate-x-full border-basil"
                : "translate-x-0 border-blueGrey"
            }`}
          />
          <input
            disabled={loadingUpdateDefaultProfile}
            type="checkbox"
            id="toggle"
            name="toggle"
            className="appearance-none w-full h-full active:outline-none focus:outline-none"
            checked={isDefaultProfile}
            onChange={() =>
              setUpdateDefaultProfile(profile.id, profile, wallet.ethereum)
            }
          />
        </div>
        <p className="text-xs">Use as default profile for this wallet</p>
      </span>
    </div>
  );
}

EditProfile.propTypes = {
  profile: PropTypes.object,
  loadingMetadataUpdate: PropTypes.bool,
  loadingFollowModuleUpdate: PropTypes.bool,
};

InfosToEdit.propTypes = {
  fields: PropTypes.object,
  fieldsChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  profile: PropTypes.object,
};

LinksToEdit.propTypes = {
  fields: PropTypes.object,
  fieldsChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

CoverPictureEditor.propTypes = {
  disabled: PropTypes.bool,
  loadingUploadCover: PropTypes.bool,
  updateCoverPicture: PropTypes.func,
  coverPicture: PropTypes.object,
  coverPictureToUpdate: PropTypes.string,
};

EditDefaultProfile.propTypes = {
  profile: PropTypes.object,
};
