import { apolloClient } from "../apolloClient";
import { gql } from "@apollo/client";

const CREATE_SET_PROFILE_IMAGE_URI_VIA_DISPATCHER = `
  mutation CreateSetProfileImageURIViaDispatcher(
    $request: UpdateProfileImageRequest!
  ) {
    createSetProfileImageURIViaDispatcher(request: $request) {
      ... on RelayerResult {
        txId
        txHash
      }
      ... on RelayError {
        reason
      }
    }
  }
`;

export const createSetProfileImageUriViaDispatcher = (request) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_SET_PROFILE_IMAGE_URI_VIA_DISPATCHER),
    variables: {
      request,
    },
  });
};
