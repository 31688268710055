import React, { useEffect, useState } from "react";
import {
  getFollowModule,
  getFollowModuleInfo,
  getPictureToDisplay,
  openSeaUrl,
  shortAddress,
  tokenExpired,
  tweetIntentUrl,
  URLformat,
  validURL,
} from "../../utils/utils";
import LazyImage from "../Modules/LazyImage";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import EmptyRectangle from "../../assets/png/EmptyRectangle.png";
import Button from "../Modules/Button";
import LinkButton from "../Modules/LinkButton";
import { useUser } from "../../_reducers/user";
import DetailsTooltip from "../Modules/DetailsTooltip";
import FollowButton from "./FollowButton";
import { useWallet } from "use-wallet";
import EditModeCardContent from "./EditModeCardContent";
import { ReactComponent as CameraIcon } from "../../assets/svg/CameraIcon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/TwitterIcon.svg";
import { ReactComponent as LensterLogo } from "../../assets/svg/LensterLogo.svg";
import NFTPickerModal from "../Modals/NFTPickerModal";
import Spinner from "../Modules/Spinner";
import { useAppStatus } from "../../_reducers/appStatus";
import { follow } from "../../_functions/follow";
import { useRouter } from "../../hooks/useRouter";
import ProfilesListModal from "../Modals/ProfilesListModal";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { unfollow } from "../../_functions/unfollow";
import toast from "react-hot-toast";
import useENS from "../../hooks/useENS";
import config from "../../config";
import { gql, useQuery } from "@apollo/client";
import { ARRAY_OF_FOLLOWS } from "../../apollo/apolloClient";
import { MUTUAL_FOLLOWERS } from "../../apollo/mutualFollowers";

function ExtendedCard({ profile, isEdit }) {
  const {
    picture,
    handle,
    id,
    ownedBy,
    coverPicture,
    name,
    followModule,
    bio,
    attributes,
    stats,
    isFollowedByMe,
  } = profile;

  const { data: arrayOfFollowsData } = useQuery(ARRAY_OF_FOLLOWS);

  const followedByMe =
    isFollowedByMe ||
    (arrayOfFollowsData && arrayOfFollowsData.arrayOfFollows
      ? arrayOfFollowsData.arrayOfFollows.includes(id)
      : false);

  const { totalFollowers, totalFollowing } = stats;

  const { ensName, loading: loadingEns } = useENS(ownedBy ? ownedBy : "");

  const twitter = attributes.find((a) => a.key === "twitter")
    ? attributes.find((a) => a.key === "twitter").value
    : null;

  const website = attributes.find((a) => a.key === "website")
    ? attributes.find((a) => a.key === "website").value
    : null;

  const wallet = useWallet();

  const router = useRouter();

  const user = useUser();
  const isOwnerConnected =
    user.auth &&
    !tokenExpired(user.auth.accessToken) &&
    user.profiles &&
    user.profiles.map((p) => p.id).includes(profile ? profile.id : "");

  const isRevertFollowModule =
    followModule && followModule.type === "RevertFollowModule";

  const isProfileFollowModule =
    followModule && followModule.type === "ProfileFollowModule";

  const handleFollowButtonClick = async () => {
    if (
      isProfileFollowModule &&
      wallet &&
      wallet.account &&
      user &&
      user.profiles.length === 0
    ) {
      toast.error("This profile accepts follows only from Lens profiles");
    }
    await follow(
      followModule
        ? [
            {
              profile: id,
              followModule: getFollowModule(
                followModule,
                user && user.defaultProfile
                  ? user.defaultProfile.id
                  : user && user.profiles
                  ? user.profiles[0].id
                  : null
              ),
            },
          ]
        : [
            {
              profile: id,
            },
          ],
      wallet.account,
      followModule,
      wallet.ethereum,
      user.profiles
    );
  };

  const { data: mutualFollowersData } = useQuery(gql(MUTUAL_FOLLOWERS), {
    variables: {
      request: {
        viewingProfileId: id,
        yourProfileId: user.defaultProfile
          ? user.defaultProfile.id
          : user.profiles
          ? user.profiles[0]
          : "",
        limit: 6,
      },
    },
    skip: !user || !user.defaultProfile || !user.profiles || !id,
  });

  const handleUnfollowButtonClick = async () => {
    await unfollow(profile, wallet.ethereum);
  };

  const [editMode, setEditMode] = useState(isEdit);

  const [openPfpEditor, setOpenPfpEditor] = useState(false);

  const appStatus = useAppStatus();
  const loadingImageUpdate =
    appStatus["UPDATE_PFP"] && appStatus["UPDATE_PFP"].pending;
  const loadingMetadataUpdate =
    appStatus &&
    appStatus["UPDATE_METADATA"] &&
    appStatus["UPDATE_METADATA"].pending;
  const loadingFollow =
    appStatus && appStatus["SET_FOLLOW"] && appStatus["SET_FOLLOW"].pending;
  const loadingUnfollow =
    appStatus && appStatus["SET_UNFOLLOW"] && appStatus["SET_UNFOLLOW"].pending;

  useEffect(() => {
    if (isEdit && profile && isOwnerConnected) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [isEdit, profile, isOwnerConnected]);

  const [openProfiles, setOpenProfiles] = useState(false);

  useEffect(() => {
    setOpenProfiles(false);
  }, []);

  const addFollowersCountCondition =
    user &&
    user.defaultProfile &&
    user.pendingFollows &&
    user.pendingFollows.length > 0 &&
    user.pendingFollows.includes(id);

  return (
    <div className="relative">
      <NFTPickerModal
        open={openPfpEditor}
        setOpen={setOpenPfpEditor}
        inlineEditor={id}
      />
      <ProfilesListModal
        open={openProfiles}
        setOpen={setOpenProfiles}
        profile={profile}
      />
      <ProfileHead profile={profile} />
      <div className="backdrop-blur-md bg-white/70 sm:bg-white rounded-xl shadow-xl w-[85vw] md:w-[600px] mt-0 sm:mx-0 flex flex-col items-center">
        <div
          style={{
            backgroundImage: `url("${
              coverPicture && coverPicture.original
                ? coverPicture.original.url.replace(
                    "ipfs://",
                    "https://lens.infura-ipfs.io/ipfs/"
                  )
                : EmptyRectangle
            }")`,
          }}
          className="rounded-t-xl h-28 sm:h-48 flex justify-center bg-no-repeat bg-cover bg-center w-full"
        />
        <LazyImage
          src={getPictureToDisplay(picture) || FallbackAvatar}
          className="object-cover border-4 border-cream rounded-full w-36 h-36 sm:w-52 sm:h-52 absolute top-10 sm:top-20 z-10"
          alt="profile_picture"
        />
        <div className="hidden sm:block bg-white rounded-t-xl w-full h-4 absolute top-24 sm:top-44" />
        {editMode ? (
          <PictureEditors
            setOpenPfpEditor={setOpenPfpEditor}
            loadingImageUpdate={loadingImageUpdate}
            loadingMetadataUpdate={loadingMetadataUpdate}
          />
        ) : null}
        <div className="bg-lightBg border-4 border-cream rounded-full w-36 h-36 md:w-52 md:h-52 absolute top-10 sm:top-20" />
        {!editMode ? (
          <a
            href={openSeaUrl(
              config.contracts.LENS_HUB_CONTRACT_ADDRESS.toLowerCase(),
              id,
              config.chain.CHAIN_ID
            )}
            target="_blank"
            rel="noreferrer"
            className="rounded py-1 px-2 text-black font-header bg-blueGrey/10 hover:bg-blueGrey/20 absolute top-32 left-6 sm:top-48 sm:left-6 text-xs"
          >
            #{parseInt(id, 16)}
          </a>
        ) : null}
        {!editMode ? (
          <DetailsTooltip
            className="absolute z-20 top-32 right-6 sm:top-48 sm:right-6"
            ownedBy={ownedBy}
            id={id}
          />
        ) : null}
        {editMode && profile ? (
          <EditModeCardContent profile={profile} />
        ) : (
          <div className="mt-20 sm:mt-28 flex flex-col items-center text-center w-full">
            <p className="font-header text-2xl sm:text-4xl font-bold">
              {name || handle}
            </p>
            <p className="font-header text-md sm:text-2xl">@{handle}</p>
            <a
              href={openSeaUrl(
                config.contracts.LENS_HUB_CONTRACT_ADDRESS.toLowerCase(),
                id,
                config.chain.CHAIN_ID
              )}
              target="_blank"
              rel="noreferrer"
              className={`mt-3 py-1 px-3 rounded-lg bg-blueGrey/10 hover:bg-blueGrey/20 ${
                loadingEns ? "animate-pulse" : ""
              } min-w-[35px]`}
            >
              {loadingEns ? (
                <p className="font-header text-sm">...</p>
              ) : ensName ? (
                <p className="font-header text-sm">{ensName}</p>
              ) : (
                <p className="font-header text-sm">{shortAddress(ownedBy)}</p>
              )}
            </a>
            {mutualFollowersData &&
            mutualFollowersData.mutualFollowersProfiles ? (
              <MutualFollowers
                mutualFollowers={mutualFollowersData.mutualFollowersProfiles}
              />
            ) : null}
            {bio && bio.length ? (
              <p
                className="font-header text-md sm:text-lg mt-4 px-8 sm:px-12 text-center break-words"
                style={{ wordBreak: "break-word" }}
              >
                {bio}
              </p>
            ) : null}
            <button
              className="flex items-center gap-4 mx-auto mt-2 hover:bg-blueGrey/10 px-3 py-1 rounded-lg"
              onClick={() => setOpenProfiles(true)}
            >
              <span className="font-header text-black flex gap-1">
                <p className="font-header text-black font-semibold">
                  {addFollowersCountCondition
                    ? totalFollowers + 1
                    : totalFollowers}
                </p>{" "}
                followers
              </span>
              <span className="font-header text-black flex gap-1">
                <p className="font-header text-black font-semibold">
                  {totalFollowing}
                </p>{" "}
                following
              </span>
            </button>
            <div className="flex flex-col items-center gap-4 mt-4 mb-8 w-5/6 sm:w-7/12">
              {isOwnerConnected ? (
                <>
                  <LinkButton
                    propsclasses="text-basil w-full"
                    href={tweetIntentUrl(handle)}
                    target="_blank"
                    primary
                  >
                    <span className="flex items-center gap-2">
                      <TwitterIcon className="h-3" />
                      <p className="text-basil font-subheader font-medium leading-3 uppercase flex items-center">
                        Share to twitter
                      </p>
                    </span>
                  </LinkButton>
                  <Button
                    onClick={() => router.push("/" + handle + "/edit")}
                    label="Edit Profile"
                    primary
                    propsclasses="w-full"
                  />
                </>
              ) : (
                <FollowButton
                  isFollowedByMe={followedByMe}
                  handleFollowButtonLabel={() =>
                    getFollowModuleInfo(followModule)
                  }
                  handleFollowButtonClick={handleFollowButtonClick}
                  isRevertFollowModule={isRevertFollowModule}
                  loadingFollow={
                    loadingFollow && user.pendingFollows.includes(id)
                  }
                  loadingUnfollow={loadingUnfollow}
                  handleUnfollowButtonClick={handleUnfollowButtonClick}
                />
              )}
              <LinkButton
                href={config.lensterProfileUrl + handle}
                target="_blank"
                label={
                  <span className="flex gap-2 items-center">
                    <LensterLogo className="h-4 w-4" />
                    <p>Lenster</p>
                  </span>
                }
                propsclasses="w-full"
              />
              {twitter ? (
                <LinkButton
                  href={`https://twitter.com/${twitter}`}
                  target="_blank"
                  label="🐦 Twitter"
                  propsclasses="w-full"
                />
              ) : null}
              {website ? (
                <LinkButton
                  href={validURL(website) ? URLformat(website) : ""}
                  target="_blank"
                  label="🌐 Website"
                  propsclasses="w-full"
                  rel="noreferrer"
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExtendedCard;

function PictureEditors({
  setOpenPfpEditor,
  loadingImageUpdate,
  loadingMetadataUpdate,
}) {
  const disabled = loadingImageUpdate || loadingMetadataUpdate;
  return (
    <button
      disabled={disabled}
      onClick={() => setOpenPfpEditor(true)}
      className={`${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } absolute top-[140px] right-[115px] sm:top-[240px] sm:right-[215px] z-40 rounded-full flex items-center justify-center p-1 h-8 w-8 bg-black`}
    >
      {loadingImageUpdate ? (
        <Spinner borderColor="border-white" />
      ) : (
        <CameraIcon className="h-4 w-4" />
      )}
    </button>
  );
}

function ProfileHead({ profile }) {
  const title = `${profile.name ? profile.name + " - " : ""}
  @${profile.handle} | LensFrens`;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}

function MutualFollowers({ mutualFollowers }) {
  const moreCount = mutualFollowers.pageInfo.totalCount - 6 > 0 ? mutualFollowers.pageInfo.totalCount - 6 : 0;
  return (
    <div className="flex flex-col items-center">
      <p className="text-black/70 text-xs mt-4">
        Followers you have in common:
      </p>
      <div className="flex -space-x-1.5 mt-2">
        {mutualFollowers.items.map((profile) => (
          <LazyImage
            key={profile.id}
            title={profile.handle}
            className="rounded-full w-7 h-7"
            src={getPictureToDisplay(profile.picture)}
            alt={profile.handle}
          />
        ))}
        {moreCount ? (
          <div className="flex items-center justify-center bg-white border rounded-full dark:bg-gray-900 w-7 h-7 dark:border-gray-700/80">
            <span className="text-[10px]">+ {moreCount}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

ExtendedCard.propTypes = {
  profile: PropTypes.object,
  isEdit: PropTypes.bool,
  followedByMe: PropTypes.bool,
};

PictureEditors.propTypes = {
  setOpenPfpEditor: PropTypes.func,
  loadingImageUpdate: PropTypes.bool,
  loadingMetadataUpdate: PropTypes.bool,
};

ProfileHead.propTypes = {
  profile: PropTypes.object,
};

MutualFollowers.propTypes = {
  mutualFollowers: PropTypes.object,
};
