import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const GET_USERS_NFTS = `
query Nfts($request: NFTsRequest!) {
    nfts(request: $request) {
      items {
        contractName
        contractAddress
        symbol
        tokenId
        owners {
          amount
          address
        }
        name
        description
        contentURI
        originalContent {
          uri
          metaType
        }
        chainId
        collectionName
        ercType
      }
      pageInfo {
        prev
        next
        totalCount
      }
    }
  }
  `;

export const getUsersNfts = async (ownerAddress, chainIds, limit, cursor) => {
  return await apolloClient.query({
    query: gql(GET_USERS_NFTS),
    variables: {
      request: {
        ownerAddress,
        chainIds,
        limit,
        cursor,
      },
    },
    fetchPolicy: "network-only",
  });
};
