export const CLAIMABLE_HANDLES = `
  query ClaimableHandles{
    claimableHandles {
      canClaimFreeTextHandle
      reservedHandles {
        id
        expiry
        handle
        source
      }
    }
  }
`;
