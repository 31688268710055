import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const GET_FOLLOWERS = `
query Followers($request: FollowersRequest!) {
  followers(request: $request) {
    items {
      wallet {
        address
        defaultProfile {
          id
          name
          bio
          handle
          picture {
            ... on NftImage {
              contractAddress
              tokenId
              uri
              verified
            }
            ... on MediaSet {
              original {
                url
                mimeType
              }
            }
          }
          ownedBy
        }
      }
    }
    pageInfo {
      prev
      next
      totalCount
    }
  }
}
`;

export const followers = (profileId, cursor) => {
  return apolloClient.query({
    query: gql(GET_FOLLOWERS),
    variables: {
      request: {
        profileId,
        limit: 12,
        cursor: cursor || null,
      },
    },
  });
};
