import React from "react";
import { useRouter } from "../../hooks/useRouter";
import Button from "./Button";

function NotFound() {
  const router = useRouter();
  return (
    <div className="flex flex-col items-center mt-24">
      <p className="text-6xl font-header font-semibold text-basil mx-auto text-center uppercase">
        Oops...
      </p>
      <p className="text-3xl font-header font-semibold text-basil mx-auto text-center">
        {"This page doesn't seem to exist"}
      </p>
      <Button
        onClick={() => router.push("/")}
        primary
        label="go back to the homepage"
        propsclasses="my-10"
      />
    </div>
  );
}

export default NotFound;
