import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const GET_FOLLOWING = `
query Following($request: FollowingRequest!) {
  following(request: $request) {
    items {
      profile {
        id
        name
        bio
        handle
        picture {
          ... on NftImage {
            contractAddress
            tokenId
            uri
            verified
          }
          ... on MediaSet {
            original {
              url
              mimeType
            }
          }
        }
        ownedBy
      }
    }
    pageInfo {
      prev
      next
      totalCount
    }
  }
}
`;

export const following = (walletAddress, cursor) => {
  return apolloClient.query({
    query: gql(GET_FOLLOWING),
    variables: {
      request: {
        address: walletAddress,
        limit: 12,
        cursor,
      },
    },
  });
};
