import { getUsersNfts } from "../apollo/getUsersNfts";
import config from "../config";
import { dispatcher } from "../store";
import {
  getNftsFailure,
  getNftsRequest,
  getNftsSuccess,
} from "../_actions/user";

export function getUserNfts(userAddress) {
  dispatcher(getNftsRequest());
  getUsersNfts(
    userAddress,
    config.chain.CHAIN_ID === 137
      ? [config.chain.CHAIN_ID, 1]
      : [config.chain.CHAIN_ID]
  )
    .then((result) => {
      const nfts = result.data.nfts.items;
      dispatcher(getNftsSuccess(nfts));
    })
    .catch((error) => {
      dispatcher(getNftsFailure(error));
    });
}
