import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LensExLogoWhite } from "../../assets/svg/LensExLogoWhite.svg";
import HeaderMenu from "./HeaderMenu";

function Header() {
  return (
    <div className="pt-4 pb-1 w-11/12 mx-auto flex justify-between items-start z-10">
      <Link aria-label="home" to="/">
        <LensExLogoWhite />
      </Link>
      <HeaderMenu />
    </div>
  );
}

export default Header;
