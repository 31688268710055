import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_SET_DEFAULT_PROFILE_TYPED_DATA = `
  mutation CreateSetDefaultProfile($request: CreateSetDefaultProfileRequest!) { 
    createSetDefaultProfileTypedData(request: $request) {
      id
      expiresAt
      typedData {
        types {
          SetDefaultProfileWithSig {
            name
            type
          }
        }
      domain {
        name
        chainId
        version
        verifyingContract
      }
      value {
        nonce
        deadline
        wallet
        profileId
      }
    }
  }
}
`;

export const createSetDefaultProfileTypedData = (profileId) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_SET_DEFAULT_PROFILE_TYPED_DATA),
    variables: {
      request: {
        profileId,
      },
    },
  });
};
