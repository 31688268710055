import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_SET_PROFILE_METADATA_TYPED_DATA = `
mutation CreateSetProfileMetadataTypedData(
  $request: CreatePublicSetProfileMetadataURIRequest!
) {
  createSetProfileMetadataTypedData(request: $request) {
    id
    expiresAt
    typedData {
      types {
        SetProfileMetadataURIWithSig {
          name
          type
        }
      }
      domain {
        name
        chainId
        version
        verifyingContract
      }
      value {
        nonce
        deadline
        profileId
        metadata
      }
    }
  }
}
`;

export const createSetProfileMetadataTypedData = (request) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_SET_PROFILE_METADATA_TYPED_DATA),
    variables: {
      request,
    },
  });
};
