import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const GET_DEFAULT_PROFILE = `
query DefaultProfile($request: DefaultProfileRequest!) {
  defaultProfile(request: $request) {
    id
    name
    bio
    attributes {
      displayType
      traitType
      key
      value
    }
    metadata
    picture {
      ... on NftImage {
        contractAddress
        tokenId
        uri
        verified
      }
      ... on MediaSet {
        original {
          url
          mimeType
        }
      }
      __typename
    }
    handle
    ownedBy
    coverPicture {
      ... on NftImage {
        contractAddress
        tokenId
        uri
        verified
      }
      ... on MediaSet {
        original {
          url
          mimeType
        }
      }
      __typename
    }
    dispatcher {
      address
      canUseRelay
    }
    stats {
      totalFollowers
      totalFollowing
      totalPosts
      totalComments
      totalMirrors
      totalPublications
      totalCollects
    }
    followModule {
      ... on FeeFollowModuleSettings {
        type
        amount {
          asset {
            symbol
            name
            decimals
            address
          }
          value
        }
        recipient
      }
      ... on ProfileFollowModuleSettings {
       type
      }
      ... on RevertFollowModuleSettings {
       type
      }
    }
    dispatcher {
      canUseRelay
    }
    isFollowedByMe
  }
}
`;

export const getDefaultProfileRequest = (ethereumAddress) => {
  return apolloClient.query({
    query: gql(GET_DEFAULT_PROFILE),
    variables: {
      request: {
        ethereumAddress,
      },
    },
  });
};
