import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Button from "../Modules/Button";
import { ReactComponent as XCircleIcon } from "../../assets/svg/XCircleIcon.svg";
import PropTypes from "prop-types";
import { useAppStatus } from "../../_reducers/appStatus";

function BatchApproveBanner({
  show,
  profilesToApprove,
  emptyProfilesToApprove,
  submitApprovals,
}) {
  const appStatus = useAppStatus();

  const loadingApproval =
    appStatus &&
    appStatus["TOGGLE_FOLLOW"] &&
    appStatus["TOGGLE_FOLLOW"].pending;

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-800"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-out duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed top-4 bg-black py-4 px-8 mx-2 sm:mx-0 sm:min-w-[550px] z-30 rounded-xl shadow-lg">
        <div className="flex flex-col sm:flex-row items-center gap-3 sm:gap-4 justify-between relative">
          <button
            className="hidden sm:block text-white/60 font-subheader text-sm mt-2 underline absolute -right-7 -top-5"
            onClick={emptyProfilesToApprove}
            disabled={loadingApproval}
          >
            <XCircleIcon className="opacity-60 w-2/3" />
          </button>
          <div className="flex flex-col items-start justify-center">
            <p className="text-white font-subheader text-sm">
              Approving{" "}
              {
                Object.keys(profilesToApprove).filter(
                  (key) => profilesToApprove[key] === "true"
                ).length
              }
              , rejecting{" "}
              {
                Object.keys(profilesToApprove).filter(
                  (key) => profilesToApprove[key] === "false"
                ).length
              }
            </p>
            <p className="text-white/60 font-subheader text-sm">
              You can batch up to 20 actions in a single tx
            </p>
          </div>
          <div className="flex flex-col items-center w-full sm:w-max">
            <Button
              primary
              label="Run batch"
              propsclasses="w-full sm:w-max"
              onClick={submitApprovals}
              loading={loadingApproval}
              disabled={loadingApproval}
            />
          </div>
          <button
            className="sm:hidden text-white/60 font-subheader text-sm underline"
            onClick={emptyProfilesToApprove}
          >
            Cancel
          </button>
        </div>
      </div>
    </Transition>
  );
}

export default BatchApproveBanner;

BatchApproveBanner.propTypes = {
  show: PropTypes.bool,
  profilesToApprove: PropTypes.object,
  emptyProfilesToApprove: PropTypes.func,
  submitApprovals: PropTypes.func,
};
