import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { tokenExpired } from "../../utils/utils";
import { useAppStatus } from "../../_reducers/appStatus";
import { useUser } from "../../_reducers/user";
import { ReactComponent as ArrowLeftIcon } from "../../assets/svg/ArrowLeftIcon.svg";
import Spinner from "../Modules/Spinner";
import { useRouter } from "../../hooks/useRouter";
import { ReactComponent as CheckIcon } from "../../assets/svg/CheckIcon.svg";
import PropTypes from "prop-types";
import EditProfile from "./EditProfile";
import EditSettings from "./EditSettings";
import EditApprovals from "./EditApprovals";
import { gql, useQuery } from "@apollo/client";
import { GET_PENDING_APPROVALS } from "../../apollo/pendingApprovalFollows";
import { useWallet } from "use-wallet";
import { setProfileImageUriNormal } from "../../_functions/setUpdateProfileImage";

function EditModeCardContent({ profile }) {
  const { handle } = profile;

  const [tab, setTab] = useState("profile");

  const user = useUser();
  const isOwnerConnected =
    user.auth &&
    !tokenExpired(user.auth.accessToken) &&
    user.profiles &&
    user.profiles.map((p) => p.id).includes(profile ? profile.id : "");

  const appStatus = useAppStatus();
  const loadingMetadataUpdate =
    appStatus &&
    appStatus["UPDATE_METADATA"] &&
    appStatus["UPDATE_METADATA"].pending;
  const loadingFollowModuleUpdate =
    appStatus &&
    appStatus["UPDATE_FMODULE"] &&
    appStatus["UPDATE_FMODULE"].pending;

  const router = useRouter();

  useEffect(() => {
    if (!isOwnerConnected) {
      router.push("/" + handle);
    }
  }, [isOwnerConnected]);

  return (
    <div className="mt-20 sm:mt-28 flex flex-col items-center pb-12 w-full relative">
      <NavLink
        className="absolute sm:-top-24 sm:left-16 -top-16 left-4"
        to={"/" + handle}
        disabled={loadingMetadataUpdate || loadingFollowModuleUpdate}
      >
        <ArrowLeftIcon />
      </NavLink>
      <div className="flex items-center justify-center w-11/12 sm:w-4/5 mb-4">
        <Link
          to={"/" + handle}
          className="font-header text-md sm:text-2xl"
          disabled={loadingMetadataUpdate || loadingFollowModuleUpdate}
        >
          @{handle}
        </Link>
      </div>
      {isOwnerConnected && profile.picture ? (
        <PfpReset profileId={profile.id} />
      ) : null}
      {isOwnerConnected ? (
        <DefaultProfileUpdater
          isDefaultProfile={
            user.defaultProfile && user.defaultProfile.handle === handle
          }
        />
      ) : null}
      <Tabs tab={tab} setTab={setTab} />
      <div className="w-11/12 sm:w-4/5 flex flex-col items-center gap-3 mt-1">
        {tab === "profile" ? (
          <EditProfile
            profile={profile}
            loadingMetadataUpdate={loadingMetadataUpdate}
            loadingFollowModuleUpdate={loadingFollowModuleUpdate}
          />
        ) : tab === "settings" ? (
          <EditSettings
            profile={profile}
            loadingFollowModuleUpdate={loadingFollowModuleUpdate}
          />
        ) : (
          <EditApprovals />
        )}
      </div>
    </div>
  );
}

export default EditModeCardContent;

function PfpReset({ profileId }) {
  const appStatus = useAppStatus();
  const loadingImageUpdate =
    appStatus["UPDATE_PFP"] && appStatus["UPDATE_PFP"].pending;

  const wallet = useWallet();

  return (
    <button
      disabled={loadingImageUpdate}
      className="mb-3"
      onClick={async () =>
        await setProfileImageUriNormal(
          null,
          profileId,
          wallet.account,
          wallet.ethereum,
          true
        )
      }
    >
      <p className="text-sm underline text-basil">
        {loadingImageUpdate
          ? "Loading profile picture update..."
          : "Reset your profile picture"}
      </p>
    </button>
  );
}

function DefaultProfileUpdater({ isDefaultProfile }) {
  const appStatus = useAppStatus();
  const loadingUpdateDefaultProfile =
    appStatus &&
    appStatus["UPDATE_DPROFILE"] &&
    appStatus["UPDATE_DPROFILE"].pending;
  return isDefaultProfile ? (
    <div className="mt-1 mb-3 flex px-4 py-1 text-sm bg-peas text-basil font-header rounded-lg items-center gap-2">
      <CheckIcon />
      <>Default profile</>
    </div>
  ) : (
    <div className="mt-1 mb-3 flex px-4 py-1 text-sm bg-peas hover:bg-peas/60 text-basil font-header font-medium rounded-lg items-center gap-2 border border-basil">
      {loadingUpdateDefaultProfile ? (
        <Spinner borderColor="border-basil" />
      ) : null}
      <>Not default profile</>
    </div>
  );
}

function Tabs({ tab, setTab }) {
  const { data, fetchMore } = useQuery(gql(GET_PENDING_APPROVALS), {
    variables: {
      request: {},
    },
    fetchPolicy: "network-only",
  });

  const appStatus = useAppStatus();

  const approvalSuccess =
    appStatus["TOGGLE_FOLLOW"] &&
    !appStatus["TOGGLE_FOLLOW"].pending &&
    !appStatus["TOGGLE_FOLLOW"].error;

  useEffect(() => {
    if (approvalSuccess) {
      fetchMore({
        variables: {
          request: {},
        },
      });
    }
  }, [approvalSuccess]);

  return (
    <div className="flex justify-between gap-6 my-4 w-11/12 sm:w-4/5">
      <button
        className={`flex flex-col items-center w-max pb-1 focus:outline-none ${
          tab === "profile"
            ? "text-basil border-b-2 border-basil font-semibold"
            : "font-light"
        }`}
        onClick={() => (tab !== "profile" ? setTab("profile") : null)}
      >
        <p className="font-header leading-tight uppercase">profile</p>
      </button>
      <button
        className={`flex flex-col items-center w-max pb-1 focus:outline-none ${
          tab === "settings"
            ? "text-basil border-b-2 border-basil font-semibold"
            : "font-light"
        }`}
        onClick={() => (tab !== "settings" ? setTab("settings") : null)}
      >
        <p className="font-header leading-tight uppercase">settings</p>
      </button>
      <button
        className={`flex gap-2 items-center w-max pb-1 focus:outline-none ${
          tab === "approvals"
            ? "text-basil border-b-2 border-basil font-semibold"
            : "font-light"
        }`}
        onClick={() => (tab !== "approvals" ? setTab("approvals") : null)}
      >
        <p className="font-header leading-tight uppercase">approve following</p>
        <span className="flex items-center justify-center text-xs text-basil bg-peas rounded-full w-5 h-5 font-medium">
          {data ? data.pendingApprovalFollows.pageInfo.totalCount : "--"}
        </span>
      </button>
    </div>
  );
}

EditModeCardContent.propTypes = {
  profile: PropTypes.object,
};

PfpReset.propTypes = {
  profileId: PropTypes.string,
};

DefaultProfileUpdater.propTypes = {
  isDefaultProfile: PropTypes.bool,
};

Tabs.propTypes = { tab: PropTypes.string, setTab: PropTypes.func };
