import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import omitDeep from "omit-deep";
import { generateNftChallenge } from "../apollo/nftOwnership";
import { ethers } from "ethers";
import {
  getProfilesFailure,
  getProfilesRequest,
  getProfilesSuccess,
  updatePfpFailure,
  updatePfpRequest,
  updatePfpSuccess,
} from "../_actions/user";
import { dispatcher, store } from "../store";
import { createSetProfileImageUriTypedData } from "../apollo/createSetProfileImageUriTypedData";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { getProfiles } from "../apollo/getProfiles";
import toast from "react-hot-toast";
import { refreshDefaultProfile } from "./refreshDefaultProfile";
import { boradcastErrorFormatting } from "../utils/utils";
import { broadcast } from "../apollo/broadcast";
import config from "../config";
import { LENS_HUB_ABI } from "../utils/lensHubAbi";
import { createSetProfileImageUriViaDispatcher } from "../apollo/viaDispatcher/createSetProfileImageUriViaDispatcher";
import axios from "axios";

const signNftChallenge = (selectedNft, userAddress) => {
  const { contractAddress, tokenId } = selectedNft;
  return generateNftChallenge(ethers.utils.getAddress(userAddress), [
    { contractAddress, tokenId, chainId: config.chain.CHAIN_ID },
  ]);
};

const setProfileImageUriFromTypedDate = async (
  result,
  userAddress,
  provider
) => {
  const { expiresAt, id } = result.data.createSetProfileImageURITypedData;

  const typedData = omitDeep(
    result.data.createSetProfileImageURITypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(updatePfpFailure(e));
    console.error(e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(updatePfpFailure("Error updating image"));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason || !relayerResult || !txHash) {
    toast.error(boradcastErrorFormatting(reason));
    return await setUpdatePfpWithSig(
      signature,
      typedData,
      userAddress,
      provider
    );
  }
  if (txHash) {
    const indexation = await pollUntilIndexed(txHash);
    if (indexation) {
      setTimeout(async () => {
        await refreshDefaultProfile(userAddress);
      }, 1000);
      dispatcher(updatePfpSuccess());
      toast.success("Profile picture updated!");
      dispatcher(getProfilesRequest());
      return getProfiles({ ownedBy: userAddress })
        .then((result) => {
          const profiles = result.data.profiles.items;
          dispatcher(getProfilesSuccess(profiles));
        })
        .catch((error) => {
          dispatcher(getProfilesFailure(error));
          toast.error(error.message);
        });
    }
  } else {
    dispatcher(updatePfpFailure("Error updating image"));
    console.error("Error updating image");
    toast.error("Error updating image!");
    return;
  }
};

export const setProfileImageUriNormal = async (
  localFile,
  profileId,
  userAddress,
  provider,
  reset
) => {
  dispatcher(updatePfpRequest());

  let media, uploadResponse;

  if (!reset) {
    try {
      const url = "https://lens-utils-api.vercel.app/api/uploadFilesToIPFS";
      // const url = "http://localhost:8080/api/uploadFilesToIPFS";

      const formData = new FormData();
      formData.append("file", localFile);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      uploadResponse = await axios.post(url, formData, config);
    } catch (e) {
      dispatcher(updatePfpFailure("Error pinning image"));
      console.error("Error pinning image", e);
      return;
    }

    if (!uploadResponse || !uploadResponse.data || !uploadResponse.data.Hash) {
      dispatcher(updatePfpFailure("Error pinning image"));
      console.error("Error pinning image");
      return;
    }

    if (uploadResponse) {
      const { Hash } = uploadResponse.data;
      media = {
        mimeType: localFile.type,
        url: `ipfs://${Hash}`,
      };
    } else {
      dispatcher(updatePfpFailure("Error uploading image"));
      console.error("Error uploading image");
      return;
    }
  }

  /* VIA DISPATCHER SNIPPET **/

  const canUseRelay =
    store.getState().user &&
    store.getState().user.defaultProfile &&
    store.getState().user.defaultProfile.dispatcher
      ? store.getState().user.defaultProfile.dispatcher.canUseRelay
      : false;

  if (canUseRelay) {
    const viaDispatcher = await createSetProfileImageUriViaDispatcher({
      profileId: profileId,
      url: reset ? "" : media.url,
    });

    const { txHash, reason } =
      viaDispatcher.data.createSetProfileImageURIViaDispatcher;

    if (reason || !viaDispatcher) {
      toast.error(boradcastErrorFormatting(reason));
    }
    if (txHash) {
      const indexation = await pollUntilIndexed(txHash);
      if (indexation) {
        setTimeout(async () => {
          await refreshDefaultProfile(userAddress);
        }, 1000);
        dispatcher(updatePfpSuccess());
        toast.success("Profile picture updated!");
        dispatcher(getProfilesRequest());
        return getProfiles({ ownedBy: userAddress })
          .then((result) => {
            const profiles = result.data.profiles.items;
            dispatcher(getProfilesSuccess(profiles));
          })
          .catch((error) => {
            dispatcher(getProfilesFailure(error));
            toast.error(error.message);
          });
      }
    } else {
      dispatcher(updatePfpFailure("Error updating image"));
      console.error("Error updating image");
      toast.error("Error updating image!");
      return;
    }
  }

  /* END OF VIA DISPATCHER SNIPPET **/

  const result = await createSetProfileImageUriTypedData({
    profileId: profileId,
    url: reset ? "" : media.url,
  });
  if (result && result.data) {
    await setProfileImageUriFromTypedDate(result, userAddress, provider);
  } else {
    dispatcher(updatePfpFailure("Error uploading image"));
    console.error("Error uploading image");
    return;
  }
  dispatcher(updatePfpFailure("Error uploading image"));
};

export const setProfileImageUriWithNft = async (
  selectedNft,
  profileId,
  userAddress,
  provider
) => {
  dispatcher(updatePfpRequest());
  const signer = getProvider(provider).getSigner();
  const response = await signNftChallenge(selectedNft, userAddress);
  const { text, id } = response.data.nftOwnershipChallenge;
  const challengeSignedResult = await signer
    .signMessage(text)
    .then(async (result) => ({ id, signature: result }))
    .catch((error) => {
      dispatcher(updatePfpFailure(error));
      toast.error(error.message);
      throw new Error("NFT ownership Challenge sign error: " + error);
    });
  const setProfileImageUriWithNftRequest = {
    profileId,
    nftData: challengeSignedResult,
  };

  /* VIA DISPATCHER SNIPPET **/

  const canUseRelay =
    store.getState().user &&
    store.getState().user.defaultProfile &&
    store.getState().user.defaultProfile.dispatcher
      ? store.getState().user.defaultProfile.dispatcher.canUseRelay
      : false;

  if (canUseRelay) {
    const viaDispatcher = await createSetProfileImageUriViaDispatcher(
      setProfileImageUriWithNftRequest
    );

    const { txHash, reason } =
      viaDispatcher.data.createSetProfileImageURIViaDispatcher;

    if (reason || !viaDispatcher) {
      toast.error(boradcastErrorFormatting(reason));
    }
    if (txHash) {
      const indexation = await pollUntilIndexed(txHash);
      if (indexation) {
        setTimeout(async () => {
          await refreshDefaultProfile(userAddress);
        }, 1000);
        dispatcher(updatePfpSuccess());
        toast.success("Profile picture updated!");
        dispatcher(getProfilesRequest());
        return getProfiles({ ownedBy: userAddress })
          .then((result) => {
            const profiles = result.data.profiles.items;
            dispatcher(getProfilesSuccess(profiles));
          })
          .catch((error) => {
            dispatcher(getProfilesFailure(error));
            toast.error(error.message);
          });
      }
    } else {
      dispatcher(updatePfpFailure("Error updating image"));
      console.error("Error updating image");
      toast.error("Error updating image!");
      return;
    }
  }

  /* END OF VIA DISPATCHER SNIPPET **/

  const result = await createSetProfileImageUriTypedData(
    setProfileImageUriWithNftRequest
  );
  if (result && result.data) {
    await setProfileImageUriFromTypedDate(result, userAddress, provider);
  } else {
    dispatcher(updatePfpFailure("Error uploading NFT image"));
    console.error("Error uploading NFT image");
    return;
  }
};

const setUpdatePfpWithSig = async (
  signature,
  typedData,
  userAddress,
  provider
) => {
  const { v, r, s } = splitSignature(signature);
  const lensHub = new ethers.Contract(
    config.contracts.LENS_HUB_CONTRACT_ADDRESS,
    LENS_HUB_ABI,
    getProvider(provider).getSigner()
  );
  const tx = await lensHub.setProfileImageURIWithSig({
    profileId: typedData.value.profileId,
    imageURI: typedData.value.imageURI,
    sig: {
      v,
      r,
      s,
      deadline: typedData.value.deadline,
    },
  });
  if (tx.hash) {
    const indexation = await pollUntilIndexed(tx.hash);
    if (indexation) {
      setTimeout(async () => {
        await refreshDefaultProfile(userAddress);
      }, 1000);
      dispatcher(updatePfpSuccess());
      toast.success("Profile picture updated!");
      dispatcher(getProfilesRequest());
      return getProfiles({ ownedBy: userAddress })
        .then((result) => {
          const profiles = result.data.profiles.items;
          dispatcher(getProfilesSuccess(profiles));
        })
        .catch((error) => {
          dispatcher(getProfilesFailure(error));
          toast.error(error.message);
        });
    }
  } else {
    dispatcher(updatePfpFailure("Error updating image"));
    console.error("Error updating image");
    return;
  }
};
