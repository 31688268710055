import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const SEARCH_USERS_QUERY = `
query SearchUsers($request: SearchQueryRequest!) {
  search(request: $request) {
    ... on ProfileSearchResult {
      items {
        id
        handle
        name
        ownedBy
        picture {
          ... on MediaSet {
            original {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const searchProfiles = (keyword) => {
  return apolloClient.query({
    query: gql(SEARCH_USERS_QUERY),
    variables: { request: { type: "PROFILE", query: keyword } },
    fetchPolicy: "network-only",
  });
};
