import { ethers } from "ethers";
import { authenticateJwt } from "../apollo/authenticateJwt";
import { generateChallenge } from "../apollo/generateChallenge";
import { dispatcher } from "../store";
import { saveTokens } from "../utils/localStorageUtils";
import {
  userAuthFailure,
  userAuthRequest,
  userAuthSuccess,
} from "../_actions/user";
import toast from "react-hot-toast";
import { shortAddress } from "../utils/utils";
import { getUserInfo } from "./getUserInfo";

const signChallenge = (resultSignature, resultAddress) => {
  return authenticateJwt(resultAddress, resultSignature)
    .then((result) => {
      const { authenticate } = result.data;
      saveTokens(authenticate);
      dispatcher(userAuthSuccess(authenticate));
      toast.success("Sign-in success with: " + shortAddress(resultAddress));
      getUserInfo(resultAddress);
    })
    .catch((error) => {
      dispatcher(userAuthFailure(error));
      console.error("Signature error", error);
      toast.error(error.message);
    });
};

export const authenticate = (userAddress, provider) => {
  dispatcher(userAuthRequest());
  const address = ethers.utils.getAddress(userAddress);
  const signer = provider.getSigner();
  generateChallenge(address)
    .then((result) => {
      let challenge = result.data.challenge.text;
      signer
        .signMessage(challenge)
        .then(async (result) => {
          signChallenge(result, address);
        })
        .catch((error) => {
          dispatcher(userAuthFailure(error));
          console.error("Signature error", error);
          toast.error(error.message);
        });
    })
    .catch((error) => {
      dispatcher(userAuthFailure(error));
      console.error("Generate challenge error", error);
      toast.error(error.message);
    });
};
