import React, { useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { useRouter } from "../../hooks/useRouter";
import Button from "./Button";
import PropTypes from "prop-types";

function ErrorBoundaryWrapper({ children }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const resetAppState = useCallback(
    () => dispatch(() => ({ type: "RESET_APP" })),
    [dispatch]
  );
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        resetAppState();
        router.push("/");
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundaryWrapper;

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="p-6 bg-white rounded-md shadow-xl m-auto flex flex-col items-start"
    >
      <p className="font-header text-xl text-basil">Something went wrong:</p>
      <code className="mt-4">{error.message}</code>
      <Button
        primary
        label="try again"
        propsclasses="mt-4"
        onClick={resetErrorBoundary}
      />
    </div>
  );
}

ErrorBoundaryWrapper.propTypes = {
  children: PropTypes.array,
};

ErrorFallback.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
};
