import omitDeep from "omit-deep";
import toast from "react-hot-toast";
import { createSetProfileMetadataTypedData } from "../apollo/createSetProfileMetadataTypedData";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { dispatcher, store } from "../store";
import {
  getProvider,
  signedTypeData,
  splitSignature,
} from "../utils/ethersService";
import {
  updateMetadataFailure,
  updateMetadataRequest,
  updateMetadataSuccess,
} from "../_actions/user";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import { refreshProfile } from "./refreshProfile";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import config from "../config";
import { LENS_PERIPHERY_ABI } from "../utils/lensPeripheryAbi";
import { createSetProfileMetadataViaDispatcher } from "../apollo/viaDispatcher/createSetProfileMetadataViaDispatcher";
import axios from "axios";

export const setUpdateProfileMetadataFromTypedData = async (
  result,
  profileId,
  userAddress,
  metadata,
  provider
) => {
  const { expiresAt, id } = result.data.createSetProfileMetadataTypedData;

  const typedData = omitDeep(
    result.data.createSetProfileMetadataTypedData.typedData,
    "__typename"
  );

  let signature;
  try {
    signature = await signedTypeData(
      typedData.domain,
      typedData.types,
      typedData.value,
      provider
    );
  } catch (e) {
    dispatcher(updateMetadataFailure(e));
    console.error("Error with profile metadata update signature", e);
    toast.error(e.message);
    return;
  }

  if (new Date(expiresAt) <= new Date()) {
    dispatcher(updateMetadataFailure("Error updating follow module"));
    toast.error(boradcastErrorFormatting("EXPIRED"));
    return;
  }

  const relayerResult = await broadcast({ id, signature });

  const { txHash, reason } = relayerResult.data.broadcast;

  if (reason || !relayerResult || !txHash) {
    toast.error(boradcastErrorFormatting(reason));
    return await setUpdateMetadataWithSig(
      signature,
      profileId,
      metadata,
      userAddress,
      typedData,
      provider
    );
  }
  if (txHash) {
    const indexation = await pollUntilIndexed(txHash);
    if (indexation) {
      setTimeout(async () => {
        await refreshProfile(userAddress, profileId);
      }, 1000);
      dispatcher(updateMetadataSuccess());
      toast.success("Metadata updated!");
    }
  } else {
    dispatcher(updateMetadataFailure("Error updating follow module"));
    console.error("Error with profile metadata update");
    toast.error("Error updating metadata!");
    return;
  }
};

export async function setUpdateProfileMetadata(
  profileId,
  fields,
  userAddress,
  provider
) {
  const address = ethers.utils.getAddress(userAddress);

  const { name, location, bio, coverPicture, website, twitter } = fields;

  dispatcher(updateMetadataRequest());

  try {
    const uploadIPFSResult = await axios.post(
      "https://lens-utils-api.vercel.app/api/uploadToIPFS",
      // "http://localhost:8080/api/uploadToIPFS",
      {
        name: name && name.length > 0 ? name : "",
        bio,
        cover_picture: coverPicture ? coverPicture : null,
        attributes: [
          {
            traitType: "string",
            key: "location",
            value: location,
          },
          {
            traitType: "string",
            key: "website",
            value: website,
          },
          {
            traitType: "string",
            key: "twitter",
            value: twitter,
          },
          {
            traitType: "string",
            key: "app",
            value: "LensFrens",
          },
        ],
        version: "1.0.0",
        metadata_id: uuidv4(),
        createdOn: new Date(),
        appId: "LensFrens",
      }
    );

    console.log(uploadIPFSResult.data);

    const { path } = uploadIPFSResult.data;

    if (!uploadIPFSResult || !uploadIPFSResult.data || !path || path === null) {
      toast.error("Something went wrong with IPFS pinning");
      return;
    }

    /* VIA DISPATCHER SNIPPET **/

    const canUseRelay =
      store.getState().user &&
      store.getState().user.defaultProfile &&
      store.getState().user.defaultProfile.dispatcher
        ? store.getState().user.defaultProfile.dispatcher.canUseRelay
        : false;

    if (canUseRelay) {
      const viaDispatcher = await createSetProfileMetadataViaDispatcher({
        profileId,
        metadata: `ipfs://${path}`,
      });

      const { txHash, reason } =
        viaDispatcher.data.createSetProfileMetadataViaDispatcher;

      if (reason) {
        toast.error(boradcastErrorFormatting(reason));
      }
      if (txHash) {
        const indexation = await pollUntilIndexed(txHash);
        if (indexation) {
          setTimeout(async () => {
            await refreshProfile(userAddress, profileId);
          }, 1000);
          dispatcher(updateMetadataSuccess());
          toast.success("Metadata updated!");
          return;
        }
      } else {
        dispatcher(updateMetadataFailure("Error updating follow module"));
        console.error("Error with profile metadata update");
        toast.error("Error updating metadata!");
      }
    }

    /* END OF VIA DISPATCHER SNIPPET **/

    const result = await createSetProfileMetadataTypedData({
      profileId,
      metadata: `ipfs://${path}`,
    });
    await setUpdateProfileMetadataFromTypedData(
      result,
      profileId,
      address,
      `ipfs://${path}`,
      provider
    );
  } catch (e) {
    dispatcher(updateMetadataFailure("Error updating profile metadata"));
    console.error("Error updating profile metadata", e);
    toast.error(e.message);
    return;
  }
}

const setUpdateMetadataWithSig = async (
  signature,
  profileId,
  metadata,
  userAddress,
  typedData,
  provider
) => {
  const { v, r, s } = splitSignature(signature);
  const lensPeriphery = new ethers.Contract(
    config.contracts.LENS_PERIPHERY_CONTRACT_ADDRESS,
    LENS_PERIPHERY_ABI,
    getProvider(provider).getSigner()
  );
  const tx = await lensPeriphery.setProfileMetadataURIWithSig({
    profileId,
    metadata,
    user: userAddress,
    sig: {
      v,
      r,
      s,
      deadline: typedData.value.deadline,
    },
  });
  if (tx.hash) {
    const indexation = await pollUntilIndexed(tx.hash);
    if (indexation) {
      setTimeout(async () => {
        await refreshProfile(userAddress, profileId);
      }, 1000);
      dispatcher(updateMetadataSuccess());
      toast.success("Metadata updated!");
    }
  } else {
    dispatcher(updateMetadataFailure("Error updating profile metadata"));
    console.error("Error updating profile metadata");
    return;
  }
};
