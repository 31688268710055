import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const ALLOWANCE = `
  query ApprovedModuleAllowanceAmount($request: ApprovedModuleAllowanceAmountRequest!) {
    approvedModuleAllowanceAmount(request: $request) {
      currency
      module
      contractAddress
      allowance
    }
  }
`;

export const allowance = (allowanceRequest) => {
  return apolloClient.query({
    query: gql(ALLOWANCE),
    variables: {
      request: allowanceRequest,
    },
    fetchPolicy: "network-only",
  });
};
