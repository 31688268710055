import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

const CREATE_UNFOLLOW_TYPED_DATA = `
  mutation Unfollow($request: UnfollowRequest!) { 
    createUnfollowTypedData(request: $request) {
      id
      expiresAt
      typedData {
        domain {
          name
          chainId
          version
          verifyingContract
        }
        types {
          BurnWithSig {
            name
            type
          }
        }
        value {
          nonce
          deadline
          tokenId
        }
      }
    }
 }
`;

export const createUnfollowTypedData = (unfollowRequestInfo) => {
  return apolloClient.mutate({
    mutation: gql(CREATE_UNFOLLOW_TYPED_DATA),
    variables: {
      request: unfollowRequestInfo,
    },
  });
};
