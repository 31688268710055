import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GET_PENDING_APPROVALS } from "../../apollo/pendingApprovalFollows";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import LazyImage from "../Modules/LazyImage";
import FallbackAvatar from "../../assets/png/FallbackAvatar.png";
import BatchApproveBanner from "./BatchApproveBanner";
import toast from "react-hot-toast";
import { shortName } from "../../utils/utils";
import Spinner from "../Modules/Spinner";
import { toggleFollow } from "../../_functions/toggleFollow";
import { useWallet } from "use-wallet";
import { useAppStatus } from "../../_reducers/appStatus";

function EditApprovals() {
  return (
    <>
      <Approvals />
    </>
  );
}

export default EditApprovals;

function Approvals() {
  const { data, loading, fetchMore } = useQuery(gql(GET_PENDING_APPROVALS), {
    variables: {
      request: {
        limit: 5,
      },
    },
    fetchPolicy: "network-only",
  });

  const [approvals, setApprovals] = useState([]);
  const [approvalsPageInfo, setApprovalsPageInfo] = useState();
  const [loadingMoreApprovals, setLoadingMoreApprovals] = useState(false);

  useEffect(() => {
    if (data) {
      const approvals =
        data.pendingApprovalFollows.items &&
        data.pendingApprovalFollows.items.length
          ? data.pendingApprovalFollows.items
          : [];
      setApprovals(approvals);
      setApprovalsPageInfo(data.pendingApprovalFollows.pageInfo);
    }
  }, [data]);

  const displayLoadMore =
    data &&
    !loading &&
    approvalsPageInfo &&
    approvals &&
    approvalsPageInfo.totalCount > approvals.length;

  const loadMore = async () => {
    setLoadingMoreApprovals(true);
    const results = await fetchMore({
      variables: {
        request: {
          limit: 5,
          cursor: approvalsPageInfo.next,
        },
      },
    });
    if (results && results.data && results.data.pendingApprovalFollows) {
      const cleanedApprovals = results.data.pendingApprovalFollows.items;
      const newApprovals = approvals.concat(cleanedApprovals);
      setApprovals(newApprovals);
      setApprovalsPageInfo(results.data.pendingApprovalFollows.pageInfo);
    }
    setLoadingMoreApprovals(false);
  };

  const [profilesToApprove, setProfilesToApprove] = useState({});

  const handleProfileToggle = (profile, approval) => {
    if (
      profilesToApprove[profile.id] &&
      profilesToApprove[profile.id] === approval
    ) {
      setProfilesToApprove({ ...profilesToApprove, [profile.id]: null });
    } else {
      Object.keys(profilesToApprove).length >= 20
        ? toast.error("Cannot batch more than 20 actions", {
            id: "batchLimitError",
          })
        : setProfilesToApprove({
            ...profilesToApprove,
            [profile.id]: approval,
          });
    }
  };

  const emptyProfilesToApprove = () => setProfilesToApprove({});

  const wallet = useWallet();

  const submitApprovals = () => {
    const profileIds = Object.keys(profilesToApprove);
    const enables = profileIds.map((id) => profilesToApprove[id] === "true");
    toggleFollow(profileIds, enables, wallet.ethereum, wallet.account);
  };

  const appStatus = useAppStatus();

  const loadingApproval =
    appStatus &&
    appStatus["TOGGLE_FOLLOW"] &&
    appStatus["TOGGLE_FOLLOW"].pending;

  const approvalSuccess =
    appStatus["TOGGLE_FOLLOW"] &&
    !appStatus["TOGGLE_FOLLOW"].pending &&
    !appStatus["TOGGLE_FOLLOW"].error;

  useEffect(() => {
    if (approvalSuccess) {
      fetchMore({
        variables: {
          request: {
            limit: 5,
          },
        },
      });
      emptyProfilesToApprove();
    }
  }, [approvalSuccess]);

  return (
    <>
      <BatchApproveBanner
        show={
          Object.keys(profilesToApprove).length > 0 &&
          Object.values(profilesToApprove).some((v) => v !== null)
        }
        profilesToApprove={profilesToApprove}
        emptyProfilesToApprove={emptyProfilesToApprove}
        submitApprovals={submitApprovals}
      />
      <p className="self-start font-medium text-lg font-header mt-4">
        Approval Settings
      </p>
      <p className="font-subheader text-sm text-black/80">
        These FollowNFTs have been bought on a marketplace or sent to your
        wallet - approve the ones you want to appear in your “Following” list
      </p>
      <div className="flex flex-col gap-4 w-full mt-4">
        {!loading && approvals && approvals.length === 0 ? (
          <p className="text-center text-sm text-blueGrey">
            No pending approvals
          </p>
        ) : null}
        {loading ? (
          <div className="w-full h-8 flex items-center justify-center">
            <Spinner borderColor="border-basil" />
          </div>
        ) : null}
        {!loading && data && approvals && approvals.length
          ? approvals.map((profile) => (
              <div
                key={profile.id}
                className="flex items-center justify-between"
              >
                <Link
                  to={"/" + profile.handle}
                  className="w-full flex items-center gap-4"
                >
                  <LazyImage
                    className="w-10 h-10 rounded-full"
                    src={
                      profile.picture &&
                      profile.picture.original &&
                      profile.picture.original.url
                        ? profile.picture.original.url.replace(
                            "ipfs://",
                            "https://lens.infura-ipfs.io/ipfs/"
                          )
                        : FallbackAvatar
                    }
                    alt="profile thumbnail"
                  />
                  <div className="flex flex-col items-start">
                    <p className="font-header font-semibold text-lg leading-tight">
                      {profile.name ? shortName(profile.name) : profile.handle}
                    </p>
                    <p className="font-header leading-tight font-light text-sm">
                      @{profile.handle}
                    </p>
                  </div>
                </Link>
                <div className="flex items-center gap-2">
                  <button
                    className={`text-basil rounded-full w-20 h-12 flex items-center justify-center disabled:cursor-not-allowed ${
                      profilesToApprove[profile.id] &&
                      profilesToApprove[profile.id] === "false"
                        ? "bg-danger/20"
                        : "bg-lightBg"
                    }`}
                    onClick={() => handleProfileToggle(profile, "false")}
                    disabled={loadingApproval}
                  >
                    No
                  </button>
                  <button
                    className={`text-basil rounded-full w-20 h-12 flex items-center justify-center disabled:cursor-not-allowed ${
                      profilesToApprove[profile.id] &&
                      profilesToApprove[profile.id] === "true"
                        ? "bg-lime"
                        : "bg-lightBg"
                    }`}
                    onClick={() => handleProfileToggle(profile, "true")}
                    disabled={loadingApproval}
                  >
                    Yes
                  </button>
                </div>
              </div>
            ))
          : null}
      </div>
      {displayLoadMore ? (
        <button
          className="font-header text-basil my-2 mx-auto font-medium col-span-full flex items-center gap-2"
          onClick={loadMore}
        >
          {loadingMoreApprovals ? <Spinner borderColor="border-basil" /> : null}
          <>Load more</>
        </button>
      ) : null}
    </>
  );
}

EditApprovals.propTypes = {
  profile: PropTypes.object,
  loadingFollowModuleUpdate: PropTypes.bool,
};

Approvals.propTypes = {
  newFollowModule: PropTypes.object,
  disabled: PropTypes.bool,
  setNewFollowModule: PropTypes.func,
};
