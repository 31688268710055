import { splitSignature } from "ethers/lib/utils";
import omitDeep from "omit-deep";
import { createSetFollowModuleTypedData } from "../apollo/createSetFollowModuleTypedData";
import { pollUntilIndexed } from "../apollo/hasTxBeenIndexed";
import { dispatcher } from "../store";
import { getProvider, signedTypeData } from "../utils/ethersService";
import {
  updateFollowModuleFailure,
  updateFollowModuleRequest,
  updateFollowModuleSuccess,
} from "../_actions/user";
import toast from "react-hot-toast";
import { broadcast } from "../apollo/broadcast";
import { boradcastErrorFormatting } from "../utils/utils";
import config from "../config";
import { LENS_HUB_ABI } from "../utils/lensHubAbi";
import { ethers } from "ethers";

export async function setFollowModule(profileId, followModule, provider) {
  dispatcher(updateFollowModuleRequest());

  try {
    const setFollowModuleRequest = {
      profileId,
      followModule,
    };

    const result = await createSetFollowModuleTypedData(setFollowModuleRequest);

    const { expiresAt, id } = result.data.createSetFollowModuleTypedData;

    const typedData = omitDeep(
      result.data.createSetFollowModuleTypedData.typedData,
      "__typename"
    );

    let signature;
    try {
      signature = await signedTypeData(
        typedData.domain,
        typedData.types,
        typedData.value,
        provider
      );
    } catch (e) {
      dispatcher(updateFollowModuleFailure(e));
      console.error(e);
      toast.error(e.message);
      return;
    }

    if (new Date(expiresAt) <= new Date()) {
      dispatcher(updateFollowModuleFailure("Error updating follow module"));
      toast.error(boradcastErrorFormatting("EXPIRED"));
      return;
    }

    const relayerResult = await broadcast({ id, signature });

    const { txHash, reason } = relayerResult.data.broadcast;

    if (reason || !relayerResult) {
      toast.error(boradcastErrorFormatting(reason));
      return await updateFollowModuleWithSig(signature, typedData, provider);
    }
    if (txHash) {
      const indexation = await pollUntilIndexed(txHash);
      if (indexation) {
        dispatcher(updateFollowModuleSuccess());
        toast.success("Follow module updated!");
      }
    } else {
      dispatcher(updateFollowModuleFailure("Error updating follow module"));
      console.error("Error follow module");
      toast.error("Error updating follow module!");
      return;
    }
  } catch (e) {
    dispatcher(updateFollowModuleFailure("Error updating follow module"));
    console.error("Error follow module", e);
    toast.error("Error updating follow module!");
    return;
  }
}

const updateFollowModuleWithSig = async (signature, typedData, provider) => {
  const { v, r, s } = splitSignature(signature);
  const lensHub = new ethers.Contract(
    config.contracts.LENS_HUB_CONTRACT_ADDRESS,
    LENS_HUB_ABI,
    getProvider(provider).getSigner()
  );
  const tx = await lensHub.setFollowModuleWithSig({
    profileId: typedData.value.profileId,
    followModule: typedData.value.followModule,
    followModuleInitData: typedData.value.followModuleInitData,
    sig: {
      v,
      r,
      s,
      deadline: typedData.value.deadline,
    },
  });
  if (tx.hash) {
    const indexation = await pollUntilIndexed(tx.hash);
    if (indexation) {
      dispatcher(updateFollowModuleSuccess());
      toast.success("Follow module updated!");
    }
  } else {
    dispatcher(updateFollowModuleFailure("Error updating follow module"));
    console.error("Error follow module");
    return;
  }
};
