import { apolloClient } from "./apolloClient";
import { gql } from "@apollo/client";

export const ENABLED_CURRENCIES = `
  query EnabledCurrencies{
    enabledModuleCurrencies {
      name
      symbol
      decimals
      address
    }
  }
`;

export const getEnabledCurrencies = () => {
  return apolloClient.query({
    query: gql(ENABLED_CURRENCIES),
  });
};
