import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card";
import { ReactComponent as ArrowUp } from "../../assets/svg/ArrowUp.svg";
import { useReward } from "react-rewards";
import { useAppStatus } from "../../_reducers/appStatus";
import { useDispatch } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import { EXPLORE_PROFILES } from "../../apollo/exploreProfiles";
import { RECOMMENDED_PROFILES } from "../../apollo/recommendedProfiles";
import Button from "../Modules/Button";

function ExplorerList() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 400) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const appStatus = useAppStatus();
  const successFollow =
    appStatus &&
    appStatus["SET_FOLLOW"] &&
    !appStatus["SET_FOLLOW"].pending &&
    !appStatus["SET_FOLLOW"].error;

  const dispatch = useDispatch();
  const resetAppStatus = useCallback(
    () => dispatch(() => ({ type: "RESET_APPSTATUS" })),
    [dispatch]
  );

  const { reward } = useReward("rewardId", "confetti", {
    spread: 200,
    colors: ["#ABFE2C", "#E5FDBC", "#CEECAC", "#B7DA9C", "#B7DA9C", "#A0C98D"],
  });

  useEffect(() => {
    if (successFollow) {
      reward();
      setTimeout(() => resetAppStatus(), 4000);
    }
  }, [successFollow]);

  const {
    data: similarProfilesData,
    loading: similarProfilesLoading,
    error: similarProfilesError,
  } = useQuery(gql(RECOMMENDED_PROFILES), {
    fetchPolicy: "network-only",
  });

  const {
    data: exploreProfilesData,
    loading: exploreProfilesLoading,
    error: exploreProfilesError,
    fetchMore: exploreProfilesFetchMore,
  } = useQuery(gql(EXPLORE_PROFILES), {
    variables: {
      request: {
        limit: 50,
        sortCriteria: "MOST_COLLECTS",
        customFilters: ["GARDENERS"],
      },
    },
  });

  const [nextCursor, setNextCursor] = useState(
    exploreProfilesData ? exploreProfilesData.exploreProfiles.pageInfo.next : ""
  );

  const handleMore = () => {
    exploreProfilesFetchMore({
      variables: {
        request: {
          cursor: nextCursor,
          limit: 50,
          sortCriteria: "MOST_COLLECTS",
          customFilters: ["GARDENERS"],
        },
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }

        setNextCursor(fetchMoreResult.exploreProfiles.pageInfo.next);

        return {
          ...prevResult,
          exploreProfiles: {
            ...prevResult.exploreProfiles,
            items: [
              ...prevResult.exploreProfiles.items,
              ...fetchMoreResult.exploreProfiles.items,
            ],
          },
        };
      },
    });
  };

  const loadingProfiles = exploreProfilesLoading && similarProfilesLoading;
  const noProfiles =
    exploreProfilesError &&
    similarProfilesError &&
    !exploreProfilesData &&
    !similarProfilesData &&
    exploreProfilesData &&
    exploreProfilesData.exploreProfiles.items.length === 0 &&
    similarProfilesData &&
    similarProfilesData.recommendedProfiles.items === 0;

  if (loadingProfiles) {
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center gap-8 mx-6 sm:mx-0">
          <div className="my-8 w-full h-full flex items-center justify-center">
            <p className="text-[50px] animate-ping fixed top-1/2">🌿</p>
          </div>
        </div>
      </div>
    );
  }

  if (noProfiles) {
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center gap-8 mx-6 sm:mx-0">
          <div className="my-8 w-full flex flex-col items-center">
            <p className="font-header text-2xl text-black font-bold m-auto">
              No recommendation results yet.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <span id="rewardId" />
      <div className="flex flex-col items-center gap-8 mx-6 sm:mx-0">
        {/* SIMILAR PROFILES */}
        {!loadingProfiles &&
        similarProfilesData &&
        similarProfilesData.recommendedProfiles
          ? similarProfilesData.recommendedProfiles.map((profile) => (
              <Card key={profile.id} profile={profile} reward={reward} />
            ))
          : null}

        {/* ADDITIONAL PROFILES */}
        {!loadingProfiles &&
        exploreProfilesData &&
        exploreProfilesData.exploreProfiles
          ? exploreProfilesData.exploreProfiles.items.map((profile) => (
              <Card key={profile.id} profile={profile} reward={reward} />
            ))
          : null}

        {/* LOAD MORE BUTTON */}
        {!loadingProfiles &&
        exploreProfilesData &&
        exploreProfilesData.exploreProfiles ? (
          <Button
            label="Load more profiles"
            onClick={() => handleMore()}
            loading={exploreProfilesLoading}
          />
        ) : null}
      </div>
      {showButton ? (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 h-12 w-12 rounded-full bg-lime flex items-center justify-center shadow-md"
        >
          <ArrowUp />
        </button>
      ) : null}
    </div>
  );
}

export default ExplorerList;
